import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { styled, withTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import ProfileFeeds from "../Components/Profile/ProfileFeeds";
import { getUser, getPosts_user, followHandler } from "../Utils";
import { SettingsIcon } from "../Assets/Icons";
import PopupUserSettingsDrawer from "../Components/PopupUserSettingsDrawer";
import ProfileLoaderTop from "../Components/Loaders/ProfileLoaderTop";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch({ type: "UPDATE_USER_INFO", payload: data }),
  setUserData: (data) => dispatch({ type: "SET_USER_DATA", payload: data }),
  setUserPosts: (data) => dispatch({ type: "SET_USER_POSTS", payload: data }),
  setUserCollection: (data) => dispatch({ type: "SET_USER_COLLECTION", payload: data }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

const Profile = (props) => {
  const [loading, setLoading] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const { userData, userPosts } = props.app;
  const isFollowing = props.user.following.findIndex((followedUser) => followedUser.userID === userData._id) !== -1;
  const isLoggedInUser = userData._id === props.user._id;

  const start = async () => {
    setLoading(true);
    await props.setUserData(await getUser(props.match.params.id));
    await props.setUserPosts(await getPosts_user(props.match.params.id));
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const followUser = async (userID) => {
    await props.setUser(await followHandler(userID));
  };

  useEffect(() => {
    start();
  }, [props.match.params.id]);

  return (
    <>
      <ProfileWrap container spacing={0} justify="center">
        <ProfileColumn item xs={10} md={12}>
          {loading ? (
            <ProfileLoaderTop />
          ) : (
            userData && (
              <>
                <UserInfo>
                  {userData.image && (
                    <ProfilePhoto>
                      <img className="profile-photo" src={userData.image} alt="profile" />
                    </ProfilePhoto>
                  )}
                  <Box
                    style={
                      !isLoggedInUser
                        ? { marginBottom: 20, display: "flex", alignItems: "center", marginTop: 20 }
                        : { display: "flex", alignItems: "center", marginTop: 20 }
                    }
                  >
                    <Typography variant="h4" style={userData._id === props.user._id ? { marginRight: 6 } : {}}>
                      {userData.username}
                    </Typography>
                    {isLoggedInUser && props.user._id !== process.env.REACT_APP_GUEST_ID && (
                      <SettingsButton onClick={() => setShowSettings(true)}>
                        <SettingsIcon />
                      </SettingsButton>
                    )}
                  </Box>
                  {!isLoggedInUser && (
                    <FollowButton onClick={() => followUser(userData._id)} className={isFollowing ? "following" : ""}>
                      {isFollowing ? "Unfollow" : "Follow"}
                    </FollowButton>
                  )}
                  <UserStats>
                    <Button>
                      <Typography variant="h6">{userPosts.length}</Typography>
                      <Typography variant="body2">Posts</Typography>
                    </Button>
                    <Button>
                      <Typography variant="h6">{userData.followers && userData.followers.length}</Typography>
                      <Typography variant="body2">Followers</Typography>
                    </Button>
                    <Button>
                      <Typography variant="h6">{userData.following && userData.following.length}</Typography>
                      <Typography variant="body2">Following</Typography>
                    </Button>
                  </UserStats>
                  <Typography className="description" variant="body2">
                    {userData.bio}
                  </Typography>
                </UserInfo>
                <ProfileFeeds loading={loading} setLoading={setLoading} />
              </>
            )
          )}
        </ProfileColumn>
      </ProfileWrap>
      <PopupUserSettingsDrawer show={showSettings} showSettings={setShowSettings} />
    </>
  );
};

const ProfileWrap = styled(withTheme(Grid))((props) => ({
  padding: "40px 20px",
  width: "100%",
  height: "100vh",
  overflowY: "auto",
  overflowX: "hidden",
  paddingBottom: 100,
  marginBottom: 100,
  [props.theme.breakpoints.down("sm")]: {},
}));

const ProfileColumn = styled(withTheme(Grid))((props) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  maxWidth: 1200,
  "& .profile-photo": {
    width: 180,
    height: 180,
    borderRadius: "100%",
    position: "relative",
    zIndex: 10,
    objectFit: "cover",
    [props.theme.breakpoints.down("sm")]: {
      width: 100,
      height: 100,
    },
  },

  "& h4": {
    fontWeight: 500,
    [props.theme.breakpoints.down("sm")]: {
      fontSize: 28,
    },
  },
  [props.theme.breakpoints.down("sm")]: {
    padding: 0,
    marginTop: 100,
  },
}));

const ProfilePhoto = styled(Box)({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "&::before": {
    content: `""`,
    position: "absolute",
    top: -2,
    left: -2,
    right: -2,
    bottom: -2,
    borderRadius: "100%",
    backgroundColor: "#5805e9",
    backgroundImage: "linear-gradient(90deg, #5805e9 0%, #8b20eb 100%)",
    opacity: 0.65,
  },
  "&::after": {
    content: `""`,
    position: "absolute",
    left: -2,
    right: -2,
    bottom: -2,
    top: -2,
    borderRadius: "100%",
    backgroundColor: "#5805e9",
    backgroundImage: "linear-gradient(90deg, #5805e9 0%, #8b20eb 100%)",
    filter: "blur(3px)",
    opacity: 0.55,
  },
});

const UserInfo = styled(withTheme(Box))((props) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: 60,
  [props.theme.breakpoints.down("sm")]: {
    marginBottom: 40,
  },
  "& .description": {
    fontWeight: 500,
    maxWidth: 400,
    textAlign: "center",
  },
}));

const UserStats = styled(withTheme(Box))((props) => ({
  display: "flex",
  alignItems: "center",
  margin: "20px 0px",
  [props.theme.breakpoints.down("sm")]: {
    marginTop: 10,
    marginBottom: 20,
  },
  "& button": {
    position: "relative",
    minWidth: 120,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 20px",
    margin: "0 10px",
    textAlign: "center",
    borderRadius: "1rem 1rem 0rem 0rem",
    zIndex: 10,
    backgroundColor: "transparent",
    backgroundImage: "none",
    [props.theme.breakpoints.down("sm")]: {
      minWidth: 0,
      padding: 10,
    },

    "&::before": {
      content: `""`,
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      height: 2,
      zIndex: -1,
      backgroundColor: "#5805e9",
      backgroundImage: "linear-gradient(90deg, #5805e9 0%, #8b20eb 100%)",
    },
    "& h6": {
      fontWeight: 700,
      fontSize: 22,
      marginRight: 6,
    },
    "& p": {
      fontWeight: 500,
    },
  },
}));

const FollowButton = styled(Button)({
  padding: "10px 50px",
  color: "#fcfcfc",
  border: "1px solid transparent",
  minWidth: 200,
  "&.following": {
    backgroundColor: "#e7e7e7",
    backgroundImage: "none",
    color: "#454545",
    border: "1px solid #454545",
  },
});

const SettingsButton = styled(Button)({
  backgroundImage: "none",
  backgroundColor: "transparent",
  minWidth: 0,
  marginTop: 6,
  height: 30,
  width: 30,
  padding: 0,
  "& svg": {
    stroke: "#454545",
    height: 24,
    width: 24,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
