import React from "react";
import { connect } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

const ErrorAlert = ({ text, show, filled }) => {
  const styles = makeStyles((theme) => ({
    onTop: {
      position: "relative",
      zIndex: 999,
    },
  }));
  const classes = styles();
  return (
    <AnimatePresence>
      {show && (
        <motion.span className={classes.onTop} animate={{ x: [-5, 5, -5] }} transition={{ repeat: "Infinity" }}>
          <Alert
            severity="error"
            variant={filled && "filled"}
            style={{ marginBottom: 20 }}
            animate={{ scale: [0.1, 1], opacity: [0, 1] }}
            transition={{ ease: "easeInOut", duration: 0.5 }}
            exit={{ scale: [1, 0], opacity: [1, 0] }}
            component={motion.div}
          >
            {text.charAt(0).toUpperCase() + text.slice(1)}
          </Alert>
        </motion.span>
      )}
    </AnimatePresence>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorAlert);
