import React from "react";
import { connect } from "react-redux";
import { styled, withTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { NewMessage } from "../../Assets/Icons";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch({ type: "UPDATE_USER_INFO", payload: data }),
  setLoading: (boolean) => dispatch({ type: "SET_LOADING", payload: boolean }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

const users = ["", "", "", "", "", "", "", "", ""];

const Messages = (props) => {
  return (
    <MessagesMainWrap>
      <MessagesContainer container spacing={0}>
        <Left item xs={4}>
          <TitleBar>
            <Box></Box>
            <Typography variant="body2">{props.user.username}</Typography>
            <Button>
              <NewMessage />
            </Button>
          </TitleBar>
          <Userlist>
            {users.map((user) => (
              <SingleUser>
                <Box>
                  <img src={props.user.image} alt="profile" />
                  <Typography variant="body2">{props.user.username}</Typography>
                </Box>
                <ArrowForwardIosIcon />
              </SingleUser>
            ))}
          </Userlist>
        </Left>
        <Right item xs={12} md={8}>
          <TitleBar>
            <Link to="/">{props.user.username}</Link>
          </TitleBar>
          <MessagesContentWrap>
            <MessagesContent>
              {users.map((message, index) => (
                <SingleMessage className={index % 2 !== 0 ? "reply" : ""}>
                  <Box>
                    <Typography className="timestamp" variant="body2">
                      5 minutes ago
                    </Typography>
                    <Typography className="message" variant="body2">
                      This is a message
                    </Typography>
                  </Box>
                </SingleMessage>
              ))}
            </MessagesContent>
          </MessagesContentWrap>

          <MessageBar>
            <input type="text" placeholder="Enter a message..." />
            <Button>Send</Button>
          </MessageBar>
        </Right>
      </MessagesContainer>
    </MessagesMainWrap>
  );
};

const MessagesMainWrap = styled(Box)({
  display: "flex",
  justifyContent: "center",
  margin: "30px auto 80px",
  maxHeight: "80vh",
  width: "100%",
});

const MessagesContainer = styled(Grid)({
  width: "100%",
  maxWidth: 1000,
  height: "100%",
  borderRadius: "1rem",
  backgroundColor: "#fcfcfc",
});

const Left = styled(withTheme(Grid))((props) => ({
  height: "100%",
  borderRadius: "1rem 0rem 0rem 1rem",
  overflow: "hidden",
  borderRight: "1px solid rgba(0,0,0,0.125)",
  [props.theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const TitleBar = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: 14,
  borderBottom: "1px solid rgba(0,0,0,0.125)",
  textAlign: "center",

  "& p": {
    fontWeight: 500,
    width: "100%",
    fontSize: 16,
  },
  "& button": {
    backgroundColor: "transparent",
    backgroundImage: "none",
    minWidth: "0",
    borderRadius: "100%",
    padding: 10,
    "& svg": {
      stroke: "#454545",
      width: 24,
    },
  },
  "& a": {
    fontWeight: 500,
    width: "100%",
    fontSize: 16,
    color: "#454545",
    padding: 10,
  },
});

const Userlist = styled(Box)({
  overflowY: "auto",
  overflowX: "hidden",
  height: "93%",
  paddingBottom: 20,
  margin: "8px 4px 8px 0px",
  "&::-webkit-scrollbar": {
    width: 6,
    height: 6,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: "2rem",
  },
});

const SingleUser = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  margin: "6px 10px",
  borderRadius: "1rem",
  padding: "8px 16px",
  cursor: "pointer",
  transition: "background-color 0.25s ease",
  "&:hover": {
    backgroundColor: "#ededed",
  },
  "& > div": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  "& img": {
    width: 36,
    height: 36,
    borderRadius: "100%",
    marginRight: 10,
  },
  "& p": {
    fontWeight: 500,
    color: "#454545",
    fontSize: 14,
  },
  "& svg": {
    height: 16,
    width: 16,
    fill: "rgba(0,0,0,0.25)",
  },
});

const Right = styled(Grid)({
  height: "100%",
  borderRadius: "0rem 1rem 1rem 0rem",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  "& div:nth-child(2)": {
    margin: "6px 6px 6px 0px",
  },
});

const MessagesContentWrap = styled(Box)({
  height: "100%",
  overflowY: "auto",
  overflowX: "hidden",
  maxHeight: 600,
  "&::-webkit-scrollbar": {
    width: 6,
    height: 6,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: "2rem",
  },
});

const MessagesContent = styled(Box)({
  padding: 20,
  display: "flex",
  flexDirection: "column",
});

const SingleMessage = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: 20,
  "&.reply": {
    justifyContent: "flex-start",
    "& .message": {
      backgroundColor: "#5805e9",
      backgroundImage: "linear-gradient(90deg, #86ffd9 0%, #a4ffa0 100%)",
      color: "#454545",
      borderRadius: "2rem 2rem 2rem 0rem",
    },
    "& .timestamp": {
      textAlign: "left",
    },
  },
  "& .timestamp": {
    color: "rgba(0,0,0,0.25)",
    marginBottom: 4,
    textAlign: "right",
  },
  "& .message": {
    display: "inline-block",
    backgroundColor: "#5805e9",
    backgroundImage: "linear-gradient(90deg, #5805e9 0%, #8b20eb 100%)",
    borderRadius: "2rem 2rem 0rem 2rem",
    padding: 20,
    color: "#fcfcfc",
    fontWeight: 500,
  },
});

const MessageBar = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: 16,
  borderTop: "1px solid rgba(0,0,0,0.125)",
  textAlign: "center",
  "& input": {
    border: "none",
    padding: 10,
    width: "75%",
    fontSize: 16,
    "&::placeholder": {
      fontWeight: 600,
      color: "rgba(0,0,0,0.25)",
    },
    "&:focus": {
      outline: "none",
    },
  },
  "& button": {
    backgroundImage: "none",
    padding: "10px 30px",
    backgroundColor: "#e7e7e7",
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
