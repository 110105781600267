import { createStore, combineReducers, compose } from "redux";
import errorsReducer from "../Reducers/errors";
import userReducer from "../Reducers/user";
import appReducer from "../Reducers/app";

const composedEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialState = {
  user: {
    name: "",
    surname: "",
    username: "",
    image: "",
    email: "",
    searchHistory: [],
    following: [],
    followers: [],
    recipeCollection: [],
    hasSeen: [],
  },
  app: {
    isLoading: false,
    isLoggedIn: false,
    showBuilderDrawer: false,
    followingFeed: [],
    discoverFeed: [],
    loggedInUserPosts: [],
    userPosts: [],
    userCollection: [],
    userData: {},
    suggestedUsers: [],
    searchResults: {
      recipes: [],
      users: [],
    },
  },
  errors: {
    show: false,
    errors: [],
  },
};

const reducerMerge = combineReducers({
  user: userReducer,
  errors: errorsReducer,
  app: appReducer,
});

export default function configureStore() {
  return createStore(
    reducerMerge,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
}
