import React from "react";
import Lottie from "react-lottie";
import animationData from "../Assets/heartDumplingAnim.json";
import Box from "@material-ui/core/Box";
import { styled, withTheme } from "@material-ui/core/styles";
import { motion, AnimatePresence } from "framer-motion";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function HeartDumpling({ show }) {
  return (
    <AnimatePresence>
      {show && (
        <AnimationContainer
          component={motion.div}
          animate={{ scale: [0.4, 1] }}
          transition={{ ease: "easeInOut", duration: 0.5 }}
          exit={{ scale: [1, 0] }}
        >
          <Lottie options={defaultOptions} height={100} width={100} isClickToPauseDisabled={true} />
        </AnimationContainer>
      )}
    </AnimatePresence>
  );
}

const AnimationContainer = styled(withTheme(Box))((props) => ({
  borderRadius: "1rem 0rem 0rem 1rem",
  [props.theme.breakpoints.down("sm")]: {
    borderRadius: "1rem 1rem 0rem 0rem",
  },
  padding: "0 3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
}));

export default HeartDumpling;
