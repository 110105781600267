import React from "react";
import { connect } from "react-redux";
import { styled } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch({ type: "UPDATE_USER_INFO", payload: data }),
  setLoading: (boolean) => dispatch({ type: "SET_LOADING", payload: boolean }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

function SingleNotification(props) {
  return (
    <FollowNotificationContainer>
      <Top>
        <Left>
          <UserInfo>
            <img src={props.user.image} alt="profile" />
            <Link to="/">{props.user.username}</Link>
          </UserInfo>
          {props.type === "follow" && <Typography variant="body2"> started following you</Typography>}
          {props.type === "like" && <Typography variant="body2"> liked a post</Typography>}
          {props.type === "purchase" && <Typography variant="body2"> purchased a recipe</Typography>}
        </Left>
        <Right>
          {/*  {props.type === "follow" && <Button>Follow</Button>}
          {props.type === "purchase" && <Button>Send Message</Button>} */}
          <Typography className="timestamp" variant="body2">
            5 minutes ago
          </Typography>
        </Right>
      </Top>
    </FollowNotificationContainer>
  );
}

const FollowNotificationContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  marginBottom: 14,

  "& img": {
    height: 40,
    width: 40,
    borderRadius: "100%",
    marginRight: 14,
    position: "relative",
  },
  "& .timestamp": {
    color: "rgba(0,0,0,0.25)",
    width: "100%",
    textAlign: "left",
    padding: "6px 10px",
  },
});

const UserInfo = styled(Box)({
  display: "flex",
  alignItems: "center",
  position: "relative",
  "& a": {
    fontWeight: 500,
    fontSize: 16,
    color: "#454545",
  },
  /*   "&::before": {
    content: `""`,
    position: "absolute",
    left: -1,
    bottom: -1,
    width: 42,
    height: 42,
    borderRadius: "100%",
    backgroundColor: "#5805e9",
    backgroundImage: "linear-gradient(90deg, #5805e9 0%, #8b20eb 100%)",
    opacity: 1,
  }, */
});

const Top = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "1rem",
  backgroundColor: "#fcfcfc",
  padding: "10px 14px",
  boxShadow: "0 2px 3px -1px rgba(0,0,0,0.05)",
  borderBottom: "1px solid rgba(0,0,0,0.125)",
});

const Left = styled(Box)({
  display: "flex",
  alignItems: "center",

  "& a": {
    marginTop: -3,
    marginRight: 10,
  },
});
const Right = styled(Box)({
  display: "flex",
  alignItems: "center",
  "& button": {
    padding: "6px 30px",
    backgroundColor: "rgba(0,0,0,0.05)",
    backgroundImage: "none",
    color: "",
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleNotification);
