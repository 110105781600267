import React, { useState } from "react";
import { connect } from "react-redux";
import { styled, withTheme } from "@material-ui/core/styles";
import { EditIcon } from "../../Assets/Icons";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { uploadProfilePicture } from "../../Utils";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch({ type: "UPDATE_USER_INFO", payload: data }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

const UserImageBlock = (props) => {
  const [loading, setLoading] = useState(false);

  return (
    <UserBlockContainer>
      <Grid container spacing={3}>
        <Left item xs={12} md={6}>
          <EditButton>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="raised-button-file"
              type="file"
              onChange={async (event) => {
                setLoading(true);
                const data = await uploadProfilePicture(event.target.files[0]);
                await props.setUser(data);
                setTimeout(() => {
                  setLoading(false);
                }, 2000);
              }}
            />
            <label htmlFor="raised-button-file">
              <Button component="span" className="upload-btn">
                {loading ? <CircleLoader /> : <EditIcon />}
              </Button>
            </label>
          </EditButton>
          <img src={props.user.image} alt="profile" />
        </Left>
        <Right item xs={12} md={6}>
          <Typography variant="h3">{props.user.username}</Typography>
          <Typography variant="h6">{props.user.name + " " + props.user.surname}</Typography>
        </Right>
      </Grid>
    </UserBlockContainer>
  );
};

const UserBlockContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "20px 80px",
  borderRadius: "1rem",
  position: "relative",
  marginTop: 30,
});

const Left = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  "& img": {
    width: 140,
    height: 140,
    borderRadius: "100%",
    boxShadow: "0 3px 10px -1px rgba(0,0,0,0.125)",
    objectFit: "cover",
  },
});
const Right = styled(withTheme(Grid))((props) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  position: "relative",
  [props.theme.breakpoints.down("sm")]: {
    alignItems: "center",
  },
  "& h3": {
    fontWeight: 500,
    marginBottom: 0,
  },
}));

const EditButton = styled(withTheme(Button))((props) => ({
  minWidth: 0,
  position: "absolute",
  top: 10,
  right: 10,
  height: 40,
  width: 40,
  [props.theme.breakpoints.down("sm")]: {
    top: 10,
    right: 90,
  },

  "& label span": {
    minWidth: 0,

    "& svg": {
      stroke: "#fcfcfc",
      height: 20,
      width: 20,
      padding: 0,
    },
  },
}));

const CircleLoader = styled(CircularProgress)({
  height: "20px !important",
  width: "20px !important",
});

export default connect(mapStateToProps, mapDispatchToProps)(UserImageBlock);
