import React from "react";
import { connect } from "react-redux";
import { styled, withTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import SingleNotification from "./SingleNotification";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch({ type: "UPDATE_USER_INFO", payload: data }),
  setLoading: (boolean) => dispatch({ type: "SET_LOADING", payload: boolean }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

const notifications = [
  { type: "follow" },
  { type: "follow" },
  { type: "purchase" },
  { type: "like" },
  { type: "like" },
  { type: "follow" },
  { type: "like" },
  { type: "purchase" },
  { type: "like" },
  { type: "like" },
  { type: "like" },
  { type: "like" },
  { type: "like" },
  { type: "like" },
];

const NotificationsList = (props) => {
  return (
    <NotificationsWrap>
      <NotificationsContainer>
        {notifications.map((notification) => (
          <SingleNotification type={notification.type} />
        ))}
      </NotificationsContainer>
    </NotificationsWrap>
  );
};

const NotificationsWrap = styled(withTheme(Box))((props) => ({
  width: "100%",
  padding: 20,
  height: "100%",
  margin: "0 auto",
  marginBottom: 60,
  maxWidth: 800,
  overflowY: "auto",
  overflowX: "hidden",

  "&::-webkit-scrollbar": {
    width: 6,
    height: 6,
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,0.15)",
    borderRadius: "2rem",
  },

  [props.theme.breakpoints.down("sm")]: {
    marginBottom: 140,
    padding: 0,
  },
}));

const NotificationsContainer = styled(withTheme(Box))((props) => ({
  width: "100%",
  height: "100%",
  padding: 20,
  [props.theme.breakpoints.down("sm")]: {
    padding: 0,
  },
}));

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsList);
