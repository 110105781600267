import React from "react";
import { styled } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function PreparationInstructions({ text }) {
  return (
    <PreparationContainer>
      <Typography variant="h5" style={{ marginBottom: 10, fontWeight: "bold" }}>
        Preparation
      </Typography>
      <Typography variant="body1">{text}</Typography>
    </PreparationContainer>
  );
}

const PreparationContainer = styled(Box)({
  backgroundColor: "rgba(255,255,255,0.65)",
  padding: 20,
  borderRadius: "1rem",
  /* boxShadow: "0 3px 3px -1px rgba(0,0,0,0.1)", */
  borderBottom: "1px solid rgba(0,0,0,0.125)",
  marginBottom: 20,
});

export default PreparationInstructions;
