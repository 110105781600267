import React from "react";
import Lottie from "react-lottie";
import animationData from "../../Assets/loader.json";
import Box from "@material-ui/core/Box";
import { styled } from "@material-ui/core/styles";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function FullPageLoader() {
  return (
    <FullPageWrap display="flex" alignItems="center" justifyContent="center">
      <Lottie options={defaultOptions} height={160} width={160} isClickToPauseDisabled={true} />
    </FullPageWrap>
  );
}

const FullPageWrap = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 999,
  backgroundColor: "#e7e7e7",
  background: "linear-gradient(19deg, #cacaca 0%, #fbfbfb 100%)",
});

export default FullPageLoader;
