import React from "react";
import { styled } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import SinglePost from "./SinglePost";

function Feed({ data, type = "", currentTab, setCollectionLoading }) {
  return (
    <FeedMainWrap>
      <Grid container spacing={3}>
        {data &&
          data.length > 0 &&
          data.map((post, index) => (
            <SinglePost
              data={post}
              key={index}
              delay={index}
              type={type}
              currentTab={currentTab}
              setCollectionLoading={setCollectionLoading}
            />
          ))}
      </Grid>
    </FeedMainWrap>
  );
}

const FeedMainWrap = styled(Box)({
  marginBottom: 70,
  paddingBottom: 70,
  width: "100%",
});

export default Feed;
