import React from "react";
import { connect } from "react-redux";
import { styled, withTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { followHandler } from "../../Utils";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch({ type: "UPDATE_USER_INFO", payload: data }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

function UserInfo(props) {
  const followUser = async (userID) => {
    await props.setUser(await followHandler(userID));
  };
  return (
    <InfoContainer>
      <Box>
        <Link to={`/profile/${props.data.userID}`}>
          <UsernameButton>
            <img src={props.data.author_picture} alt="user-profile" />
            {props.data.author}
          </UsernameButton>
        </Link>
      </Box>
      {props.data.userID !== props.user._id && (
        <FollowButton
          onClick={() => followUser(props.data.userID)}
          style={
            props.user.following.findIndex((followedUser) => followedUser.userID === props.data.userID) !== -1
              ? {
                  backgroundColor: "transparent",
                  backgroundImage: "none",
                  color: "#454545",
                  border: "1px solid #454545",
                }
              : {}
          }
        >
          {props.user.following.findIndex((followedUser) => followedUser.userID === props.data.userID) !== -1
            ? "Unfollow"
            : "Follow"}
        </FollowButton>
      )}
    </InfoContainer>
  );
}

const InfoContainer = styled(withTheme(Box))((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: 20,
  "& div": {
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.65)",
    boxShadow: "0 3px 3px -1px rgba(0,0,0,0.1)",
    backdropFilter: "blur(4px)",
    borderRadius: "2rem",
  },
  "& img": {
    height: 32,
    width: 32,
    borderRadius: "100%",
    marginRight: 6,
  },
}));

const FollowButton = styled(Button)({
  padding: "6px 20px",
  color: "#fcfcfc",
  border: "1px solid transparent",
  minWidth: 120,
});

const UsernameButton = styled(Button)({
  fontWeight: 500,
  backgroundImage: "none",
  backgroundColor: "transparent",
  textTransform: "none",
  color: "#fcfcfc",
  minWidth: 160,
  justifyContent: "flex-start",
  "&:hover": {
    backgroundColor: "rgba(255,255,255,0.05)",
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
