const axios = require("axios");

const getPosts_following = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/feed`, { withCredentials: true });
    const data = response.data;
    if (!data.errors) {
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

const getPosts_discover = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/feed/discover`, { withCredentials: true });
    const data = response.data;
    if (!data.errors) {
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

const getPosts_search = async (searchTerm) => {
  try {
  } catch (error) {
    console.log(error);
  }
};

const getPosts_user = async (userID) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/feed/userposts/${userID}`, {
      withCredentials: true,
    });
    const data = response.data;
    if (!data.errors) {
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

const getPosts_collection = async (userID) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/feed/collection/${userID}`, {
      withCredentials: true,
    });
    const data = response.data;
    if (!data.errors) {
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

const getUser = async (userID) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/data/${userID}`, {
      withCredentials: true,
    });
    const data = response.data;
    if (!data.errors) {
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

const likeHandler = async (likeData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/likes`,
      { ...likeData },
      {
        withCredentials: true,
      }
    );
    const data = response.data;
    if (!data.errors) {
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

const deleteRecipeHandler = async (recipeID) => {
  try {
    await axios.delete(`${process.env.REACT_APP_BASE_URL}/recipes/${recipeID}`, {
      withCredentials: true,
    });
  } catch (error) {
    console.log(error);
  }
};

const collectionHandler = async (recipeID) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/recipes/star/${recipeID}`,
      {},
      {
        withCredentials: true,
      }
    );
    const data = response.data;
    if (!data.errors) {
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

const followHandler = async (userID) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/users/follow/${userID}`,
      {},
      {
        withCredentials: true,
      }
    );
    const data = response.data;
    if (!data.errors) {
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

const updateUserData = async (userData) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/users`, userData, {
      withCredentials: true,
    });
    const data = response.data;
    if (!data.errors) {
      if (data.code && !data.code === 11000) {
        return;
      }
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

const uploadProfilePicture = async (image) => {
  try {
    let formData = new FormData();
    let blob = new Blob([image], { type: "image/jpeg" });
    formData.append("image", blob);
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/users/profilepicture/upload`, formData, {
      withCredentials: true,
    });
    const data = response.data;
    if (!data.errors) {
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

const newCommentHandler = async (comment) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/comments`, comment, {
      withCredentials: true,
    });
    const data = response.data;
    if (!data.errors) {
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

const getCommentsHandler = async (referenceID) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/comments/${referenceID}`, {
      withCredentials: true,
    });
    const data = response.data;
    if (!data.errors) {
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

const deleteCommentHandler = async (commentID) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/comments/${commentID}`, {
      withCredentials: true,
    });
    const data = response.data;
    if (!data.errors) {
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

const newBugReportHandler = async (reportData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/maint/bugreport`, reportData, {
      withCredentials: true,
    });
    const data = response.data;
    if (!data.errors) {
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

export {
  getPosts_following,
  getPosts_discover,
  getPosts_search,
  getPosts_user,
  getPosts_collection,
  getUser,
  likeHandler,
  deleteRecipeHandler,
  collectionHandler,
  followHandler,
  updateUserData,
  uploadProfilePicture,
  newCommentHandler,
  getCommentsHandler,
  deleteCommentHandler,
  newBugReportHandler,
};
