import React from "react";
import { styled, withTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import NavBar from "./NavBar";
import NavBarMobile from "./NavBar_mobile";
import PopupBuilderDrawer from "./PopupBuilderDrawer";

function NavBarController(props) {
  const location = props.history.location.pathname;
  return (
    <>
      <NavBarContainer>
        <NavBar location={location} />
      </NavBarContainer>
      <NavBarMobileContainer>
        <NavBarMobile location={location} />
      </NavBarMobileContainer>
      <PopupBuilderDrawer />
    </>
  );
}

const NavBarContainer = styled(withTheme(Box))((props) => ({
  [props.theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const NavBarMobileContainer = styled(withTheme(Box))((props) => ({
  display: "none",
  [props.theme.breakpoints.down("sm")]: {
    display: "flex",
  },
}));

export default NavBarController;
