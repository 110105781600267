import React from "react";
import { styled } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { CloseIcon } from "../../Assets/Icons";

function TitleBar({ showSettings }) {
  return (
    <TitleBarContainer>
      <Typography variant="h4">My Account</Typography>
      <Button onClick={() => showSettings(false)}>
        <CloseIcon />
      </Button>
    </TitleBarContainer>
  );
}

const TitleBarContainer = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "& Button": {
    backgroundColor: "transparent",
    backgroundImage: "none",
    borderRadius: "100%",
    minWidth: 0,
    "&:hover": {
      backgroundColor: "#e7e7e7",
    },
    "& svg": {
      width: 40,
      height: 40,
    },
  },
  "& h4": {
    fontWeight: 700,
  },
});

export default TitleBar;
