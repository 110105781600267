import React from "react";
import Lottie from "react-lottie";
import animationData from "../../Assets/cookingAnim.json";
import Box from "@material-ui/core/Box";
import { styled, withTheme } from "@material-ui/core/styles";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function LoginLottie() {
  return (
    <>
      <AnimationContainer>
        <Lottie options={defaultOptions} height={400} width={400} isClickToPauseDisabled={true} />
      </AnimationContainer>
      <AnimationContainerMedium>
        <Lottie options={defaultOptions} height={250} width={250} isClickToPauseDisabled={true} />
      </AnimationContainerMedium>
      <AnimationContainerSmall>
        <Lottie options={defaultOptions} height={250} width={250} isClickToPauseDisabled={true} />
      </AnimationContainerSmall>
    </>
  );
}

const AnimationContainer = styled(withTheme(Box))((props) => ({
  backgroundColor: "#735cdd",
  borderRadius: "2rem 0rem 0rem 2rem",
  height: "100%",
  padding: "0 3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  zIndex: 999,
  [props.theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const AnimationContainerMedium = styled(withTheme(Box))((props) => ({
  backgroundColor: "#735cdd",
  borderRadius: "2rem 2rem 0rem 0rem",
  height: "100%",
  padding: "0 3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  zIndex: 999,

  [props.theme.breakpoints.up("md")]: {
    display: "none",
  },
  [props.theme.breakpoints.down("xs")]: {
    display: "none",
  },
}));

const AnimationContainerSmall = styled(withTheme(Box))((props) => ({
  backgroundColor: "#735cdd",
  borderRadius: "2rem 2rem 0rem 0rem",
  height: "100%",
  padding: "0 3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  zIndex: 999,
  [props.theme.breakpoints.up("sm")]: {
    display: "none",
  },
  [props.theme.breakpoints.down("sm")]: {
    borderRadius: "0rem 0rem 0rem 0rem",
  },
}));

export default LoginLottie;
