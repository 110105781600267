import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { styled, withTheme } from "@material-ui/core/styles";
import Clips from "./Clips";
import Feed from "../Feed";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { getPosts_following, getPosts_discover } from "../../Utils";
import NoPostsMessage from "./NoPostsMessage";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setFollowingFeed: (data) => dispatch({ type: "SET_FOLLOWING_FEED", payload: data }),
  setDiscoverFeed: (data) => dispatch({ type: "SET_DISCOVER_FEED", payload: data }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

function HomeFeeds(props) {
  const [currentTab, setCurrentTab] = useState("Following");
  const [tabLoaded, setTabLoaded] = useState(false);
  const { discoverFeed, followingFeed } = props.app;

  useEffect(() => {
    const start = async () => {
      if (currentTab === "Following") {
        await props.setFollowingFeed(await getPosts_following());
      }
      if (currentTab === "Discover") {
        await props.setDiscoverFeed(await getPosts_discover());
        if (!tabLoaded) setTabLoaded(true);
      }
    };
    start();
  }, [currentTab]);

  return (
    <>
      <TabButtons disableElevation variant="contained" color="primary">
        <Button
          className={currentTab === "Following" ? "selected" : ""}
          onClick={(event) => {
            event.stopPropagation();
            setCurrentTab("Following");
          }}
        >
          <span style={currentTab === "Following" ? { color: "#fcfcfc" } : { color: "#454545" }}>Following</span>
        </Button>
        <Button
          className={currentTab === "Discover" ? "selected" : ""}
          onClick={(event) => {
            event.stopPropagation();
            setCurrentTab("Discover");
          }}
        >
          <span style={currentTab === "Discover" ? { color: "#fcfcfc" } : { color: "#454545" }}>Discover</span>
        </Button>
      </TabButtons>
      {currentTab === "Following" && <Clips />}
      {currentTab === "Following" &&
        (props.app.followingFeed.length > 0 ? (
          <Feed data={followingFeed} currentTab={currentTab} />
        ) : (
          <NoPostsMessage text="Oops! Start following users to see posts here." />
        ))}
      {currentTab === "Discover" &&
        tabLoaded &&
        (props.app.discoverFeed.length > 0 ? (
          <Feed data={discoverFeed} currentTab={currentTab} />
        ) : (
          <NoPostsMessage text="Oops! There are no new posts to see." />
        ))}
    </>
  );
}

const TabButtons = styled(withTheme(ButtonGroup))((props) => ({
  margin: "0 auto",
  marginBottom: 30,
  boxShadow: "0 3px 4px -1px rgba(0,0,0,0.05)",
  borderBottom: "1px solid rgba(0,0,0,0.05)",
  borderRadius: "2rem",
  width: "100%",
  maxWidth: 600,
  [props.theme.breakpoints.down("md")]: {
    maxWidth: 500,
  },

  "& button": {
    backgroundColor: "#fcfcfc",
    backgroundImage: "none",
    width: "50%",
    padding: 10,
    display: "flex",
    alignItems: "center",
    transition: "width 0.25s ease",
    fontWeight: 600,
    "&.selected": {
      backgroundColor: "#5805e9",
      backgroundImage: "linear-gradient(90deg, #5805e9 0%, #8b20eb 100%)",
    },

    [props.theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [props.theme.breakpoints.down("sm")]: {
      padding: "10px 40px",
      width: "100%",
    },
    "&:hover": {
      backgroundColor: "#fcfcfc",
    },
    "&:first-child": {
      borderRight: "1px solid rgba(0,0,0,0.15)",
    },
  },
  [props.theme.breakpoints.down("sm")]: {
    "& button": {
      width: 200,
    },
  },
}));

export default connect(mapStateToProps, mapDispatchToProps)(HomeFeeds);
