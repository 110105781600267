import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { authorise } from "./Auth";
import Login from "./Components/LoginRegister/Login";
import Register from "./Components/LoginRegister/Register";
import ForgotPassword from "./Components/LoginRegister/ForgotPassword";
import ResetPassword from "./Components/LoginRegister/ResetPassword";
import FullPageLoader from "./Components/Loaders/FullPageLoader";
import NavBarController from "./Components/NavBarController";
import Home from "./Pages/Home";
import Search from "./Pages/Search";
import Notifications from "./Pages/Notifications";
import Collection from "./Pages/Collection";
import Profile from "./Pages/Profile";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch({ type: "UPDATE_USER_INFO", payload: data }),
  setUserCollection: (data) => dispatch({ type: "SET_USER_COLLECTION", payload: data }),
  setLoading: (boolean) => dispatch({ type: "SET_LOADING", payload: boolean }),
  setLoggedIn: (boolean) => dispatch({ type: "SET_LOGGED_IN_STATUS", payload: boolean }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

const Main = (props) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const start = async () => {
    const user = await authorise(props.setUser);
    if (user && user.recipeCollection) await props.setUserCollection(user.recipeCollection);
    setTimeout(() => {
      props.setLoading(false);
    }, 1500);
  };

  useEffect(() => {
    props.setLoading(true);
    if (isFirstLoad) start();
    setIsFirstLoad(false);
  }, []);

  return (
    <Router>
      {!props.app.isLoggedIn && !props.user.username ? (
        <>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route exact path="/resetpassword" component={ResetPassword} />
            <Redirect to="/login" />
          </Switch>
        </>
      ) : (
        <>
          <Route path="/" component={NavBarController} />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/search" component={Search} />
            <Route exact path="/notifications" component={Notifications} />
            <Route exact path="/collection" component={Collection} />
            <Route exact path="/profile/:id" component={Profile} />
            <Redirect to="/" />
          </Switch>
        </>
      )}

      {props.app.isLoading && props.user.username && <FullPageLoader />}
    </Router>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
