import React from "react";
import { styled } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function Ingredients({ ingredients }) {
  return (
    <>
      <Typography variant="h5" style={{ marginBottom: 10, fontWeight: "bold" }}>
        Ingredients
      </Typography>
      <IngredientsContainer>
        {ingredients.map((ingredient, index) => (
          <SingleIngredient key={index}>
            <Box>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                {ingredient.name}
              </Typography>
              <Box>
                <Typography variant="body2">{ingredient.quantity}</Typography>
                {ingredient.units !== "None" ? (
                  <Typography variant="body2" style={{ marginLeft: 4 }}>
                    {ingredient.units}
                  </Typography>
                ) : (
                  <Typography variant="body2">x</Typography>
                )}
              </Box>
            </Box>
            {ingredient.link && (
              <Box>
                <a href={ingredient.link}>Where to buy?</a>
              </Box>
            )}
          </SingleIngredient>
        ))}
      </IngredientsContainer>
    </>
  );
}

const IngredientsContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
  overflowX: "hidden",
  maxHeight: 190,
  padding: "4px 10px 4px 0px",
  width: "100%",
  "&::-webkit-scrollbar": {
    width: 6,
    height: 6,
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,0.15)",
    borderRadius: "2rem",
  },
});
const SingleIngredient = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginBottom: 10,
  backgroundColor: "rgba(255,255,255,0.65)",
  borderBottom: "1px solid rgba(0,0,0,0.125)",
  borderRadius: "1rem",
  padding: "10px 20px",
  "& > div": {
    display: "flex",
    justifyContent: "space-between",
    "& > div": {
      display: "flex",
    },
  },
  "& a": {
    fontSize: 14,
    color: "#735cdd",
  },
});

export default Ingredients;
