import React, { useEffect } from "react";
import { connect } from "react-redux";
import { styled } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { getPosts_user } from "../../Utils";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setLoggedInUserPosts: (data) => dispatch({ type: "SET_LOGGED_IN_USER_POSTS", payload: data }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

const UserProfile = (props) => {
  const start = async () => {
    props.setLoggedInUserPosts(await getPosts_user(props.user._id));
  };

  useEffect(() => {
    if (props.app.loggedInUserPosts.length === 0 || !props.user._id) start();
  }, []);

  return (
    <UserProfileWrap>
      <UserProfileContainer>
        <Top>
          <img src={props.user.image} alt="profile" />
          <Typography variant="h5">{props.user.name + " " + props.user.surname}</Typography>
          <Typography variant="body2">@{props.user.username}</Typography>
        </Top>
        <Middle>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container justify="center">
                <Grid item xs={4}>
                  <ProfileInfoBlock>
                    <Typography variant="body2">{props.app.loggedInUserPosts.length}</Typography>
                    <Typography variant="h6">Posts</Typography>
                  </ProfileInfoBlock>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2">{props.user.followers.length}</Typography>
                  <Typography variant="h6">Followers</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2">{props.user.following.length}</Typography>
                  <Typography variant="h6">Following</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Middle>
        <Bottom>
          <Typography variant="body2">{props.user.bio}</Typography>
        </Bottom>
      </UserProfileContainer>
    </UserProfileWrap>
  );
};

const UserProfileWrap = styled(Box)({
  display: "block",
  marginBottom: 20,
  width: "100%",
});

const UserProfileContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  position: "relative",
  padding: "30px 0px",
  borderRadius: "1rem",
  maxWidth: 400,
  "&::before": {
    content: `""`,
    position: "absolute",
    left: 3,
    right: 3,
    bottom: 0,
    zIndex: 1,
    height: 1,
    borderRadius: "0rem 0rem 2rem 2rem",
    backgroundColor: "rgba(0,0,0,0.125)",
  },
});

const Top = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  marginBottom: 10,
  "& img": {
    borderRadius: "100%",
    width: 96,
    height: 96,
    marginBottom: 10,
  },
  "& h5": {
    fontWeight: 600,
  },
  "& p": {
    color: "#808080",
  },
});
const Middle = styled(Box)({
  justifyContent: "space-between",
  textAlign: "center",
  borderRadius: "1rem",
  width: "100%",

  margin: "16px 0px",
  "& h6": {
    fontSize: 14,
    fontWeight: 700,
    color: "rgba(0,0,0,.35)",
  },
  "& p": {
    fontWeight: 700,
    fontSize: 20,
  },
});

const ProfileInfoBlock = styled(Box)({
  width: "100%",
});

const Bottom = styled(Box)({
  textAlign: "center",
  padding: 14,
  "& p": {
    fontWeight: 500,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
