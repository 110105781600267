const HomeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
    <polyline points="9 22 9 12 15 12 15 22"></polyline>
  </svg>
);

const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="11" cy="11" r="8"></circle>
    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
  </svg>
);

const DiscoverIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="12" cy="12" r="10"></circle>
    <polygon points="16.24 7.76 14.12 14.12 7.76 16.24 9.88 9.88 16.24 7.76"></polygon>
  </svg>
);

const MessagesIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="22" y1="2" x2="11" y2="13"></line>
    <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
  </svg>
);

const StarIcon = ({ inCollection }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill={inCollection ? "#f4c256" : "none"}
    style={inCollection ? { stroke: "#f4c256" } : {}}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
  </svg>
);

const MenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#454545"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="3" y1="12" x2="21" y2="12"></line>
    <line x1="3" y1="6" x2="21" y2="6"></line>
    <line x1="3" y1="18" x2="21" y2="18"></line>
  </svg>
);

const UserIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
    <circle cx="12" cy="7" r="4"></circle>
  </svg>
);

const SettingsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="12" cy="12" r="3"></circle>
    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
  </svg>
);

const LogoutIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
    <polyline points="16 17 21 12 16 7"></polyline>
    <line x1="21" y1="12" x2="9" y2="12"></line>
  </svg>
);

const PlusIcon = ({ rotate }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={rotate ? { transform: "rotate(45deg)" } : {}}
  >
    <line x1="12" y1="5" x2="12" y2="19"></line>
    <line x1="5" y1="12" x2="19" y2="12"></line>
  </svg>
);

const CloseIcon = ({ open }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke={open ? "#5805e9" : "#454545"}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
);

const BackIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="19" y1="12" x2="5" y2="12"></line>
    <polyline points="12 19 5 12 12 5"></polyline>
  </svg>
);

const MenuMoreIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="9 18 15 12 9 6"></polyline>
  </svg>
);

const NewRecipeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
    <polyline points="2 17 12 22 22 17"></polyline>
    <polyline points="2 12 12 17 22 12"></polyline>
  </svg>
);

const NewPostIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
    <circle cx="8.5" cy="8.5" r="1.5"></circle>
    <polyline points="21 15 16 10 5 21"></polyline>
  </svg>
);

const NewClipIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="2" y="7" width="20" height="15" rx="2" ry="2"></rect>
    <polyline points="17 2 12 7 7 2"></polyline>
  </svg>
);

const BellIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path>
  </svg>
);

const JellyBeanIcon = () => (
  <svg height="511pt" viewBox="0 -12 511.96007 511" width="511pt" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m195.867188 214.578125 27.90625 54.488281c21.054687 41.105469 101.496093 41.96875 179.675781 1.929688 78.175781-40.039063 124.484375-105.820313 103.429687-146.925782l-27.90625-54.488281zm0 0"
      fill="#ffb600"
    />
    <path
      d="m195.867188 214.582031 27.90625 54.488281c20.234374 39.503907 95.320312 41.835938 170.53125 6.417969-8.25-40.144531-24.753907-72.585937-37.894532-96.324219-6.464844-11.679687-8.796875-28.574218-6.503906-43.476562zm0 0"
      fill="#ff9d00"
    />
    <path
      d="m479.003906 69.566406c-21.054687-41.109375-101.5-41.96875-179.683594-1.925781-78.179687 40.042969-124.492187 105.828125-103.4375 146.9375 21.054688 41.109375 101.503907 41.96875 179.683594 1.925781 78.183594-40.042968 124.492188-105.828125 103.4375-146.9375zm0 0"
      fill="#ffcd00"
    />
    <path
      d="m356.410156 179.160156c-9.394531-16.972656-10.0625-44.964844-.484375-61.835937 14.972657-26.375 19.515625-55.679688 20.636719-76.210938-24.449219 4.242188-50.980469 13.078125-77.261719 26.539063-78.175781 40.039062-124.484375 105.820312-103.429687 146.929687 21.023437 41.050781 101.292968 41.964844 179.375 2.078125-6.25-14.21875-12.886719-26.753906-18.835938-37.5zm0 0"
      fill="#ffb600"
    />
    <path
      d="m318.429688 105c-33.039063 16.925781-98.421876 64.652344-85.210938 90.449219 13.210938 25.792969 85.339844 3.097656 123.191406-16.289063 37.855469-19.390625 98.425782-64.652344 85.214844-90.449218-13.214844-25.796876-90.152344-.632813-123.195312 16.289062zm0 0"
      fill="#ffde55"
    />
    <path
      d="m355.925781 117.320312c5.917969-10.425781 10.210938-21.316406 13.304688-31.9375-19.335938 5.5625-38.238281 13.1875-50.800781 19.617188-33.039063 16.925781-98.421876 64.652344-85.210938 90.449219 13.210938 25.792969 85.339844 3.097656 123.191406-16.289063-9.394531-16.976562-10.0625-44.96875-.484375-61.839844zm0 0"
      fill="#ffcd00"
    />
    <path
      d="m6.910156 401.914062 27.90625 54.488282c21.050782 41.105468 101.496094 41.96875 179.671875 1.929687 78.179688-40.039062 124.488281-105.820312 103.433594-146.925781l-27.90625-54.488281zm0 0"
      fill="#ffcd00"
    />
    <path
      d="m322.878906 329.667969c-.4375-6.46875-2.050781-12.601563-4.953125-18.261719l-27.90625-54.488281-174.546875 89.398437c50.359375 9.644532 119.417969 10.277344 207.40625-16.648437zm0 0"
      fill="#ffb600"
    />
    <path
      d="m290.042969 256.917969c-21.054688-41.105469-101.5-41.96875-179.683594-1.925781-78.179687 40.042968-124.488281 105.832031-103.433594 146.9375 21.054688 41.109374 101.5 41.972656 179.683594 1.929687 78.179687-40.046875 124.488281-105.832031 103.433594-146.941406zm0 0"
      fill="#ffde55"
    />
    <path
      d="m110.34375 254.988281c-33.019531 16.910157-60.355469 38.414063-79.332031 60.714844 32.605469 19.203125 110.351562 52.148437 234.054687 28.414063 26.5625-30.785157 37.238282-63.203126 24.949219-87.199219-21.050781-41.105469-101.496094-41.972657-179.671875-1.929688zm0 0"
      fill="#ffcd00"
    />
    <path
      d="m129.472656 292.339844c-33.042968 16.921875-98.421875 64.652344-85.210937 90.445312 13.210937 25.792969 85.335937 3.097656 123.191406-16.289062 37.855469-19.386719 98.421875-64.652344 85.210937-90.449219-13.210937-25.792969-90.148437-.632813-123.191406 16.292969zm0 0"
      fill="#ffeb99"
    />
    <path
      d="m5.039062 176.890625 27.90625 54.488281c21.054688 41.105469 101.496094 41.96875 179.675782 1.929688 78.175781-40.039063 124.484375-105.820313 103.429687-146.929688l-27.90625-54.484375zm0 0"
      fill="#ffde55"
    />
    <path
      d="m288.164062 31.882812c-21.054687-41.105468-101.503906-41.96875-179.683593-1.925781-78.183594 40.042969-124.492188 105.832031-103.4375 146.9375 21.054687 41.109375 101.503906 41.972657 179.683593 1.929688 78.183594-40.046875 124.492188-105.832031 103.4375-146.941407zm0 0"
      fill="#ffeb99"
    />
    <path
      d="m127.601562 67.3125c-33.042968 16.921875-98.421874 64.652344-85.210937 90.445312 13.210937 25.796876 85.335937 3.097657 123.191406-16.289062s98.421875-64.652344 85.210938-90.445312c-13.210938-25.796876-90.148438-.632813-123.191407 16.289062zm0 0"
      fill="#fff5ca"
    />
    <path
      d="m129.472656 292.339844c-16.398437 8.394531-40.765625 24.386718-59.53125 41.511718 29.25 10.671876 69.863282 19.75 121.964844 18.40625 34.519531-22.339843 71.347656-55.535156 60.757812-76.210937-13.210937-25.792969-90.148437-.632813-123.191406 16.292969zm0 0"
      fill="#ffde55"
    />
    <g fill="#57565c">
      <path d="m205.394531 84.4375c-3.742187 2.050781-8.4375.679688-10.488281-3.0625l-6.164062-11.246094c-2.050782-3.742187-.679688-8.441406 3.0625-10.492187 3.742187-2.050781 8.4375-.679688 10.488281 3.0625l6.164062 11.246093c2.050781 3.746094.679688 8.441407-3.0625 10.492188zm0 0" />
      <path d="m93.726562 145.640625c-3.742187 2.054687-8.4375.683594-10.488281-3.058594l-6.167969-11.25c-2.050781-3.742187-.679687-8.4375 3.0625-10.488281 3.742188-2.050781 8.4375-.679688 10.492188 3.0625l6.164062 11.246094c2.050782 3.742187.679688 8.4375-3.0625 10.488281zm0 0" />
      <path d="m146.765625 98.957031c-.296875 3.0625-2.363281 4.542969-3.257813 5.035157-.898437.492187-3.28125 1.480468-5.996093.039062-3.769531-2-8.449219-.570312-10.449219 3.199219-2.003906 3.769531-.570312 8.445312 3.199219 10.449219 6.496093 3.449218 14.222656 3.398437 20.671875-.136719 6.449218-3.535157 10.648437-10.019531 11.234375-17.351563.339843-4.253906-2.832031-7.976562-7.085938-8.316406-1.558593-.125-7.699219.703125-8.316406 7.082031zm0 0" />
    </g>
  </svg>
);

const StoreIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
    <line x1="3" y1="6" x2="21" y2="6"></line>
    <path d="M16 10a4 4 0 0 1-8 0"></path>
  </svg>
);

const HeartIcon = ({ liked }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill={liked ? "#d72638" : "none"}
    style={liked ? { stroke: "#d72638" } : {}}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
  </svg>
);

const CommentsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
  </svg>
);

const NewMessage = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
    <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
  </svg>
);

const UploadIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
    <polyline points="17 8 12 3 7 8"></polyline>
    <line x1="12" y1="3" x2="12" y2="15"></line>
  </svg>
);

const ClockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="12" cy="12" r="10"></circle>
    <polyline points="12 6 12 12 16 14"></polyline>
  </svg>
);

const MoreIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="12" cy="12" r="1"></circle>
    <circle cx="19" cy="12" r="1"></circle>
    <circle cx="5" cy="12" r="1"></circle>
  </svg>
);

const EditIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polygon points="16 3 21 8 8 21 3 21 3 16 16 3"></polygon>
  </svg>
);

const DeleteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="3 6 5 6 21 6"></polyline>
    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
    <line x1="10" y1="11" x2="10" y2="17"></line>
    <line x1="14" y1="11" x2="14" y2="17"></line>
  </svg>
);

const UsersIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
    <circle cx="9" cy="7" r="4"></circle>
    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
  </svg>
);

const CorrectIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="20 6 9 17 4 12"></polyline>
  </svg>
);

const IncorrectIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
);

const WarningIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="12" y1="8" x2="12" y2="12"></line>
    <line x1="12" y1="16" x2="12" y2="16"></line>
  </svg>
);

export {
  HomeIcon,
  SearchIcon,
  DiscoverIcon,
  MessagesIcon,
  StarIcon,
  PlusIcon,
  UserIcon,
  SettingsIcon,
  LogoutIcon,
  MenuIcon,
  CloseIcon,
  BackIcon,
  MenuMoreIcon,
  NewRecipeIcon,
  NewPostIcon,
  NewClipIcon,
  BellIcon,
  JellyBeanIcon,
  StoreIcon,
  HeartIcon,
  CommentsIcon,
  NewMessage,
  UploadIcon,
  ClockIcon,
  MoreIcon,
  EditIcon,
  DeleteIcon,
  UsersIcon,
  CorrectIcon,
  IncorrectIcon,
  WarningIcon,
};
