import React, { useState, createRef } from "react";
import { connect } from "react-redux";
import { styled, withTheme } from "@material-ui/core/styles";
import { AnimatePresence } from "framer-motion";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import MoreMenu from "./MoreMenu";
import { HeartIcon, CommentsIcon, StarIcon } from "../../Assets/Icons";
import HeartDumpling from "../HeartDumpling";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setFollowingFeed: (data) => dispatch({ type: "SET_FOLLOWING_FEED", payload: data }),
  setDiscoverFeed: (data) => dispatch({ type: "SET_DISCOVER_FEED", payload: data }),
  setUser: (data) => dispatch({ type: "UPDATE_USER_INFO", payload: data }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

function InteractionButtonBar(props) {
  const [liking, setLiking] = useState(false);
  const commentsEnd = createRef();

  const likeHandler = () => {
    setLiking(true);
    props.likeRecipe();
    setTimeout(() => {
      setLiking(false);
    }, 2000);
  };

  const scrollToBottom = () => {
    commentsEnd.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <ButtonBarWrapper>
        <ButtonContainer className={props.isAuthor ? "is-author" : ""}>
          <Button onClick={likeHandler}>
            <HeartIcon liked={props.liked} />
            {props.postLikes.length}
          </Button>
          <Button onClick={scrollToBottom}>
            <CommentsIcon />
            {props.comments}
          </Button>
          <Button onClick={props.toggleCollection}>
            <StarIcon inCollection={props.inCollection} />
          </Button>
          {props.isAuthor && (
            <MoreMenu
              recipeID={props.recipeID}
              showDetailsHandler={props.showDetailsHandler}
              showEditDrawer={props.showEditDrawer}
            />
          )}
          <AnimatePresence>
            <DumplingContainer>
              <HeartDumpling show={liking} />
            </DumplingContainer>
          </AnimatePresence>
        </ButtonContainer>
      </ButtonBarWrapper>
      <div ref={commentsEnd}></div>
    </>
  );
}

const ButtonBarWrapper = styled(Box)({
  width: "100%",
});
const ButtonContainer = styled(withTheme(Box))((props) => ({
  position: "fixed",
  bottom: 10,
  left: 0,
  right: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  maxWidth: 300,
  zIndex: 999,
  [props.theme.breakpoints.down("sm")]: {
    maxWidth: 270,
  },
  margin: "0 auto",
  borderRadius: "6rem",
  padding: "10px",
  "&.is-author": {
    maxWidth: "400px !important",
    [props.theme.breakpoints.down("sm")]: {
      maxWidth: "340px !important",
    },
  },

  "& button": {
    margin: 0,
    backgroundImage: "none",
    backgroundColor: "#fcfcfc",
    borderRadius: "100%",
    width: 80,
    height: 80,
    boxShadow: "0 2px 4px -1px rgba(0,0,0,0.35)",
    borderBottom: "1px solid rgba(0,0,0,0.125)",
    fontSize: 18,
    transition: "transform 0.25s ease",
    "&:active": {
      transform: "scale(0.9)",
    },
    [props.theme.breakpoints.down("sm")]: {
      width: 70,
      height: 70,
      boxShadow: "0 2px 6px -1px rgba(0,0,0,0.15)",
    },
    "&:hover": {
      backgroundColor: "#eeeeee",
      boxShadow: "0 2px 6px -1px rgba(0,0,0,0.15)",
    },
    "& svg": {
      stroke: "#454545",
      height: 20,
      width: 20,
      marginRight: 6,
    },
    "&:nth-child(3)": {
      "& svg": {
        marginRight: 0,
      },
    },
    "&:nth-child(4)": {
      "& svg": {
        marginRight: 0,
      },
    },
  },
}));

const DumplingContainer = styled(withTheme(Box))((props) => ({
  position: "absolute",
  zIndex: 99,
  left: -47,
  [props.theme.breakpoints.down("sm")]: {
    left: -52,
  },
}));

export default connect(mapStateToProps, mapDispatchToProps)(InteractionButtonBar);
