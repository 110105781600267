import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { styled, withTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import LockIcon from "@material-ui/icons/Lock";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Logo from "../../Assets/Logo/logo_large_purple_sidetext_alt.png";
import InputAdornment from "@material-ui/core/InputAdornment";
/* import SuccessAlert from "../Alerts/SuccessAlert"; */
import ErrorAlert from "../Alerts/ErrorAlert";
import SpinnerLoader from "../Loaders/SpinnerLoader";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch({ type: "UPDATE_USER_INFO", payload: data }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

function Alert(props) {
  return <MuiAlert variant="filled" {...props} />;
}

const ResetPassword = (props) => {
  const [resetInput, setResetInput] = useState({
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [showPasswordMismatch, setShowPasswordMismatch] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const token = props.location.search;

  const checkResetTokenHandler = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/resetauth${token}`, {
        withCredentials: true,
      });
      const data = await response.data;
      if (!data.errors) {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      } else {
        props.setError(data.errors);
        props.showErrors(true);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updatePasswordHandler = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/users/resetpassword${token}`,
        { password: resetInput.password },
        {
          withCredentials: true,
        }
      );
      const data = await response.data;
      if (!data.errors) {
        setLoading(true);
        setShowSnackbar(true);
        setTimeout(() => {
          props.setUser(data);
          setShowSnackbar(false);
          setLoading(false);
          props.history.push("/");
        }, 2500);
      } else {
        props.setError(data.errors);
        props.showErrors(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resetInputHandler = (event) => {
    setResetInput({ ...resetInput, [event.target.name]: event.target.value });
  };

  const goBackHandler = () => {
    props.showErrors(false);
    props.setError([]);
    props.history.push("/");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  useEffect(() => {
    if (
      resetInput.password === "" ||
      resetInput.confirmPassword === "" ||
      resetInput.password.startsWith(" ") ||
      resetInput.confirmPassword.startsWith(" ")
    ) {
      setDisabled(true);
      setShowPasswordMismatch(false);
    } else if (
      resetInput.password.length > 3 &&
      resetInput.confirmPassword.length > 0 &&
      resetInput.password !== resetInput.confirmPassword
    ) {
      setShowPasswordMismatch(true);
    } else if (resetInput.password === resetInput.confirmPassword) {
      setDisabled(false);
      setShowPasswordMismatch(false);
    }
  }, [resetInput]);

  useEffect(() => {
    checkResetTokenHandler();
  }, []);

  return (
    <ResetMainContainer display="flex" alignItems="center">
      <Grid container spacing={0} alignItems="center" justify="center">
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
          </Box>
        </Grid>
        <Grid item xs={10} sm={8} md={6} lg={4}>
          <ResetPanel
            component={motion.div}
            animate={{ y: [100, -10, 0], opacity: [0, 1, 1] }}
            transition={{ ease: "easeInOut", duration: 0.75 }}
          >
            <Right display="flex" flexDirection="column">
              {loading ? (
                <SpinnerLoader />
              ) : props.errors.errors.length > 0 && props.errors.show ? (
                <>
                  <ErrorAlert
                    show={props.errors.errors.length > 0 && props.errors.show}
                    text={props.errors.errors[0].msg}
                    filled
                  />
                  <BackButton color="primary" onClick={goBackHandler}>
                    Go Back
                  </BackButton>
                </>
              ) : (
                <>
                  <Box fontWeight="fontWeightBold" fontSize={18}>
                    Choose a new password
                  </Box>
                  <Box color="secondary" fontWeight="fontWeightLight" fontSize={20} mb={3}>
                    Enter the information below.
                  </Box>

                  <ErrorAlert text="Passwords do not match." show={!props.errors.show && showPasswordMismatch} />
                  <ErrorAlert
                    text={props.errors.errors.length > 0 && props.errors.errors[0].msg}
                    show={props.errors.show}
                  />

                  <form onSubmit={updatePasswordHandler}>
                    <Input
                      label="New Password"
                      type="password"
                      name="password"
                      color="secondary"
                      onChange={resetInputHandler}
                      value={resetInput.password}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon style={{ opacity: 0.55, color: "#735cdd" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Input
                      label="Confirm new password"
                      type="password"
                      name="confirmPassword"
                      color="secondary"
                      onChange={resetInputHandler}
                      value={resetInput.confirmPassword}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon style={{ opacity: 0.55, color: "#735cdd" }} />
                          </InputAdornment>
                        ),
                      }}
                    />

                    <ResetSubmitButton type="submit" color="primary" disabled={disabled}>
                      Reset Password
                    </ResetSubmitButton>
                  </form>
                </>
              )}
            </Right>
          </ResetPanel>
          <Snackbar open={showSnackbar} autoHideDuration={2000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Your password has been successfully changed, Logging In...
            </Alert>
          </Snackbar>
        </Grid>
      </Grid>
    </ResetMainContainer>
  );
};

const ResetMainContainer = styled(Box)({
  minHeight: "100vh",
  width: "100%",
  overflow: "hidden",
  "& a": {
    "& img": {
      width: "100%",
      maxWidth: 240,
      minWidth: 150,
      marginBottom: 30,
      opacity: 0.75,
      display: "block",
    },
  },
});

const ResetPanel = styled(withTheme(Box))((props) => ({
  borderRadius: "2rem",
  color: "#0f0423",
  position: "relative",
  zIndex: 999,
  boxShadow: "0 3px 4px -1px rgba(0,0,0,0.05)",
  borderBottom: "1px solid rgba(0,0,0,0.125)",
  backgroundColor: "#fcfcfc",
  maxWidth: 450,
  margin: "0 auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: 40,
  [props.theme.breakpoints.down("xs")]: {
    borderRadius: 0,
  },
  "& form": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    "& ~ div": {
      marginTop: 20,
    },
  },
  "& a": {
    color: "#735cdd",
    fontWeight: "bold",
  },
  "& .heightController": {
    height: "100%",
  },
  "& > div": {
    margin: "0 auto",
  },
}));

const Right = styled(withTheme(Box))((props) => ({
  margin: "2rem",
  padding: "30px",
  [props.theme.breakpoints.down("sm")]: {
    padding: "50px 30px",
  },
  height: "100%",
  position: "relative",
  zIndex: 999,
  display: "flex",
  justifyContent: "center",
  minHeight: 300,
  "& button": {
    marginTop: 10,
  },
}));

const Input = styled(TextField)({
  width: "100%",
  minWidth: 200,
  marginBottom: 30,
  color: "#735cdd !important",
});

const ResetSubmitButton = styled(Button)({
  width: "100%",
  minWidth: 200,
  padding: "10px",
});

const BackButton = styled(Button)({
  width: "100%",
  minWidth: 200,
  padding: "10px",
  position: "relative",
  zIndex: 999,
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
