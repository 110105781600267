import React from "react";
import { connect } from "react-redux";
import { styled, withTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import UserProfile from "../Components/Home/UserProfile";
import SuggestedUsers from "../Components/Home/SuggestedUsers";
import BannerAd from "../Components/Home/BannerAd";
import Box from "@material-ui/core/Box";
import HomeFeeds from "../Components/Home/HomeFeeds";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

const Home = () => {
  return (
    <HomeMainContainer>
      <Grid container spacing={0} style={{ maxWidth: 1800 }}>
        <LeftGrid item sm={5} md={4} lg={3}>
          <Left>
            <UserProfile />
            <SuggestedUsers />
            <BannerAd />
          </Left>
        </LeftGrid>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <Right>
            <HomeFeeds />
          </Right>
        </Grid>
      </Grid>
    </HomeMainContainer>
  );
};

const HomeMainContainer = styled(withTheme(Box))((props) => ({
  width: "100%",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  paddingTop: 10,
  [props.theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    paddingTop: 86,
  },
}));

const Left = styled(withTheme(Box))((props) => ({
  height: "92vh",
  minHeight: 900,
  margin: "0 10px 0 20px",
  padding: "30px 20px 20px 20px",
  boxShadow: "0 3px 4px -1px rgba(0,0,0,0.05)",
  borderRight: "1px solid rgba(0,0,0,0.05)",
  borderLeft: "1px solid rgba(0,0,0,0.05)",
  borderBottom: "1px solid rgba(0,0,0,0.125)",
  backgroundColor: "#fcfcfc",
  borderRadius: "1rem",
  display: "flex",
  alignItems: "flex-end",
  flexDirection: "column",
  maxWidth: 380,
  transition: "width 0.25s ease",
  [props.theme.breakpoints.down("md")]: {
    maxWidth: 340,
  },
  [props.theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const Right = styled(withTheme(Box))((props) => ({
  display: "flex",
  flexDirection: "column",
  height: "96vh",
  margin: "0 20px 0 10px",
  padding: "0 0 20px 20px",
  backgroundColor: "#e7e7e7",
  maxWidth: 1400,
  overflowY: "auto",
  overflowX: "hidden",
  scrollbarWidth: "none",
  position: "relative",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  [props.theme.breakpoints.down("sm")]: {
    margin: "0 10px 10px",
    padding: "0 10px 10px",
  },
}));

const LeftGrid = styled(withTheme(Grid))((props) => ({
  [props.theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export default connect(mapStateToProps, mapDispatchToProps)(Home);
