import React from "react";
import { Link } from "react-router-dom";
import { styled, withTheme } from "@material-ui/core/styles";
import { motion } from "framer-motion";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

function SingleUser({ data, delay }) {
  return (
    <SingleUserContainer item xs={12} sm={6} md={4} lg={3}>
      <Container
        component={motion.div}
        animate={{ y: [300, 0], opacity: [0, 0, 1] }}
        transition={{ ease: "easeInOut", duration: 0.5, delay: delay / 8 }}
      >
        <Link to={`/profile/${data._id}`}>
          <Button>
            <img src={data.image} alt="user-profile" />
            <Box>
              <Typography variant="body1" noWrap>
                {data.username}
              </Typography>
              <Typography variant="body2">
                {data.followers.length} {data.followers.length === 1 ? "Follower" : "Followers"}
              </Typography>
            </Box>
          </Button>
        </Link>
      </Container>
    </SingleUserContainer>
  );
}

const SingleUserContainer = styled(withTheme(Grid))((props) => ({
  "& button": {
    display: "flex",
    justifyContent: "flex-start",
    backgroundImage: "none",
    backgroundColor: "#fcfcfc",
    textTransform: "none",
    width: "100%",
    boxShadow: "0 3px 4px -1px rgba(0,0,0,0.05)",
    borderBottom: "1px solid rgba(0,0,0,0.125)",
    transition: "transform 0.25s ease",
    padding: "12px 20px",
    "&:hover": {
      backgroundColor: "#fcfcfc",
      borderBottom: "1px solid rgba(0,0,0,0.125)",
      boxShadow: "0 3px 4px -1px rgba(0,0,0,0.05)",
      transform: "scale(1.05)",
    },
    "& div": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
      textAlign: "left",
      maxWidth: "70%",
      [props.theme.breakpoints.up("xl")]: {
        maxWidth: "100%",
      },
      "& p": {
        color: "#adadad",
        maxWidth: "80%",
        [props.theme.breakpoints.up("xl")]: {
          maxWidth: "90%",
        },
        "&:first-child": {
          fontWeight: 500,
          color: "#454545",

          /*        maxWidth: "50%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis", */
        },
      },
    },
    "& img": {
      width: 64,
      height: 64,
      borderRadius: "100%",
      marginRight: 16,
    },
  },
}));

const Container = styled(Box)({
  opacity: 0,
});

export default SingleUser;
