import React, { useState } from "react";
import SingleComment from "./SingleComment";

function SingleCommentBlock(props) {
  const [showReplies, setShowReplies] = useState(false);

  return (
    <>
      <SingleComment
        recipeID={props.recipeID}
        data={props.data}
        showReplies={showReplies}
        setComments={props.setComments}
        setReplyingHandler={props.setReplyingHandler}
        setShowReplies={setShowReplies}
      />
      {showReplies &&
        props.data.comments.map((replyComment, index) => (
          <SingleComment
            key={index}
            recipeID={props.recipeID}
            data={replyComment}
            type="reply"
            setComments={props.setComments}
            setShowReplies={setShowReplies}
            replyingTo={props.data.username}
            replyingToID={props.data.userID}
          />
        ))}
    </>
  );
}

export default SingleCommentBlock;
