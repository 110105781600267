import React, { useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { styled } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { followHandler } from "../../Utils";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch({ type: "UPDATE_USER_INFO", payload: data }),
  setSuggestedUsers: (data) => dispatch({ type: "SET_SUGGESTED_USERS", payload: data }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

function SuggestedUsers(props) {
  const followUser = async (userID) => {
    await props.setUser(await followHandler(userID));
  };

  useEffect(() => {
    const getSuggestedUsers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/suggested`, {
          withCredentials: true,
        });
        const data = response.data;
        if (!data.errors) await props.setSuggestedUsers(data);
      } catch (error) {
        console.log(error);
      }
    };
    if (props.app.suggestedUsers.length < 5) getSuggestedUsers();
  }, []);

  return (
    <SuggestedUsersWrap>
      <SuggestedUsersContainer>
        <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: "100%" }}>
          <Typography variant="h6">Suggested Users</Typography>
          <Button style={{ marginBottom: 10 }}>View All</Button>
        </Box>

        {props.app.suggestedUsers &&
          props.app.suggestedUsers.map((user, index) => (
            <SingleUser key={index}>
              <Box>
                <img src={user.image} alt="profile" />
                <Link to={`/profile/${user._id}`}>{user.username}</Link>
              </Box>
              <Button onClick={() => followUser(user._id)}>
                {props.user.following.findIndex((followedUser) => followedUser.userID === user._id) !== -1
                  ? "Unfollow"
                  : "Follow"}
              </Button>
            </SingleUser>
          ))}
        {props.app.suggestedUsers.length === 0 && (
          <Typography
            variant="body2"
            style={{
              color: "#808080",
              backgroundColor: "rgba(0,0,0,0.05)",
              width: "100%",
              padding: "10px 20px",
              borderRadius: "1rem",
              fontWeight: 500,
              fontSize: 14,
            }}
          >
            No users to display.
          </Typography>
        )}
      </SuggestedUsersContainer>
    </SuggestedUsersWrap>
  );
}

const SuggestedUsersWrap = styled(Box)({
  display: "block",
  width: "100%",
  marginBottom: 20,
});

const SuggestedUsersContainer = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  flexDirection: "column",
  padding: 10,
  width: "100%",
  borderRadius: "1rem",

  "& h6": {
    fontWeight: "bold",
    fontSize: 14,
    color: "#808080",
    marginBottom: 10,
  },

  "& button": {
    backgroundColor: "transparent",
    backgroundImage: "none",
    padding: "6px 20px",
    fontSize: 12,
    fontWeight: "bold",
    color: "#735cdd",
    textTransform: "none",
  },
});

const SingleUser = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  margin: "2px 0px",
  borderRadius: "1rem",
  padding: 10,
  transition: "background-color 0.25s ease",
  width: "100%",
  "&:hover": {
    backgroundColor: "#ededed",
  },
  "& div": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    maxWidth: "70%",
  },
  "& a": {
    fontWeight: 500,
    color: "#454545",
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& img": {
    width: 36,
    height: 36,
    borderRadius: "100%",
    marginRight: 10,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SuggestedUsers);
