import React from "react";
import { styled } from "@material-ui/core/styles";
import Moment from "react-moment";
import Typography from "@material-ui/core/Typography";

function PostedAt({ date }) {
  return (
    <TimeText variant="body2">
      <span style={{ marginRight: 4 }}>Posted</span>
      <Moment fromNow ago>
        {date}
      </Moment>
      <span style={{ marginLeft: 4 }}>ago</span>
    </TimeText>
  );
}

const TimeText = styled(Typography)({
  marginBottom: 10,
});

export default PostedAt;
