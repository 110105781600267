import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { styled, withTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import EmailIcon from "@material-ui/icons/Email";
import Logo from "../../Assets/Logo/logo_large_purple_sidetext_alt.png";
import FPLottie from "./FPLottie";
import InputAdornment from "@material-ui/core/InputAdornment";
import SuccessAlert from "../Alerts/SuccessAlert";
import ErrorAlert from "../Alerts/ErrorAlert";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch({ type: "UPDATE_USER_INFO", payload: data }),
  setLoading: (boolean) => dispatch({ type: "SET_LOADING", payload: boolean }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

const ForgotPassword = (props) => {
  const [emailInput, setEmailInput] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const forgottenPasswordHandler = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/users/forgotpassword`,
        {
          email: emailInput,
        },
        { withCredentials: true }
      );
      const data = response.data;
      if (data.errors) {
        props.setError(data.errors);
        props.showErrors(true);
        setTimeout(() => {
          props.showErrors(false);
          props.setError([]);
        }, 3000);
      } else {
        setShowSuccess(true);
        setEmailInput("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (emailInput !== "" && !emailInput.startsWith(" ") && emailInput.length > 3) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [emailInput]);

  return (
    <FPFullWrap>
      <FPMainContainer display="flex" alignItems="center">
        <Grid container spacing={0} alignItems="center" justify="center">
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" className="height-scroll">
              <Link to="/">
                <img src={Logo} alt="logo" />
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} lg={7} xl={5}>
            <FPPanel
              component={motion.div}
              animate={{ y: [100, -10, 0], opacity: [0, 1, 1] }}
              transition={{ ease: "easeInOut", duration: 0.75 }}
            >
              <Grid container spacing={0}>
                <Grid item xs={12} md={6}>
                  <FPLottie />
                </Grid>
                <Grid item xs={12} md={6} className="heightController">
                  <Right display="flex" flexDirection="column">
                    <Box fontWeight="fontWeightBold" fontSize={18}>
                      Recover your account
                    </Box>
                    <Box color="secondary" fontWeight="fontWeightLight" fontSize={20} mb={3}>
                      Enter your email below.
                    </Box>

                    <ErrorAlert
                      text={props.errors.errors.length > 0 && props.errors.errors[0].msg}
                      show={props.errors.show}
                    />

                    <SuccessAlert text="Success! Please check your email." show={showSuccess} />

                    <form onSubmit={forgottenPasswordHandler}>
                      <Input
                        id="input-with-icon-adornment"
                        type="email"
                        name="email"
                        color="secondary"
                        onChange={(event) => setEmailInput(event.target.value)}
                        value={emailInput}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon style={{ opacity: 0.55, color: "#735cdd" }} />
                            </InputAdornment>
                          ),
                        }}
                      />

                      <FPSubmitButton type="submit" color="primary" disabled={disabled}>
                        Send Email
                      </FPSubmitButton>
                    </form>

                    <Box mt={2}>
                      <Typography variant="body2" align="center">
                        Don't have an account? <Link to="/register">Register Now</Link>
                      </Typography>
                    </Box>
                  </Right>
                </Grid>
              </Grid>
            </FPPanel>
          </Grid>
        </Grid>
      </FPMainContainer>
    </FPFullWrap>
  );
};

const FPMainContainer = styled(withTheme(Box))((props) => ({
  height: "100vh",
  width: "100%",
  "& a": {
    "& img": {
      width: "100%",
      maxWidth: 240,
      minWidth: 150,
      opacity: 0.75,
      display: "block",
      [props.theme.breakpoints.down("sm")]: {
        marginTop: 30,
      },
    },
  },
}));

const FPFullWrap = styled(Box)({
  overflow: "auto",
  "&::-webkit-scrollbar": {
    width: 6,
    height: 6,
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,0.25)",
    borderRadius: "2rem",
  },
});

const FPPanel = styled(withTheme(Box))((props) => ({
  borderRadius: "2rem",
  color: "#0f0423",
  position: "relative",
  zIndex: 999,
  backgroundColor: "#fcfcfc",
  boxShadow: "0 3px 4px -1px rgba(0,0,0,0.05)",
  borderBottom: "1px solid rgba(0,0,0,0.125)",
  margin: "30px auto 40px",
  maxWidth: 815,
  [props.theme.breakpoints.down("xs")]: {
    borderRadius: 0,
  },
  "& form": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    "& ~ div": {
      marginTop: 20,
    },
  },
  "& a": {
    color: "#735cdd",
    fontWeight: "bold",
  },
  "& .heightController": {
    height: "100%",
  },
}));

const Right = styled(withTheme(Box))((props) => ({
  margin: "2rem",
  padding: "30px",
  [props.theme.breakpoints.down("sm")]: {
    padding: "50px 30px",
  },
  height: "100%",
  position: "relative",
  zIndex: 999,
  display: "flex",
  justifyContent: "center",
  minHeight: 300,
  "& button": {
    marginTop: 10,
  },
}));

const Input = styled(TextField)({
  width: "100%",
  minWidth: 200,
  marginBottom: 20,
  color: "#735cdd !important",
});

const FPSubmitButton = styled(Button)({
  width: "100%",
  minWidth: 200,
  padding: "10px",
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
