import React from "react";
import { connect } from "react-redux";
import { styled, withTheme } from "@material-ui/core/styles";
import { AnimatePresence, motion } from "framer-motion";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TitleBar from "../Components/UserSettings/TitleBar";
import UserImageBlock from "../Components/UserSettings/UserImageBlock";
import MainInfo from "../Components/UserSettings/MainInfo";
import LogoutButton from "../Components/UserSettings/LogoutButton";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setFollowingFeed: (data) => dispatch({ type: "SET_FOLLOWING_FEED", payload: data }),
  setDiscoverFeed: (data) => dispatch({ type: "SET_DISCOVER_FEED", payload: data }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

const PopupUserSettingsDrawer = (props) => {
  return (
    <>
      <AnimatePresence>
        {props.show && (
          <PopupPostContainer
            component={motion.div}
            animate={{ y: [2000, -5, 0], opacity: [0, 1, 1] }}
            transition={{ ease: "easeInOut", duration: 0.5 }}
            exit={{ y: [0, -5, 2000], opacity: [1, 1, 0] }}
          >
            <PopupGridContainer container spacing={3}>
              <UserSettingsGridItem item xs={12}>
                <TitleBar showSettings={props.showSettings} />
              </UserSettingsGridItem>
              <UserSettingsGridItem item xs={12}>
                <UserImageBlock />
              </UserSettingsGridItem>
              <UserSettingsGridItem item xs={12}>
                <MainInfo />
              </UserSettingsGridItem>
              <UserSettingsGridItem item xs={12}>
                <LogoutButton />
              </UserSettingsGridItem>
            </PopupGridContainer>
          </PopupPostContainer>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {props.show && (
          <FullPageWrap
            component={motion.div}
            animate={{ opacity: [0, 1] }}
            transition={{ ease: "easeInOut", duration: 0.5 }}
            exit={{ opacity: [1, 0] }}
            onClick={() => props.showSettings(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

const PopupGridContainer = styled(Grid)({
  maxHeight: "100%",
  overflowY: "auto",
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    width: 6,
    height: 6,
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,0.05)",
    borderRadius: "2rem",
  },
});

const FullPageWrap = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,0.25)",
  zIndex: 99,
  webkitTransform: "translate3d(0,0,0)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 20,
  opacity: 0,
});

const PopupPostContainer = styled(withTheme(Box))((props) => ({
  width: "100%",
  height: "92%",
  borderRadius: "2rem 2rem 0 0",
  maxWidth: 1000,
  padding: "30px 20px 20px 30px",
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 999,
  webkitTransform: "translate3d(0,0,0)",
  margin: "0 auto",
  opacity: 0,
  backgroundColor: "rgba(255,255,255,0.75)",
  backdropFilter: "blur(4px)",
  [props.theme.breakpoints.up("xl")]: {
    maxWidth: 1200,
  },
}));

const UserSettingsGridItem = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  "& h4": {
    marginBottom: 10,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PopupUserSettingsDrawer);
