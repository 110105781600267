import React from "react";
import { styled } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";

function ProfileLoaderFeed({ numberOfElements }) {
  return (
    <Grid container spacing={3}>
      {numberOfElements !== 0
        ? [...Array(numberOfElements)].map((box, index) => (
            <LoaderGridItem item key={index} xs={12} sm={6} lg={4} xl={3}>
              <Skeleton variant="rect" animation="wave" />
            </LoaderGridItem>
          ))
        : [...Array(9)].map((box, index) => (
            <LoaderGridItem item key={index} xs={12} sm={6} lg={4} xl={3}>
              <Skeleton variant="rect" animation="wave" />
            </LoaderGridItem>
          ))}
    </Grid>
  );
}

const LoaderGridItem = styled(Grid)({
  height: 280,
  minHeight: 200,
});

export default ProfileLoaderFeed;
