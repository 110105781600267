import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { styled, withTheme } from "@material-ui/core/styles";
import { AnimatePresence, motion } from "framer-motion";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { UploadIcon, PlusIcon, CloseIcon, CorrectIcon, IncorrectIcon } from "../Assets/Icons";
import SingleIngredientBlock from "./RecipeBuilder/SingleIngredientBlock";
import SingleInstructionBlock from "./RecipeBuilder/SingleInstructionBlock";
import SpinnerLoader from "./Loaders/SpinnerLoader";
import ErrorAlert from "./Alerts/ErrorAlert";
import { getPosts_discover } from "../Utils";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setFollowingFeed: (data) => dispatch({ type: "SET_FOLLOWING_FEED", payload: data }),
  setDiscoverFeed: (data) => dispatch({ type: "SET_DISCOVER_FEED", payload: data }),
  setShowBuilderDrawer: (boolean) => dispatch({ type: "SET_SHOW_BUILDER_DRAWER", payload: boolean }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

const tags = [
  "Beef",
  "Breakfast",
  "Chicken",
  "Chocolatey",
  "Cold",
  "Dairy Free",
  "Dessert",
  "Dinner",
  "Fish",
  "Gluten Free",
  "Hot",
  "Lamb",
  "Lunch",
  "Mild",
  "Pescatarian",
  "Pork",
  "Quick & Easy",
  "Salmon",
  "Seafood",
  "Sour",
  "Spicy",
  "Sweet",
  "Vegan",
  "Vegetarian",
];

const cuisine = [
  "African",
  "American",
  "Brazilian",
  "British",
  "Caribbean",
  "Chinese",
  "Ethiopian",
  "French",
  "Greek",
  "Indian",
  "Indonesian",
  "Irish",
  "Israeli",
  "Italian",
  "Jamaican",
  "Japanese",
  "Korean",
  "Lebanese",
  "Malaysian",
  "Mexican",
  "Middle Eastern",
  "Moroccan",
  "Nigerian",
  "Peruvian",
  "Polish",
  "Portuguese",
  "Romanian",
  "Russian",
  "Singaporean",
  "South American",
  "Spanish",
  "Sri Lankan",
  "Thai",
  "Turkish",
  "Vegetarian",
  "Vietnamese",
];

const PopupEditDrawer = (props) => {
  const [loading, setLoading] = useState(false);
  const [newMainImage, setNewMainImage] = useState([]);
  const [currentMainImage, setCurrentMainImage] = useState(props.data.images[0]);
  const [recipeDetails, setRecipeDetails] = useState({
    recipeName: props.data.name,
    activeTime: props.data.activeTime.value,
    totalTime: props.data.totalTime.value,
    numberOfServings: props.data.yield.value,
  });

  const [ingredients, setIngredients] = useState(props.data.ingredients);
  const newIngredient = { name: "", quantity: 0, units: "", link: "" };

  const [preparationInstructions, setPreparationInstructions] = useState(props.data.preparation);

  const [instructions, setInstructions] = useState(props.data.instructions);
  const newInstruction = { text: "" };

  const [selectedCuisine, setSelectedCuisine] = useState(props.data.cuisine);
  const [selectedTags, setSelectedTags] = useState(props.data.tags);

  const uploadPictureHandler = async (recipeID) => {
    try {
      let formData = new FormData();
      let blob = new Blob([newMainImage][0], { type: "image/jpeg" });
      formData.append("image", blob);
      await axios.post(`${process.env.REACT_APP_BASE_URL}/recipes/${recipeID}/uploadphoto`, formData, {
        withCredentials: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updatedRecipeHandler = async (event) => {
    event.preventDefault();
    props.showErrors(false);
    setLoading(true);
    try {
      const updatedRecipe = {
        author_picture: props.user.image,
        name: recipeDetails.recipeName,
        cuisine: selectedCuisine,
        tags: selectedTags,
        yield: { value: recipeDetails.numberOfServings, units: "servings" },
        activeTime: { value: recipeDetails.activeTime, units: "minutes" },
        totalTime: { value: recipeDetails.totalTime, units: "minutes" },
        preparation: preparationInstructions,
        ingredients: ingredients,
        instructions: instructions,
      };
      const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/recipes/${props.data._id}`, updatedRecipe, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = response.data;
      if (!data.errors) {
        await uploadPictureHandler(props.data._id);
        setTimeout(async () => {
          await props.setDiscoverFeed(await getPosts_discover());
          await props.showEditDrawer(false);
          setLoading(false);
        }, 1500);
      } else {
        setTimeout(() => {
          props.showErrors(true);
        }, 1500);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateRecipeDetails = (event) => {
    setRecipeDetails({ ...recipeDetails, [event.target.name]: event.target.value });
  };

  const newPostImageUploadHandler = (event) => {
    setNewMainImage(event.target.files);
    setCurrentMainImage(URL.createObjectURL(event.target.files[0]));
  };

  const updateIngredientHandler = (event, index) => {
    const updatedIngredient = { ...ingredients[index] };
    updatedIngredient[event.target.name] = event.target.value;
    const currentIngredients = [...ingredients];
    currentIngredients.splice(index, 1, updatedIngredient);
    setIngredients(currentIngredients);
  };

  const createNewIngredientBlock = () => {
    setIngredients([...ingredients, newIngredient]);
  };

  const deleteIngredientBlock = (indexToRemove) => {
    const currentIngredients = [...ingredients];
    currentIngredients.splice(indexToRemove, 1);
    setIngredients(currentIngredients);
  };

  const createNewInstructionBlock = () => {
    setInstructions([...instructions, newInstruction]);
  };

  const deleteInstructionBlock = (indexToRemove) => {
    const currentInstructions = [...instructions];
    currentInstructions.splice(indexToRemove, 1);
    setInstructions(currentInstructions);
  };

  const updateInstructionHandler = (event, index) => {
    const updatedInstruction = { ...instructions[index] };
    updatedInstruction[event.target.name] = event.target.value;
    const currentInstructions = [...instructions];
    currentInstructions.splice(index, 1, updatedInstruction);
    setInstructions(currentInstructions);
  };

  const selectTagHandler = (tag) => {
    if (selectedTags.findIndex((text) => tag === text) === -1) {
      setSelectedTags([...selectedTags, tag]);
    } else {
      setSelectedTags(selectedTags.filter((selectedTag) => selectedTag !== tag));
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <AnimatePresence>
        {props.show && (
          <PopupPostContainer
            component={motion.div}
            animate={{ y: [2000, -5, 0], opacity: [0, 1, 1] }}
            transition={{ ease: "easeInOut", duration: 0.5 }}
            exit={{ y: [0, -5, 2000], opacity: [1, 1, 0] }}
          >
            <PopupPostWrapper>
              <TopBar>
                <Typography variant="h4">Edit a Recipe</Typography>
                <Button
                  onClick={() => {
                    props.showEditDrawer(false);
                  }}
                >
                  <CloseIcon />
                </Button>
              </TopBar>
              {props.errors.show && (
                <ErrorAlert
                  text="Error posting recipe, please check that all information is correct and try again."
                  show={props.errors.show}
                />
              )}
              <BuilderContainer>
                {loading ? (
                  <Box display="flex" alignItems="center" justifyContent="center" mt={3} style={{ height: "100%" }}>
                    <SpinnerLoader />
                  </Box>
                ) : (
                  <form autoComplete="off" onSubmit={updatedRecipeHandler}>
                    <MainImageContainer style={currentMainImage ? { maxWidth: "40%" } : {}}>
                      {currentMainImage && <img src={currentMainImage} alt="recipe-main" />}
                      <ImageUploader>
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="raised-button-file"
                          type="file"
                          onChange={newPostImageUploadHandler}
                        />
                        <label htmlFor="raised-button-file">
                          <Button component="span" className="upload-btn">
                            <UploadIcon />
                          </Button>

                          {!currentMainImage && <Typography variant="body2">Choose a main image</Typography>}
                        </label>
                        {currentMainImage && (
                          <Button
                            component="span"
                            className="reset-btn"
                            onClick={() => {
                              setNewMainImage([]);
                              setCurrentMainImage("");
                            }}
                          >
                            Reset
                          </Button>
                        )}
                      </ImageUploader>
                    </MainImageContainer>
                    <Grid container spacing={3}>
                      <RecipeGridItem item xs={12} md={8}>
                        <Input
                          name="recipeName"
                          variant="outlined"
                          color="secondary"
                          label="Choose a name for your recipe (8 Characters Minimum)"
                          autoFocus={true}
                          value={recipeDetails.recipeName}
                          onChange={updateRecipeDetails}
                          inputProps={{ minLength: 8 }}
                          required
                        />
                      </RecipeGridItem>
                      <RecipeGridItem item xs={12} md={4}>
                        <FormControl variant="outlined">
                          <InputLabel id="demo-simple-select-outlined-label">Cuisine</InputLabel>
                          <SelectInput
                            label="Cuisine"
                            color="secondary"
                            value={selectedCuisine}
                            onChange={(event) => setSelectedCuisine(event.target.value)}
                            required
                          >
                            {cuisine.map((cuisine, index) => (
                              <MenuItem key={index} value={cuisine}>
                                {cuisine}
                              </MenuItem>
                            ))}
                          </SelectInput>
                        </FormControl>
                      </RecipeGridItem>
                      <RecipeGridItem item xs={12} md={4}>
                        <Input
                          type="number"
                          name="activeTime"
                          variant="outlined"
                          color="secondary"
                          label="Active Cooking Time (Minutes)"
                          value={recipeDetails.activeTime}
                          onChange={updateRecipeDetails}
                          required
                        />
                      </RecipeGridItem>
                      <RecipeGridItem item xs={12} md={4}>
                        <Input
                          type="number"
                          name="totalTime"
                          variant="outlined"
                          color="secondary"
                          label="Total Cooking Time (Minutes)"
                          value={recipeDetails.totalTime}
                          onChange={updateRecipeDetails}
                          required
                        />
                      </RecipeGridItem>
                      <RecipeGridItem item xs={12} md={4}>
                        <Input
                          type="number"
                          variant="outlined"
                          name="numberOfServings"
                          color="secondary"
                          label="Number of servings"
                          value={recipeDetails.numberOfServings}
                          onChange={updateRecipeDetails}
                          required
                        />
                      </RecipeGridItem>
                      <Grid item xs={12}>
                        <PopoutDrawer>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="h5">Ingredients</Typography>
                          </AccordionSummary>
                          <PopoutDrawerDetails style={{ width: "100%" }}>
                            <AnimatePresence>
                              {ingredients.length > 0 &&
                                ingredients.map((ingredient, index) => (
                                  <SingleIngredientBlock
                                    key={index}
                                    index={index}
                                    data={ingredient}
                                    deleteBlock={deleteIngredientBlock}
                                    updateData={updateIngredientHandler}
                                  />
                                ))}
                            </AnimatePresence>

                            <CreateIngredientBlockButton>
                              <Button onClick={createNewIngredientBlock}>
                                <PlusIcon /> Add Ingredient
                              </Button>
                            </CreateIngredientBlockButton>
                          </PopoutDrawerDetails>
                        </PopoutDrawer>
                      </Grid>
                      <Grid item xs={12}>
                        <PopoutDrawer>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="h5">Preparation</Typography>
                          </AccordionSummary>
                          <PopoutDrawerDetails style={{ width: "100%" }}>
                            <AnimatePresence>
                              <Input
                                type="number"
                                variant="outlined"
                                color="secondary"
                                label="Preparation Instructions"
                                multiline={true}
                                value={preparationInstructions}
                                onChange={(event) => setPreparationInstructions(event.target.value)}
                                required
                              />
                            </AnimatePresence>
                          </PopoutDrawerDetails>
                        </PopoutDrawer>
                      </Grid>
                      <Grid item xs={12}>
                        <PopoutDrawer>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="h5">Instructions</Typography>
                          </AccordionSummary>
                          <PopoutDrawerDetails style={{ width: "100%" }}>
                            <AnimatePresence>
                              {instructions.length > 0 &&
                                instructions.map((instruction, index) => (
                                  <SingleInstructionBlock
                                    key={index}
                                    index={index}
                                    data={instruction}
                                    deleteBlock={deleteInstructionBlock}
                                    updateData={updateInstructionHandler}
                                  />
                                ))}
                            </AnimatePresence>

                            <CreateIngredientBlockButton>
                              <Button onClick={createNewInstructionBlock}>
                                <PlusIcon /> Add Instruction
                              </Button>
                            </CreateIngredientBlockButton>
                          </PopoutDrawerDetails>
                        </PopoutDrawer>
                      </Grid>
                      <Grid item xs={12}>
                        <PopoutDrawer>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography variant="h5">Tags</Typography>
                          </AccordionSummary>
                          <PopoutDrawerDetails style={{ width: "100%" }}>
                            <AnimatePresence>
                              {tags.map((tag, index) => (
                                <SingleTag
                                  key={index}
                                  onClick={() => selectTagHandler(tag)}
                                  className={selectedTags.findIndex((text) => tag === text) !== -1 ? "selected" : ""}
                                >
                                  {tag}
                                </SingleTag>
                              ))}
                            </AnimatePresence>
                          </PopoutDrawerDetails>
                        </PopoutDrawer>
                      </Grid>
                    </Grid>
                    <ButtonBar>
                      <Button type="submit">
                        <CorrectIcon />
                      </Button>
                      <Button
                        onClick={() => {
                          props.showEditDrawer(false);
                        }}
                      >
                        <IncorrectIcon />
                      </Button>
                    </ButtonBar>
                  </form>
                )}
              </BuilderContainer>
            </PopupPostWrapper>
          </PopupPostContainer>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {props.show && (
          <FullPageWrap
            component={motion.div}
            animate={{ opacity: [0, 1] }}
            transition={{ ease: "easeInOut", duration: 0.5 }}
            exit={{ opacity: [1, 0] }}
          />
        )}
      </AnimatePresence>
    </>
  );
};

const FullPageWrap = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,0.25)",
  zIndex: 99,
  webkitTransform: "translate3d(0,0,0)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 20,
  opacity: 0,
});

const PopupPostContainer = styled(withTheme(Box))((props) => ({
  width: "100%",
  height: "92%",
  borderRadius: "2rem 2rem 0 0",
  maxWidth: 1000,
  padding: "30px 20px 20px 30px",
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 999,
  webkitTransform: "translate3d(0,0,0)",
  margin: "0 auto",
  opacity: 0,
  backgroundColor: "rgba(255,255,255,0.75)",
  backdropFilter: "blur(4px)",
  [props.theme.breakpoints.up("xl")]: {
    maxWidth: 1200,
  },
}));

const PopupPostWrapper = styled(Box)({
  padding: 20,
  paddingBottom: 90,
  maxHeight: "100%",
  overflowY: "auto",
  overflowX: "hidden",
  position: "relative",
  "&::-webkit-scrollbar": {
    width: 6,
    height: 6,
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,0.05)",
    borderRadius: "2rem",
  },
});

const TopBar = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: 30,
  "& Button": {
    backgroundColor: "transparent",
    backgroundImage: "none",
    borderRadius: "100%",
    minWidth: 0,
    "&:hover": {
      backgroundColor: "#e7e7e7",
    },
    "& svg": {
      width: 40,
      height: 40,
    },
  },
  "& h4": {
    fontWeight: 700,
  },
});

const BuilderContainer = styled(Box)({
  padding: "20px 0",

  "& .upload-btn": {
    backgroundColor: "#454545",
    backgroundImage: "none",
    minWidth: 0,
    width: 40,
    height: 40,
    padding: 10,
    borderRadius: "100%",
    "& span": {
      "& svg": {
        stroke: "#fcfcfc",
        height: 40,
        width: 40,
        position: "relative",
        zIndex: 10,
      },
    },
  },

  "& .reset-btn": {
    backgroundColor: "#d72638",
    color: "#fcfcfc",
    backgroundImage: "none",
    minWidth: 0,
    padding: "4px 20px",
    borderRadius: "2rem",
    marginLeft: 10,
  },
});

const Input = styled(TextField)({
  width: "100%",
  minWidth: 200,
  color: "#735cdd",
  fontWeight: 24,
  borderRadius: "0.5rem",
  backgroundColor: "rgba(255,255,255,0.65)",
  boxShadow: "0 3px 3px -1px rgba(0,0,0,0.1)",
  "& input": {
    fontSize: 24,
  },
  "& fieldset": {
    border: "none",
    "& legend": {
      "& span": {
        fontSize: 24,
      },
    },
  },
});

const SelectInput = styled(Select)({
  width: "100%",
  height: "100%",
  minWidth: 200,
  color: "#735cdd",
  fontWeight: 24,
  backgroundColor: "rgba(255,255,255,0.65)",
  boxShadow: "0 3px 3px -1px rgba(0,0,0,0.1)",
  "& input": {
    fontSize: 24,
  },
  "& fieldset": {
    border: "none",
    "& legend": {
      "& span": {
        backgroundColor: "red",
        fontSize: 24,
      },
    },
  },
});

const MainImageContainer = styled(Box)({
  display: "flex",
  width: "100%",
  minHeight: 200,
  backgroundColor: "rgba(255,255,255,0.65)",
  borderRadius: "1rem",
  position: "relative",
  overflow: "hidden",
  marginBottom: 40,
  boxShadow: "0 3px 3px -1px rgba(0,0,0,0.1)",
  "& img": {
    objectFit: "contain",
    width: "100%",
  },
  "& p": {
    fontWeight: 500,
    marginTop: 4,
  },
});

const ImageUploader = styled(Box)({
  position: "absolute",
  left: 14,
  bottom: 10,
});

const RecipeGridItem = styled(Grid)({
  padding: 0,
  "& h5": {
    fontWeight: 500,
    marginBottom: 20,
  },
});

const CreateIngredientBlockButton = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",

  margin: 20,
  "& button": {
    backgroundColor: "#e7e7e7",
    backgroundImage: "none",
    borderRadius: "2rem",
    minWidth: 0,
    padding: "10px 20px",
    "& svg": {
      stroke: "#454545",
      height: 24,
      width: 24,
      marginRight: 6,
    },
  },
});

const PopoutDrawer = styled(Accordion)({
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  borderRadius: 4,
  padding: "0px 10px",
  backgroundColor: "rgba(255,255,255,0.65)",
  boxShadow: "0 3px 3px -1px rgba(0,0,0,0.1)",
  "& h5": {
    fontSize: 18,
    fontWeight: 700,
  },
  "&::before": {
    display: "none",
  },
});

const PopoutDrawerDetails = styled(AccordionDetails)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  width: "100%",
});

const ButtonBar = styled(Box)({
  padding: 20,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "1rem 1rem 0rem 0rem",
  marginTop: 40,
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  maxWidth: 600,
  margin: "0 auto",
  zIndex: 99,
  "& button": {
    marginRight: 20,
    backgroundImage: "none",
    backgroundColor: "#fcfcfc",
    borderRadius: "100%",
    width: 80,
    height: 80,
    boxShadow: "0 2px 4px -1px rgba(0,0,0,0.35)",
    borderBottom: "1px solid rgba(0,0,0,0.125)",
    fontSize: 18,
    "& svg": {
      stroke: "#454545",
      width: 32,
      height: 32,
    },

    "&:first-child": {
      backgroundColor: "rgba(138, 201, 38, 0.8)",
      backgroundImage: "none",
      color: "#212121",
    },
    "&:last-child": {
      backgroundColor: "rgba(0,0,0,0.1)",
      backgroundImage: "none",
    },
  },
});

const SingleTag = styled(Box)({
  padding: "10px 20px",
  backgroundColor: "rgba(0,0,0,0.1)",
  borderRadius: "2rem",
  margin: "6px",
  fontWeight: 500,
  fontSize: 14,
  cursor: "pointer",
  minWidth: 80,
  textAlign: "center",
  "&.selected": {
    backgroundColor: "#5d08e9",
    backgroundImage: "linear-gradient(135deg, #5805e9 0%, #8b20eb 100%)",
    color: "#fcfcfc",
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PopupEditDrawer);
