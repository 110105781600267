import React from "react";
import { styled, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

const clips = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];

function Clips(props) {
  return (
    <ClipsMainWrap>
      <ClipsContainer>
        {clips.map((clip, index) => (
          <Button key={index}>
            <SingleClip>
              <img src={props.user.image} alt="profile" />
              <Typography variant="body2">Username</Typography>
            </SingleClip>
          </Button>
        ))}
        <ComingSoon>
          <Typography variant="body1">Clips coming soon.</Typography>
        </ComingSoon>
      </ClipsContainer>
    </ClipsMainWrap>
  );
}

const ClipsMainWrap = styled(Box)({
  marginBottom: 30,
  flex: "0 1 auto",
});

const ClipsContainer = styled(withTheme(Box))((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  /*  overflowX: "auto", */
  /*  overflowY: "hidden", */
  overflow: "hidden",
  padding: "4px 0px 42px",
  marginTop: 0,
  marginLeft: -4,
  position: "relative",

  "&::-webkit-scrollbar": {
    width: "1em",
    height: 6,
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,0.125)",
    borderRadius: "2rem",
  },

  "& button": {
    backgroundColor: "transparent",
    backgroundImage: "none",
    minWidth: 98,
    margin: "0 12px",
    borderRadius: "100%",
    [props.theme.breakpoints.down("sm")]: {
      minWidth: 78,
      margin: "0 6px",
    },

    "& p": {
      fontWeight: 500,
      textTransform: "none",
      color: "#0f0423",
    },
  },
}));

const SingleClip = styled(withTheme(Box))((props) => ({
  position: "relative",
  width: 86,
  height: 86,
  borderRadius: "1rem",
  cursor: "pointer",
  margin: 0,
  [props.theme.breakpoints.down("sm")]: {
    width: 64,
    height: 64,
  },

  "&::before": {
    content: `""`,
    position: "absolute",
    left: -2,
    right: -2,
    bottom: -2,
    top: -2,
    [props.theme.breakpoints.down("sm")]: {
      left: -1,
      right: -1,
      bottom: -1,
      top: -1,
    },
    borderRadius: "100%",
    backgroundColor: "#5805e9",
    backgroundImage: "linear-gradient(90deg, #5805e9 0%, #8b20eb 100%)",
    opacity: 0.65,
  },
  "&::after": {
    content: `""`,
    position: "absolute",
    left: -2,
    right: -2,
    bottom: -2,
    top: -2,
    [props.theme.breakpoints.down("sm")]: {
      left: -1,
      right: -1,
      bottom: -1,
      top: -1,
    },
    borderRadius: "100%",
    backgroundColor: "#5805e9",
    backgroundImage: "linear-gradient(90deg, #5805e9 0%, #8b20eb 100%)",
    filter: "blur(3px)",
    opacity: 0.55,
  },
  "& img": {
    position: "relative",
    zIndex: 9,
    width: 86,
    height: 86,
    borderRadius: "100%",
    [props.theme.breakpoints.down("sm")]: {
      width: 64,
      height: 64,
    },
  },
}));

const ComingSoon = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 9,
  backgroundColor: "rgba(120,120,120,0.2)",
  padding: 20,
  borderRadius: "1rem",
  margin: "0 auto",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& p": {
    fontWeight: 500,
    color: "#fcfcfc",
    textShadow: "0px 2px 10px rgba(0,0,0,1)",
    pointerEvents: "none",
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Clips);
