import React from "react";
import { styled } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { ClockIcon, UsersIcon } from "../../Assets/Icons";

function Timings({ activeTime, totalTime, servings }) {
  return (
    <TimingsContainer container spacing={1}>
      <Grid item xs={12} lg={8} style={{ margin: 0, display: "flex", alignItems: "center", marginLeft: -8 }}>
        <SingleTimingData item xs={6}>
          <Box style={{ border: "2px solid #454545", padding: 20, borderRadius: "1rem", height: "100%" }}>
            <Box className="top-content">
              <ClockIcon />
              <Typography variant="h6">{activeTime.value}</Typography>
              <Typography variant="body1">minutes</Typography>
            </Box>
            <Typography variant="body1">Active Cooking Time</Typography>
          </Box>
        </SingleTimingData>
        <SingleTimingData item xs={6}>
          <Box style={{ border: "2px solid #454545", padding: 20, borderRadius: "1rem", height: "100%" }}>
            <Box className="top-content">
              <ClockIcon />
              <Typography variant="h6">{totalTime.value}</Typography>
              <Typography variant="body1">minutes</Typography>
            </Box>
            <Typography variant="body1">Total Cooking Time</Typography>
          </Box>
        </SingleTimingData>
      </Grid>
      <SingleTimingData item xs={12} lg={4}>
        <Box style={{ border: "2px solid #454545", padding: 20, borderRadius: "1rem", marginRight: 16 }}>
          <Box className="top-content">
            <UsersIcon />
            <Typography variant="h6">{servings}</Typography>
            <Typography variant="body1">servings</Typography>
          </Box>
          <Typography variant="body1">Number of servings</Typography>
        </Box>
      </SingleTimingData>
    </TimingsContainer>
  );
}

const TimingsContainer = styled(Grid)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  marginBottom: 20,
  width: "100%",
});

const SingleTimingData = styled(Grid)({
  padding: "10px",
  borderRadius: "1rem",

  "& .top-content": {
    display: "flex",
    alignItems: "flex-end",
    "& p": {
      fontSize: 14,
      fontWeight: "bold",
      color: "rgba(0,0,0,0.6)",
    },
    "& span": {
      color: "#454545",
      fontWeight: "bold",
      marginTop: 4,
    },
    "& svg": {
      stroke: "#454545",
      height: 24,
      width: 24,
      marginRight: 10,
    },
  },
  "& h6": {
    fontWeight: "bold",
    marginRight: 4,
    color: "#454545",
    marginBottom: -4,
  },
  "& p": {
    fontSize: 12,
    color: "rgba(0,0,0,0.6)",
  },
  "& span": {
    fontSize: 12,
    color: "rgba(0,0,0,0.6)",
  },
});

export default Timings;
