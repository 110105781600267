import React, { useState } from "react";
import { connect } from "react-redux";
import { styled, withTheme } from "@material-ui/core/styles";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Logo from "../Assets/Logo/logo_large_dark_sidetext_alt.png";
import Button from "@material-ui/core/Button";
import BugReportPopup from "./BugReportPopup";
import {
  HomeIcon,
  SearchIcon,
  MessagesIcon,
  StarIcon,
  PlusIcon,
  UserIcon,
  SettingsIcon,
  LogoutIcon,
  MenuIcon,
  CloseIcon,
  BackIcon,
  MenuMoreIcon,
  NewRecipeIcon,
  NewPostIcon,
  NewClipIcon,
  WarningIcon,
  /*   BellIcon,
  JellyBeanIcon,
  StoreIcon, */
} from "../Assets/Icons";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch({ type: "UPDATE_USER_INFO", payload: data }),
  setLoading: (boolean) => dispatch({ type: "SET_LOADING", payload: boolean }),
  setShowBuilderDrawer: (boolean) => dispatch({ type: "SET_SHOW_BUILDER_DRAWER", payload: boolean }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

const NavBar = (props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showCreateButtons, setShowCreateButtons] = useState(false);
  const [dropDownMenuData, setDropdownMenuData] = useState("");
  const [initialOpen, setInitialOpen] = useState(true);
  const [showBugReport, setShowBugReport] = useState(false);

  const links = [
    { text: "/", icon: <HomeIcon /> },
    { text: "/search", icon: <SearchIcon /> },
    {
      text: "",
      icon: showCreateButtons ? <PlusIcon rotate /> : <PlusIcon />,
      func: () => setShowCreateButtons(!showCreateButtons),
    },
    { text: "/notifications", icon: <MessagesIcon /> },
    { text: "/collection", icon: <StarIcon /> },
  ];

  const dropdownLinks = [
    {
      text: "Create",
      icon: <PlusIcon />,
      func: () => {
        setDropdownMenuData("create");
        setInitialOpen(false);
      },
      subMenu: true,
    },
    {
      text: "Your Profile",
      icon: <UserIcon />,
      to: `/profile/${props.user._id}`,
      func: () => setInitialOpen(true),
      subMenu: false,
    },
    /*     {
      text: "Notifications",
      icon: <BellIcon />,
      to: "/notifications",
      func: () => setInitialOpen(true),
      subMenu: false,
    }, */
    /* { text: "Store", icon: <StoreIcon />, isStore: true }, */
    { text: "Settings", icon: <SettingsIcon />, disabled: true },
    {
      text: "Submit Bug Report",
      icon: <WarningIcon />,
      func: () => {
        setShowBugReport(true);
        setShowDropdown(false);
      },
    },
    { text: "Log Out", icon: <LogoutIcon />, href: `${process.env.REACT_APP_BASE_URL}/users/logout` },
  ];
  const dropdownLinks_create = [
    { text: "Go Back", icon: <BackIcon />, func: () => setDropdownMenuData("") },
    {
      text: "Recipe",
      icon: <NewRecipeIcon />,
      func: () => {
        setShowDropdown(false);
        setShowCreateButtons(false);
        setDropdownMenuData("");
        setInitialOpen(true);
        props.setShowBuilderDrawer(!props.app.showBuilderDrawer);
      },
    },
    { text: "Post", icon: <NewPostIcon />, disabled: true },
    { text: "Clip", icon: <NewClipIcon />, disabled: true },
  ];

  return (
    <NavBarMainContainer
      component={motion.div}
      animate={{ y: [-100, 10, 0], opacity: [0, 1, 1] }}
      transition={{ ease: "easeInOut", duration: 0.75 }}
    >
      <NavMainContent>
        <Left>
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </Left>

        <Middle>
          <ul>
            {links.map((link, index) =>
              link.text ? (
                <li key={index}>
                  <Button label="hidden">
                    <Link as={Button} to={`${link.text}`} className={props.location === link.text ? "active" : ""}>
                      {link.icon}
                    </Link>
                  </Button>
                  {props.location === link.text && (
                    <Link
                      to={`${link.text}`}
                      className="active"
                      style={{ filter: "blur(2px)", marginTop: "-44px", transform: "scale(1.1)", opacity: 0.5 }}
                    >
                      {link.icon}
                    </Link>
                  )}
                </li>
              ) : (
                <li key={index}>
                  <Button className="create-btn" label="hidden" onClick={link.func && (() => link.func())}>
                    {link.icon}
                  </Button>
                </li>
              )
            )}
          </ul>
          <AnimatePresence>
            {showCreateButtons && (
              <CreateButtonsMenu
                component={motion.div}
                animate={{ y: [100, -5, 0], opacity: [0, 0, 1] }}
                transition={{ ease: "easeInOut", duration: 0.25 }}
                exit={{ y: [0, -5, 100], opacity: [1, 0, 0] }}
              >
                {dropdownLinks_create.map(
                  ({ text, icon, func, disabled }, index) =>
                    index !== 0 && (
                      <Button key={index} label="hidden" onClick={func && (() => func())} disabled={disabled}>
                        <Box>{icon}</Box>
                        <Typography variant="body2">{text}</Typography>
                      </Button>
                    )
                )}
              </CreateButtonsMenu>
            )}
          </AnimatePresence>
        </Middle>

        <Right>
          <Link to={`/profile/${props.user._id}`}>
            <UserProfile>
              <NavProfilePicture>
                <img src={props.user.image} alt="profile" />
              </NavProfilePicture>
              <Box fontWeight={500} style={{ marginTop: "-5px" }}>
                {props.user.username.length > 9
                  ? props.user.username.split("").splice(0, 9).join("").toLowerCase() + ".."
                  : props.user.username.toLowerCase()}
              </Box>
            </UserProfile>
          </Link>
          {/*           <JellyBeansContainer>
            <JellyBeanIcon /> 2492
          </JellyBeansContainer> */}

          <DropDownButtonMenu
            open={showDropdown}
            label="hidden"
            onClick={() => {
              setShowDropdown(true);
            }}
          >
            {showDropdown ? <CloseIcon open={showDropdown} /> : <MenuIcon />}
          </DropDownButtonMenu>
        </Right>

        <AnimatePresence>
          {showDropdown && (
            <NavDropdown
              component={motion.div}
              animate={{ y: [-100, 5, 0], opacity: [0, 1, 1] }}
              transition={{ ease: "easeInOut", duration: 0.5 }}
              exit={{ y: [0, 5, -100], opacity: [1, 1, 0] }}
            >
              {dropDownMenuData === "" ? (
                <AnimatePresence>
                  <motion.div
                    animate={!initialOpen && { x: [-100, 5, 0] }}
                    transition={!initialOpen && { ease: "easeInOut", duration: 0.5 }}
                    exit={!initialOpen && { x: [0, 5, -100] }}
                  >
                    <ul>
                      {dropdownLinks.map(({ text, icon, func, isStore, href, to, subMenu, disabled }, index) => (
                        <li key={index}>
                          <Button
                            disabled={disabled}
                            onClick={
                              (func && (() => func())) ||
                              (href &&
                                (() => {
                                  props.setLoading(true);
                                  window.location.replace(href);
                                }))
                            }
                          >
                            {to ? (
                              <Link to={to} onClick={() => setShowDropdown(false)}>
                                <Box display="flex" alignItems="center">
                                  {icon} {text}
                                </Box>
                              </Link>
                            ) : (
                              <Box display="flex" alignItems="center">
                                {icon} {text}
                              </Box>
                            )}

                            {subMenu && <MenuMoreIcon />}
                            {/*          {isStore && (
                              <JellyBeansContainer
                                style={{
                                  padding: "3px 14px",
                                  backgroundColor: "#e7e7e7",
                                  backgroundImage: "none",
                                  color: "#454545",
                                  margin: 0,
                                  fontWeight: "bold",
                                }}
                              >
                                <JellyBeanIcon /> 2492
                              </JellyBeansContainer>
                            )} */}
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </motion.div>
                </AnimatePresence>
              ) : (
                <AnimatePresence>
                  <motion.div
                    animate={{ x: [100, 5, 0] }}
                    transition={{ ease: "easeInOut", duration: 0.5 }}
                    exit={{ x: [0, 5, 100] }}
                  >
                    <ul>
                      {dropdownLinks_create.map(({ text, icon, func, disabled }, index) => (
                        <li key={index}>
                          <Button onClick={func && (() => func())} disabled={disabled ? true : false}>
                            {disabled ? (
                              <Box display="flex" alignItems="center">
                                {icon} Coming Soon
                              </Box>
                            ) : (
                              <Box display="flex" alignItems="center">
                                {icon} {text}
                              </Box>
                            )}
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </motion.div>
                </AnimatePresence>
              )}
            </NavDropdown>
          )}
        </AnimatePresence>

        {(showDropdown || showCreateButtons) && (
          <FullPageWrap
            onClick={() => {
              setShowDropdown(false);
              setShowCreateButtons(false);
              setDropdownMenuData("");
              setInitialOpen(true);
            }}
          />
        )}
        <BugReportPopup show={showBugReport} setShowBugReport={setShowBugReport} />
      </NavMainContent>
    </NavBarMainContainer>
  );
};

const NavBarMainContainer = styled(withTheme(Box))((props) => ({
  width: "100%",
  display: "flex",
  position: "relative",
}));

const NavProfilePicture = styled(Box)({
  borderRadius: "100%",
  height: 28,
  width: 28,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  marginRight: 10,
  "& img": {
    height: 28,
    width: 28,
    borderRadius: "100%",
    position: "relative",
    zIndex: 1,
  },
});

const NavMainContent = styled(withTheme(Box))((props) => ({
  border: "1px solid rgba(0,0,0,0.05)",
  backgroundColor: "#fcfcfc",
  borderRadius: "1rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "relative",
  width: "100%",
  padding: "10px 30px",
  margin: "10px 20px",
  boxShadow: "0 3px 4px -1px rgba(0,0,0,0.05)",
  borderBottom: "1px solid rgba(0,0,0,0.125)",
  maxWidth: 1760,
  [props.theme.breakpoints.up("xl")]: {
    margin: "10px auto",
  },

  "& ul": {
    display: "flex",
    alignItems: "center",
    listStyle: "none",
    padding: 0,
    margin: 0,
    "& li": {
      margin: "0 4px",
      "&:nth-child(3)": {
        "& button.create-btn": {
          backgroundColor: "#5805e9",
          backgroundImage: "linear-gradient(90deg, #5805e9 0%, #8b20eb 100%)",
          padding: "10px",
          borderRadius: "100%",
          margin: "0 20px",
          boxShadow: "0 2px 3px -1px rgba(0,0,0,0.5)",

          "& svg": {
            stroke: "#fcfcfc",
            height: 24,
            width: 24,
            position: "relative",
            zIndex: 10,
            transition: "transform 0.25s ease",
          },
        },
      },
      "& a": {
        padding: "10px 25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "0.75rem",
        transition: "background-color 0.15s ease",
        position: "relative",

        "& svg": {
          stroke: "#454545",
        },

        "&.active": {
          backgroundColor: "rgba(255,255,255,0.15)",
          "& svg": {
            stroke: "#5a06e9",
          },
        },
      },
    },
  },
  "& svg": {
    height: 24,
    width: 24,
  },
}));

const UserProfile = styled(withTheme(Box))((props) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "1rem",
  padding: "4px 10px",
  cursor: "pointer",
  transition: "background-color 0.25s ease",
  marginRight: 10,
  "&:hover": {
    backgroundColor: "#e7e7e7",
  },
}));

const NavDropdown = styled(withTheme(Box))((props) => ({
  position: "absolute",
  right: 0,
  top: 70,
  backgroundColor: "#fcfcfc",
  width: "300px",
  borderRadius: "1rem",
  zIndex: 12,
  boxShadow: "0 3px 10px -1px rgba(0,0,0,0.125)",
  border: "1px solid rgba(0,0,0,0.125)",
  opacity: 0,
  overflow: "hidden",
  "& ul": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    margin: 0,
    padding: 0,

    "& li": {
      width: "100%",
      margin: 0,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",

      "& button": {
        backgroundColor: "transparent",
        backgroundImage: "none",
        borderRadius: 0,
        width: "100%",
        padding: "10px 10px 10px 20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid rgba(0,0,0,0.075)",
        textTransform: "none",
        "&:disabled": {
          color: "#454545",
        },
        "& a": {
          width: "100%",
          color: "#454545",
          padding: 0,
          "& div": {
            width: "100%",
          },
        },
        "& svg": {
          stroke: "#454545",
          width: 32,
          height: 32,
          backgroundColor: "#e7e7e7",
          borderRadius: "100%",
          padding: 8,
          marginRight: 10,
        },
      },
      "&:first-child": {
        borderRadius: "1rem 1rem 0rem 0rem",
        "& button": {
          borderRadius: "1rem 1rem 0rem 0rem",
        },
      },
      "&:last-child": {
        borderRadius: "1rem 1rem 0rem 0rem",

        "& button": {
          borderRadius: "0rem 0rem 1rem 1rem",
          borderBottom: "transparent",
        },
      },
    },
  },
}));

const FullPageWrap = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "transparent",
  zIndex: 10,
});

const Left = styled(Box)({
  display: "flex",
  alignItems: "center",
  maxWidth: 450,
  /*  minWidth: 300, */
  "& a": {
    display: "flex",
    alignItems: "center",
    "& img": {
      height: "40px",
    },
  },
});

const Middle = styled(Box)({
  "& button": {
    minWidth: 0,
    backgroundColor: "transparent",
    backgroundImage: "none",
    borderRadius: "1rem",
    padding: 0,
    margin: 0,
    "&:hover": {
      backgroundColor: "#e7e7e7",
    },
  },
});

const Right = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  maxWidth: 450,
  /*   minWidth: 300, */
  "& a": {
    color: "#454545",
  },
  "& button": {
    backgroundColor: "#e7e7e7",
    backgroundImage: "none",
    maxWidth: 36,
    maxHeight: 36,
    minWidth: 0,
    transition: "transform 0.15s ease",
    "&:hover": {
      backgroundColor: "#e7e7e7",
      transform: "scale(1.15)",
    },
  },
});

const DropDownButtonMenu = styled(Button)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

/* const JellyBeansContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  margin: "0 6px",
  backgroundImage: "linear-gradient(90deg, rgba(88,5,233, 0.8) 0%, rgba(139, 32, 235, 0.7) 100%)",
  color: "#fcfcfc",
  padding: "10px 20px",
  borderRadius: "2rem",
  transform: "scale(0.8)",
  fontWeight: 500,
  cursor: "pointer",
  transition: "background-color 0.25s ease",
  "& svg": {
    marginRight: 6,
  },
  "&:hover": {
    backgroundColor: "rgba(125, 83, 222, 0.5)",
  },
}); */

const CreateButtonsMenu = styled(Box)({
  position: "fixed",
  display: "flex",
  justifyContent: "center",
  top: 90,
  left: 0,
  right: 0,
  zIndex: 999,
  opacity: 0,
  "& button": {
    backgroundColor: "#fcfcfc",
    height: 80,
    width: 80,
    borderRadius: "100%",
    margin: "0 20px",
    padding: 14,
    boxShadow: "0px 3px 10px -1px rgba(0, 0, 0, 0.25)",
    "&:disabled": {
      backgroundColor: "#e7e7e7",
      backgroundImage: "none",
      opacity: 1,
      boxShadow: "0px 3px 10px -1px rgba(0, 0, 0, 0.25)",
      "& svg": {
        stroke: "#a59f9f",
      },
      "& span": {
        "& p": {
          color: "#a59f9f",
        },
      },
    },
    "&:hover": {
      backgroundColor: "#e7e7e7",
      boxShadow: "0px 3px 10px -1px rgba(0, 0, 0, 0.25)",
    },
    "& span:first-child": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    "& p": {
      fontWeight: "bold",
    },
  },
  "& svg": {
    stroke: "#454545",
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
