import React, { useState } from "react";
import { connect } from "react-redux";
import { styled, withTheme } from "@material-ui/core/styles";
import { AnimatePresence, motion } from "framer-motion";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CloseIcon, CorrectIcon } from "../Assets/Icons";
import { newBugReportHandler } from "../Utils";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch({ type: "UPDATE_USER_INFO", payload: data }),
  setLoading: (boolean) => dispatch({ type: "SET_LOADING", payload: boolean }),
  setShowBuilderDrawer: (boolean) => dispatch({ type: "SET_SHOW_BUILDER_DRAWER", payload: boolean }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

function BugReportPopup(props) {
  const [inputText, setInputText] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const submitBugReport = async () => {
    if (!loading) {
      if (inputText.length > 0 && !inputText.startsWith(" ")) {
        setLoading(true);
        const newReport = {
          userID: props.user._id,
          username: props.user.username,
          text: inputText,
        };
        const reportResponse = await newBugReportHandler(newReport);
        if (!reportResponse.errors) {
          setTimeout(() => {
            setLoading(false);
            setShowSuccess(true);
          }, 1500);
        }
      }
    }
  };

  return (
    <>
      <AnimatePresence>
        {props.show && (
          <BugReportWrap
            component={motion.div}
            animate={{ opacity: [0, 1] }}
            transition={{ ease: "easeInOut", duration: 0.5 }}
            exit={{ opacity: [1, 0] }}
          >
            <BugReportContainer
              component={motion.div}
              animate={{ y: [-300, 10, 0], opacity: [0, 1, 1] }}
              transition={{ ease: "easeInOut", duration: 0.5 }}
              exit={{ y: [0, 10, -300], opacity: [1, 1, 0] }}
              className={showSuccess ? "success" : ""}
            >
              {!showSuccess ? (
                <>
                  <Box>
                    <TitleBarContainer>
                      <Typography variant="h4">Submit a bug report</Typography>
                      <Button
                        onClick={() => {
                          props.setShowBugReport(false);
                          setInputText("");
                        }}
                      >
                        <CloseIcon />
                      </Button>
                    </TitleBarContainer>
                    <BaseInput
                      name="search"
                      color="secondary"
                      label="Search"
                      multiline="true"
                      placeholder="Please describe what happened..."
                      onChange={(event) => setInputText(event.target.value)}
                      value={inputText}
                    />
                  </Box>
                  <SubmitButton onClick={submitBugReport} disabled={!inputText.length > 0 || inputText.startsWith(" ")}>
                    {loading ? <CircleLoader /> : "Submit"}
                  </SubmitButton>
                </>
              ) : (
                <SuccessContainer>
                  <CorrectContainer
                    component={motion.div}
                    animate={{ scale: [0, 0, 1], opacity: [0, 0, 1] }}
                    transition={{ ease: "easeInOut", duration: 0.5 }}
                  >
                    <CorrectIcon />
                    <Typography variant="h5">Success!</Typography>
                  </CorrectContainer>
                  <Box
                    component={motion.div}
                    animate={{ y: [20, -10, 0], opacity: [0, 1, 1] }}
                    transition={{ ease: "easeInOut", duration: 0.5 }}
                  >
                    <Typography variant="body1">We have received your report and will look into it.</Typography>
                    <Button
                      onClick={() => {
                        props.setShowBugReport(false);
                        setShowSuccess(false);
                        setInputText("");
                      }}
                    >
                      Close
                    </Button>
                  </Box>
                </SuccessContainer>
              )}
            </BugReportContainer>
          </BugReportWrap>
        )}
      </AnimatePresence>
    </>
  );
}

const BugReportWrap = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,0.1)",
  zIndex: 998,
  padding: 20,
});

const BugReportContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  position: "relative",
  zIndex: 999,
  width: "100%",
  maxWidth: 600,
  minHeight: 300,
  backgroundColor: "rgba(255,255,255,0.75)",
  backdropFilter: "blur(4px)",
  borderRadius: "2rem",
  padding: 20,
  boxShadow: "0 3px 4px -1px rgba(0,0,0,0.05)",
  borderBottom: "1px solid rgba(0,0,0,0.125)",
  "&.success": {
    justifyContent: "center !important",
    alignItems: "center !important",
  },
});

const TitleBarContainer = styled(withTheme(Box))((props) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "10px 10px 0px 10px",
  "& Button": {
    backgroundColor: "rgba(0,0,0,0.05)",
    backgroundImage: "none",
    borderRadius: "100%",
    minWidth: 0,
    height: 60,
    width: 60,
    [props.theme.breakpoints.down("sm")]: {
      width: 50,
      height: 50,
    },
    "&:hover": {
      backgroundColor: "#e7e7e7",
    },
    "& svg": {
      width: 40,
      height: 40,
      stroke: "#454545",
    },
  },
  "& h4": {
    [props.theme.breakpoints.down("sm")]: {
      fontSize: 28,
    },
    fontWeight: 700,
  },
}));

const BaseInput = styled(InputBase)({
  borderRadius: "1rem",
  padding: 10,
  fontSize: 24,
  width: "100%",
});

const SubmitButton = styled(Button)({
  width: 200,
  padding: "10px",
  color: "#fcfcfc",
  margin: "0px 0px 0px auto",
});

const CircleLoader = styled(CircularProgress)({
  height: "25px !important",
  width: "25px !important",
});

const SuccessContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
  "& svg": {
    height: 50,
    width: 50,
    stroke: "#fcfcfc",
    marginBottom: -10,
  },
  "& h5": {
    fontWeight: 500,
    color: "#fcfcfc",
  },
  "& p": {
    color: "#454545",
    fontWeight: 500,
    marginBottom: 20,
  },
  "& button": {
    padding: "10px 50px",
    backgroundImage: "none",
    backgroundColor: "rgba(255,255,255,0.75)",
  },
});

const CorrectContainer = styled(Box)({
  backgroundColor: "rgba(138, 201, 38, 0.65)",
  boxShadow: "0 3px 4px -1px rgba(0,0,0,0.125)",
  padding: 0,
  borderRadius: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  height: 140,
  width: 140,
  marginBottom: 10,
});

export default connect(mapStateToProps, mapDispatchToProps)(BugReportPopup);
