import React from "react";
import { styled } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { LogoutIcon } from "../../Assets/Icons";

function LogoutButton() {
  return (
    <Logout
      onClick={() => {
        window.location.replace(`${process.env.REACT_APP_BASE_URL}/users/logout`);
      }}
    >
      <LogoutIcon />
      Log Out
    </Logout>
  );
}

const Logout = styled(Button)({
  padding: 12,
  backgroundColor: "rgba(0,0,0,0.65)",
  boxShadow: "0 3px 3px -1px rgba(0,0,0,0.1)",
  backgroundImage: "none",
  color: "#fcfcfc",
  minWidth: 200,
  marginTop: 20,
  "&:hover": {
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  "& svg": {
    stroke: "#fcfcfc",
    height: 24,
    width: 24,
    marginRight: 16,
  },
});

export default LogoutButton;
