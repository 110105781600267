export default function a(state = {}, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "SET_LOGGED_IN_STATUS":
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case "SET_SHOW_BUILDER_DRAWER":
      return {
        ...state,
        showBuilderDrawer: action.payload,
      };
    case "SET_FOLLOWING_FEED":
      return {
        ...state,
        followingFeed: action.payload,
      };
    case "SET_DISCOVER_FEED":
      return {
        ...state,
        discoverFeed: action.payload,
      };
    case "SET_LOGGED_IN_USER_POSTS":
      return {
        ...state,
        loggedInUserPosts: action.payload,
      };
    case "SET_USER_POSTS":
      return {
        ...state,
        userPosts: action.payload,
      };
    case "SET_USER_COLLECTION":
      return {
        ...state,
        userCollection: action.payload,
      };
    case "SET_USER_DATA":
      return {
        ...state,
        userData: action.payload,
      };
    case "SET_SUGGESTED_USERS":
      return {
        ...state,
        suggestedUsers: action.payload,
      };
    case "SET_SEARCH_RESULTS":
      return {
        ...state,
        searchResults: action.payload,
      };
    default:
      return state;
  }
}
