import React from "react";
import { styled } from "@material-ui/core/styles";
import { motion } from "framer-motion";
import { CloseIcon } from "../../Assets/Icons";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";

function SingleIngredientBlock({ index, data, deleteBlock, updateData }) {
  return (
    <SingleIngredient
      component={motion.div}
      animate={{ y: [-100, 0], opacity: [0, 0, 1] }}
      transition={{ duration: 0.25 }}
      exit={{ y: [0, -100], opacity: [1, 0, 0] }}
    >
      <Grid container spacing={2} className="topbar">
        <Grid item xs={12} md={6}>
          <Input
            variant="outlined"
            name="name"
            color="secondary"
            label="Ingredient Name"
            inputProps={{ minLength: 2 }}
            value={data.name}
            onChange={(event) => updateData(event, index)}
            required
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Input
            variant="outlined"
            name="quantity"
            color="secondary"
            label="Ingredient Quantity"
            type="number"
            value={data.quantity}
            onChange={(event) => updateData(event, index)}
            required
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">Units</InputLabel>
            <SelectInput
              label="Units"
              name="units"
              color="secondary"
              value={data.units}
              onChange={(event) => updateData(event, index)}
              required
            >
              <MenuItem value="None">None</MenuItem>
              <MenuItem value="gram(s)">gram(s)</MenuItem>
              <MenuItem value="kilogram(s)">kilogram(s)</MenuItem>
              <MenuItem value="milliliters(s)">millilitre(s)</MenuItem>
              <MenuItem value="liters(s)">litre(s)</MenuItem>
              <MenuItem value="ounce(s)">ounce(s)</MenuItem>
              <MenuItem value="teaspoon(s)">teaspoon(s)</MenuItem>
              <MenuItem value="tablespoon(s)">tablespoon(s)</MenuItem>
              <MenuItem value="cup(s)">cup(s)</MenuItem>
              <MenuItem value="pint(s)">pint(s)</MenuItem>
              <MenuItem value="gallon(s)">gallon(s)</MenuItem>
              <MenuItem value="drop(s)">drop(s)</MenuItem>
              <MenuItem value="pinch(es)">pinch(es)</MenuItem>
            </SelectInput>
          </FormControl>
        </Grid>
      </Grid>
      <Box className="bottombar">
        <Input
          variant="outlined"
          name="link"
          color="secondary"
          label="Where to buy? (Url)"
          value={data.link}
          onChange={(event) => updateData(event, index)}
        />
      </Box>
      <Button onClick={() => deleteBlock(index)}>
        <CloseIcon />
      </Button>
    </SingleIngredient>
  );
}

const SingleIngredient = styled(Box)({
  display: "flex",
  flexDirection: "column",
  padding: 14,
  marginBottom: 30,
  position: "relative",
  borderRadius: "1rem",
  backgroundColor: "rgba(0,0,0,0.1)",
  boxShadow: "0 3px 3px -1px rgba(0,0,0,0.1)",
  width: "100%",
  "& .topbar": {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  "& button": {
    position: "absolute",
    right: -12,
    top: -12,
    margin: "auto",
    backgroundImage: "none",
    backgroundColor: "#d72638",
    color: "#fcfcfc",
    minWidth: 0,
    fontSize: 12,
    padding: 6,
    /*     padding: "4px 14px", */
    "&:hover": {
      backgroundColor: "#be192a",
    },
    "& svg": {
      height: 18,
      width: 18,
      stroke: "#fcfcfc",
    },
  },
});

const Input = styled(TextField)({
  width: "100%",
  minWidth: 180,
  color: "#735cdd",
  backgroundColor: "#fcfcfc",
  fontWeight: 24,
  borderRadius: "0.5rem",
  boxShadow: "0 3px 3px -1px rgba(0,0,0,0.1)",
  "& input": {
    fontSize: 24,
  },
  "& fieldset": {
    border: "none",
    boxShadow: "none",
    "& legend": {
      "& span": {
        backgroundColor: "red",
        fontSize: 24,
      },
    },
  },
});

const SelectInput = styled(Select)({
  width: "100%",
  minWidth: 180,
  color: "#735cdd",
  fontWeight: 24,
  boxShadow: "0 3px 3px -1px rgba(0,0,0,0.1)",
  "& input": {
    fontSize: 24,
  },
  "& fieldset": {
    border: "none",
    boxShadow: "none",
    "& legend": {
      "& span": {
        fontSize: 24,
      },
    },
  },
});

export default SingleIngredientBlock;
