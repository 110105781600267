import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { styled, withTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EmailIcon from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
import Logo from "../../Assets/Logo/logo_large_purple_sidetext_alt.png";
import LoginLottie from "./LoginLottie";
import InputAdornment from "@material-ui/core/InputAdornment";
import SuccessAlert from "../Alerts/SuccessAlert";
import ErrorAlert from "../Alerts/ErrorAlert";
import SpinnerLoader from "../Loaders/SpinnerLoader";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch({ type: "UPDATE_USER_INFO", payload: data }),
  setLoading: (boolean) => dispatch({ type: "SET_LOADING", payload: boolean }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

const Register = (props) => {
  const [registrationInput, setRegistrationInput] = useState({
    name: "",
    surname: "",
    email: "",
    username: "",
    password: "",
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [showPasswordMismatch, setShowPasswordMismatch] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const registrationInputHandler = (event) => {
    setRegistrationInput({
      ...registrationInput,
      [event.target.name]: event.target.value,
    });
  };

  const registrationHandler = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/users/register`, {
        name: registrationInput.name,
        surname: registrationInput.surname,
        email: registrationInput.email,
        username: registrationInput.username,
        password: registrationInput.password,
      });
      const data = response.data;
      if (data.errors) {
        setTimeout(() => {
          setLoading(false);
          const errorValues = Object.values(data.errors);
          errorValues.forEach((value) => {
            value.msg = value.message;
            delete value.message;
          });
          props.setError(errorValues);
          props.showErrors(true);
        }, 2000);
      } else {
        props.showErrors(false);
        props.setError([]);

        setShowSuccess(true);
        setTimeout(() => {
          setLoading(false);
          setShowSuccess(false);
        }, 1500);
        setTimeout(() => {
          props.setLoading(true);
          props.history.push("/login");
        }, 1500);
        setTimeout(() => {
          props.setLoading(false);
        }, 2800);
        setRegistrationInput({
          name: "",
          surname: "",
          email: "",
          username: "",
          password: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (registrationInput.password !== "" && confirmPassword !== "") {
      if (registrationInput.password !== confirmPassword) {
        setShowPasswordMismatch(true);
      } else {
        setShowPasswordMismatch(false);
      }
    }
  }, [registrationInput.password, confirmPassword]);

  useEffect(() => {
    if (
      registrationInput.name.length > 2 &&
      registrationInput.surname.length > 2 &&
      registrationInput.email.length > 5 &&
      registrationInput.username.length > 3 &&
      registrationInput.password.length > 4 &&
      registrationInput.password !== "" &&
      registrationInput.password === confirmPassword
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [registrationInput, confirmPassword]);

  useEffect(() => {
    props.showErrors(false);
    props.setError([]);
  }, []);

  return (
    <RegisterFullWrap>
      <RegisterMainContainer display="flex" alignItems="center">
        <Grid container spacing={0} alignItems="center" justify="center">
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" className="height-scroll-lg">
              <Link to="/">
                <img src={Logo} alt="logo" />
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} lg={7} xl={5}>
            <RegisterPanel
              component={motion.div}
              animate={{ y: [100, -10, 0], opacity: [0, 1, 1] }}
              transition={{ ease: "easeInOut", duration: 0.75 }}
            >
              <Grid container spacing={0}>
                <Grid item xs={12} md={6}>
                  <LoginLottie />
                </Grid>
                <Grid item xs={12} md={6} className="heightController">
                  <Right display="flex" flexDirection="column">
                    <Box fontWeight="fontWeightBold" fontSize={18}>
                      Create an account
                    </Box>
                    <Box color="secondary" fontWeight="fontWeightLight" fontSize={20} mb={3}>
                      Enter your details below.
                    </Box>
                    <ErrorAlert
                      text={props.errors.errors.length > 0 && props.errors.errors[0].msg}
                      show={props.errors.show}
                    />
                    <ErrorAlert text="Passwords do not match." show={!props.errors.show && showPasswordMismatch} />
                    <SuccessAlert text="Success, Redirecting to Login..." show={showSuccess} />
                    {loading ? (
                      <Box display="flex" alignItems="center" justifyContent="center" mt={3}>
                        <SpinnerLoader />
                      </Box>
                    ) : (
                      <>
                        <form onSubmit={registrationHandler}>
                          <Input
                            id="input-with-icon-adornment"
                            name="name"
                            color="secondary"
                            label="First name"
                            onChange={registrationInputHandler}
                            value={registrationInput.name}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AccountCircleIcon style={{ opacity: 0.55, color: "#735cdd" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Input
                            id="input-with-icon-adornment"
                            name="surname"
                            color="secondary"
                            label="Surname"
                            onChange={registrationInputHandler}
                            value={registrationInput.surname}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AccountCircleIcon style={{ opacity: 0.55, color: "#735cdd" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Input
                            id="input-with-icon-adornment"
                            name="email"
                            color="secondary"
                            label="Email"
                            type="email"
                            onChange={registrationInputHandler}
                            value={registrationInput.email}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <EmailIcon style={{ opacity: 0.55, color: "#735cdd" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Input
                            id="input-with-icon-adornment"
                            name="username"
                            color="secondary"
                            label="Username (Display Name)"
                            onChange={registrationInputHandler}
                            value={registrationInput.username}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AccountCircleIcon style={{ opacity: 0.55, color: "#735cdd" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Input
                            name="password"
                            color="secondary"
                            label="Password"
                            type="password"
                            onChange={registrationInputHandler}
                            value={registrationInput.password}
                            autoComplete="on"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LockIcon style={{ opacity: 0.55, color: "#735cdd" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Input
                            name="confirmPassword"
                            color="secondary"
                            label="Confirm Password"
                            type="password"
                            onChange={(event) => setConfirmPassword(event.target.value)}
                            value={confirmPassword}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LockIcon style={{ opacity: 0.55, color: "#735cdd" }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <RegisterButton type="submit" color="primary" disabled={disabled}>
                            Create Account
                          </RegisterButton>
                        </form>
                        <Box mt={2}>
                          <Typography variant="body2" align="center">
                            Already have an account? <Link to="/login">Log In</Link>
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Right>
                </Grid>
              </Grid>
            </RegisterPanel>
          </Grid>
        </Grid>
      </RegisterMainContainer>
    </RegisterFullWrap>
  );
};

const RegisterMainContainer = styled(withTheme(Box))((props) => ({
  height: "100vh",
  width: "100%",
  "& a": {
    "& img": {
      width: "100%",
      maxWidth: 240,
      minWidth: 150,
      opacity: 0.75,
      display: "block",
      [props.theme.breakpoints.down("sm")]: {
        marginTop: 30,
      },
    },
  },
}));

const RegisterFullWrap = styled(Box)({
  overflow: "auto",
  "&::-webkit-scrollbar": {
    width: 6,
    height: 6,
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,0.25)",
    borderRadius: "2rem",
  },
});

const RegisterPanel = styled(withTheme(Box))((props) => ({
  borderRadius: "2rem",
  color: "#0f0423",
  position: "relative",
  zIndex: 999,
  boxShadow: "0 3px 4px -1px rgba(0,0,0,0.05)",
  borderBottom: "1px solid rgba(0,0,0,0.125)",
  margin: "30px auto 40px",
  backgroundColor: "#fcfcfc",
  maxWidth: 815,
  [props.theme.breakpoints.down("xs")]: {
    borderRadius: 0,
  },
  "& form": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    "& ~ div": {
      marginTop: 20,
    },
  },
  "& a": {
    color: "#735cdd",
    fontWeight: "bold",
  },
  "& .heightController": {
    height: "100%",
  },
}));

const Right = styled(withTheme(Box))((props) => ({
  margin: "2rem",
  padding: "30px",
  [props.theme.breakpoints.down("sm")]: {
    padding: "50px 30px",
  },
  height: "100%",
  position: "relative",
  zIndex: 999,
  display: "flex",
  justifyContent: "center",
  "& button": {
    marginTop: 10,
  },
}));

const Input = styled(TextField)({
  width: "100%",
  minWidth: 200,
  marginBottom: 20,
  color: "#735cdd !important",
});

const RegisterButton = styled(Button)({
  width: "100%",
  minWidth: 200,
  padding: "10px",
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
