import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { styled, withTheme } from "@material-ui/core/styles";
import { AnimatePresence, motion } from "framer-motion";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TitleBar from "./RecipePopup/TitleBar";
import UserInfo from "./RecipePopup/UserInfo";
import RecipeName from "./RecipePopup/RecipeName";
import Tags from "./RecipePopup/Tags";
import Ingredients from "./RecipePopup/Ingredients";
import PreparationInstructions from "./RecipePopup/PreparationInstructions";
import Instructions from "./RecipePopup/Instructions";
import InteractionButtonBar from "./RecipePopup/InteractionButtonBar";
import Timings from "./RecipePopup/Timings";
import { getCommentsHandler } from "../Utils";
import PopupEditDrawer from "./PopupEditDrawer";
import PostedAt from "./RecipePopup/PostedAt";
import Comments from "./RecipePopup/Comments";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setFollowingFeed: (data) => dispatch({ type: "SET_FOLLOWING_FEED", payload: data }),
  setDiscoverFeed: (data) => dispatch({ type: "SET_DISCOVER_FEED", payload: data }),
  setProfileUserPosts: (data) => dispatch({ type: "SET_USER_POSTS", payload: data }),
  setProfileUserCollection: (data) => dispatch({ type: "SET_USER_COLLECTION", payload: data }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

const PopupSinglePost = (props) => {
  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const [comments, setComments] = useState(props.data.comments);

  const getComments = async () => {
    setComments(await getCommentsHandler(props.data._id));
  };

  useEffect(() => {
    if (!props.showDetails) getComments();
  }, [props.showDetails]);

  return (
    <>
      <AnimatePresence>
        {props.showDetails && (
          <PopupPostContainer
            component={motion.div}
            animate={{ y: [2000, -5, 0], opacity: [0, 1, 1] }}
            transition={{ ease: "easeInOut", duration: 0.5 }}
            exit={{ y: [0, -5, 2000], opacity: [1, 1, 0] }}
          >
            <PopupGridContainer container spacing={3}>
              <RecipeDetailsGridItem item xs={12}>
                <TitleBar showDetailsHandler={props.showDetailsHandler} />
              </RecipeDetailsGridItem>
              {props.data.images && props.data.images.length > 0 && (
                <RecipeDetailsGridItem item xs={12} md={5}>
                  <MainImageContainer>
                    <img src={props.data.images[0]} alt="recipe-main" />
                  </MainImageContainer>
                </RecipeDetailsGridItem>
              )}

              <RecipeDetailsGridItem item xs={12} md={7}>
                <UserInfo data={props.data} />
                <RecipeName text={props.data.name} />
                <PostedAt date={props.data.createdAt} />
                <Tags tags={props.data.tags} />
                <Timings
                  activeTime={props.data.activeTime}
                  totalTime={props.data.totalTime}
                  servings={props.data.yield.value}
                />
                <Ingredients ingredients={props.data.ingredients} />
              </RecipeDetailsGridItem>
              <RecipeDetailsGridItem item xs={12}>
                <PreparationInstructions text={props.data.preparation} />
                <Instructions instructions={props.data.instructions} />
              </RecipeDetailsGridItem>
              <RecipeDetailsGridItem item xs={12}>
                <Comments
                  recipeID={props.data._id}
                  data={comments}
                  setComments={setComments}
                  goToComments={props.goToComments}
                />
              </RecipeDetailsGridItem>
              <InteractionButtonBar
                recipeID={props.data._id}
                isAuthor={props.data.userID === props.user._id}
                likeRecipe={props.likeRecipe}
                postLikes={props.postLikes}
                liked={props.liked}
                inCollection={props.inCollection}
                toggleCollection={props.toggleCollection}
                comments={comments.length}
                showDetailsHandler={props.showDetailsHandler}
                showEditDrawer={setShowEditDrawer}
              />
            </PopupGridContainer>
          </PopupPostContainer>
        )}
      </AnimatePresence>
      <AnimatePresence>
        <PopupEditDrawer data={props.data} show={showEditDrawer} showEditDrawer={setShowEditDrawer} />
      </AnimatePresence>
      <AnimatePresence>
        {props.showDetails && (
          <FullPageWrap
            component={motion.div}
            animate={{ opacity: [0, 1] }}
            transition={{ ease: "easeInOut", duration: 0.5 }}
            exit={{ opacity: [1, 0] }}
            onClick={props.showDetailsHandler}
          />
        )}
      </AnimatePresence>
    </>
  );
};

const PopupGridContainer = styled(Grid)({
  maxHeight: "100%",
  overflowY: "auto",
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    width: 6,
    height: 6,
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,0)",
    borderRadius: "2rem",
  },
});

const FullPageWrap = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,0.25)",
  zIndex: 998,
  webkitTransform: "translate3d(0,0,0)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 20,
  opacity: 0,
});

const PopupPostContainer = styled(withTheme(Box))((props) => ({
  width: "100%",
  height: "92%",
  borderRadius: "2rem 2rem 0 0",
  maxWidth: 1000,
  padding: "30px 20px 0px 30px",
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 999,
  webkitTransform: "translate3d(0,0,0)",
  margin: "0 auto",
  opacity: 0,
  backgroundColor: "rgba(255,255,255,0.75)",
  backdropFilter: "blur(4px)",
  overflow: "hidden",
  [props.theme.breakpoints.up("xl")]: {
    maxWidth: 1200,
  },
}));

const MainImageContainer = styled(withTheme(Box))((props) => ({
  display: "flex",
  width: "100%",
  backgroundColor: "#e7e7e7",
  borderRadius: "1rem",
  position: "relative",
  overflow: "hidden",
  minHeight: 550,
  maxHeight: 650,
  [props.theme.breakpoints.down("sm")]: {
    maxHeight: 350,
    minHeight: 350,
  },
  "& img": {
    objectFit: "cover",
    width: "100%",
  },
  "& p": {
    fontWeight: 500,
    marginTop: 4,
  },
}));

const RecipeDetailsGridItem = styled(Grid)({
  "& h4": {
    marginBottom: 10,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PopupSinglePost);
