import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { styled, withTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import UserProfile from "../Components/Home/UserProfile";
import SuggestedUsers from "../Components/Home/SuggestedUsers";
import BannerAd from "../Components/Home/BannerAd";
import Feed from "../Components/Feed";
import CollectionController from "../Components/Collection/CollectionController";
import { getPosts_collection } from "../Utils";
import FeedLoader from "../Components/Loaders/FeedLoader";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setUserCollection: (data) => dispatch({ type: "SET_USER_COLLECTION", payload: data }),
  setLoading: (boolean) => dispatch({ type: "SET_LOADING", payload: boolean }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

const Collection = (props) => {
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);

  const updateFilter = (selectedFilters) => {
    const filteredArray = props.app.userCollection.filter((recipe) => {
      if (selectedFilters.findIndex((filter) => filter === recipe.cuisine) !== -1) return true;
      if (recipe.tags.findIndex((tag) => selectedFilters.some((filter) => filter === tag)) !== -1) return true;
      return false;
    });
    selectedFilters.length === 0 ? setFilteredData(props.user.recipeCollection) : setFilteredData(filteredArray);
  };

  useEffect(() => {
    const start = async () => {
      await props.setUserCollection(await getPosts_collection(props.user._id));
      setLoading(false);
    };
    start();
  }, []);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [props.app.userCollection]);

  return (
    <HomeMainContainer>
      <Grid container spacing={0} style={{ maxWidth: 1800 }}>
        <LeftGrid item sm={5} md={4} lg={3}>
          <Left>
            <UserProfile />
            <SuggestedUsers />
            <BannerAd />
          </Left>
        </LeftGrid>
        <Grid item sm={12} md={8} lg={9}>
          <Right>
            <CollectionController updateFilter={updateFilter} />
            {loading ? (
              <FeedLoader numberOfElements={props.app.userCollection.length} />
            ) : filteredData.length === 0 ? (
              <Feed data={props.app.userCollection} type="collection" setCollectionLoading={setLoading} />
            ) : (
              <Feed data={filteredData} type="collection" />
            )}
          </Right>
        </Grid>
      </Grid>
    </HomeMainContainer>
  );
};

const HomeMainContainer = styled(withTheme(Box))((props) => ({
  width: "100%",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  paddingTop: 10,
  [props.theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    paddingTop: 86,
  },
}));

const Left = styled(withTheme(Box))((props) => ({
  height: "92vh",
  minHeight: 900,
  margin: "0 10px 0 20px",
  padding: "30px 20px 20px 20px",
  borderRight: "1px solid rgba(0,0,0,0.05)",
  backgroundColor: "#fcfcfc",
  borderRadius: "1rem",
  display: "flex",
  alignItems: "flex-end",
  flexDirection: "column",
  maxWidth: 380,
  transition: "width 0.25s ease",
  [props.theme.breakpoints.down("md")]: {
    maxWidth: 340,
  },
  [props.theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const Right = styled(withTheme(Box))((props) => ({
  display: "flex",
  flexDirection: "column",
  height: "96vh",
  margin: "0 20px 0 10px",
  padding: "0 0 20px 20px",
  backgroundColor: "#e7e7e7",
  maxWidth: 1400,
  overflowY: "auto",
  overflowX: "hidden",
  scrollbarWidth: "none",
  position: "relative",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  [props.theme.breakpoints.down("sm")]: {
    margin: "0 10px 10px",
    padding: "0 10px 10px",
  },
}));

const LeftGrid = styled(withTheme(Grid))((props) => ({
  [props.theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export default connect(mapStateToProps, mapDispatchToProps)(Collection);
