import React from "react";
import { styled } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";

function SearchLoaderUsers({ numberOfElements }) {
  return (
    <>
      <Skeleton
        variant="text"
        width={200}
        height={80}
        style={{ marginTop: -28, marginBottom: 10, borderRadius: "1rem" }}
      />
      <Grid container spacing={3}>
        {numberOfElements !== 0
          ? [...Array(numberOfElements)].map((box, index) => (
              <LoaderGridItem item key={index} xs={12} sm={6} md={4} lg={3}>
                <Skeleton variant="rect" animation="wave" />
              </LoaderGridItem>
            ))
          : [...Array(9)].map((box, index) => (
              <LoaderGridItem item key={index} xs={12} sm={6} md={4} lg={3}>
                <Skeleton variant="rect" animation="wave" />
              </LoaderGridItem>
            ))}
      </Grid>
    </>
  );
}

const LoaderGridItem = styled(Grid)({
  height: 110,
});

export default SearchLoaderUsers;
