import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { styled, withTheme } from "@material-ui/core/styles";
import Feed from "../Feed";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { getPosts_user, getPosts_collection } from "../../Utils";
import NoPostsMessage from "../Home/NoPostsMessage";
import FeedLoader from "../Loaders/FeedLoader";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setFollowingFeed: (data) => dispatch({ type: "SET_FOLLOWING_FEED", payload: data }),
  setDiscoverFeed: (data) => dispatch({ type: "SET_DISCOVER_FEED", payload: data }),
  setUserPosts: (data) => dispatch({ type: "SET_USER_POSTS", payload: data }),
  setUserCollection: (data) => dispatch({ type: "SET_USER_COLLECTION", payload: data }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

function ProfileFeeds(props) {
  const [currentTab, setCurrentTab] = useState("Posts");
  const [initialLoad, setInitialLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const { userPosts, userCollection, userData } = props.app;

  useEffect(() => {
    setLoading(true);
    const start = async () => {
      setLoading(true);
      if (currentTab === "Posts" && !initialLoad) await props.setUserPosts(await getPosts_user(userData._id));
      if (currentTab === "Collection") await props.setUserCollection(await getPosts_collection(userData._id));
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };
    start();
    setInitialLoad(false);
  }, [currentTab]);

  return (
    <>
      <TabButtons disableElevation variant="contained" color="primary">
        <Button
          className={currentTab === "Posts" ? "selected" : ""}
          onClick={(event) => {
            event.stopPropagation();
            setCurrentTab("Posts");
          }}
        >
          <span style={currentTab === "Posts" ? { color: "#fcfcfc" } : { color: "#454545" }}>Posts</span>
        </Button>
        <Button
          className={currentTab === "Collection" ? "selected" : ""}
          onClick={(event) => {
            event.stopPropagation();
            setCurrentTab("Collection");
          }}
        >
          <span style={currentTab === "Collection" ? { color: "#fcfcfc" } : { color: "#454545" }}>Collection</span>
        </Button>
      </TabButtons>
      {loading ? (
        <>
          {currentTab === "Posts" && <FeedLoader numberOfElements={props.app.userPosts.length} />}
          {currentTab === "Collection" && <FeedLoader numberOfElements={props.app.userData.recipeCollection.length} />}
        </>
      ) : (
        <>
          {currentTab === "Posts" &&
            (userPosts.length > 0 ? <Feed data={userPosts} /> : <NoPostsMessage text="This user has no posts." />)}
          {currentTab === "Collection" &&
            (userCollection.length > 0 ? (
              <Feed data={userCollection} />
            ) : (
              <NoPostsMessage text="This user's collection is empty." />
            ))}
        </>
      )}
    </>
  );
}

const TabButtons = styled(withTheme(ButtonGroup))((props) => ({
  margin: "0 auto",
  marginBottom: 30,
  boxShadow: "0 3px 4px -1px rgba(0,0,0,0.05)",
  borderBottom: "1px solid rgba(0,0,0,0.05)",
  borderRadius: "2rem",
  width: "100%",
  maxWidth: 600,

  "& button": {
    backgroundColor: "#fcfcfc",
    backgroundImage: "none",
    fontWeight: 600,
    width: 350,
    padding: 10,
    display: "flex",
    alignItems: "center",
    transition: "width 0.25s ease",
    "&.selected": {
      backgroundColor: "#5805e9",
      backgroundImage: "linear-gradient(90deg, #5805e9 0%, #8b20eb 100%)",
    },

    [props.theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [props.theme.breakpoints.down("sm")]: {
      padding: "10px 40px",
      width: "50%",
      minWidth: 150,
    },
    "&:hover": {
      backgroundColor: "#fcfcfc",
    },
    "&:first-child": {
      borderRight: "1px solid rgba(0,0,0,0.15)",
    },
  },
  [props.theme.breakpoints.down("sm")]: {
    "& button": {
      width: 200,
    },
  },
}));

export default connect(mapStateToProps, mapDispatchToProps)(ProfileFeeds);
