import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { styled, withTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { HeartIcon, CommentsIcon, StarIcon } from "../Assets/Icons";
import { likeHandler, getPosts_discover, getPosts_following, getPosts_collection, collectionHandler } from "../Utils";
import PopupPostDrawer from "./PopupPostDrawer";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setFollowingFeed: (data) => dispatch({ type: "SET_FOLLOWING_FEED", payload: data }),
  setDiscoverFeed: (data) => dispatch({ type: "SET_DISCOVER_FEED", payload: data }),
  setUserCollection: (data) => dispatch({ type: "SET_USER_COLLECTION", payload: data }),
  setUser: (data) => dispatch({ type: "UPDATE_USER_INFO", payload: data }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

const SinglePost = (props) => {
  const [showDetails, setShowDetails] = useState(false);
  const [show, setShow] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [postFirstLoad, setPostFirstLoad] = useState(true);
  const [postLikes, setPostLikes] = useState(props.data.likes);
  const [liked, setLiked] = useState(postLikes.findIndex((like) => like.userID === props.user._id) !== -1);
  const [inCollection, setInCollection] = useState(
    props.app.userCollection.findIndex((recipe) => recipe._id === props.data._id) !== -1
  );
  const [goToComments, setGoToComments] = useState(false);
  const { data, user } = props;

  const showDetailsHandler = () => {
    setShowDetails(!showDetails);
  };

  const likeRecipe = async () => {
    const newLike = {
      userID: props.user._id,
      username: "",
      referenceID: props.data._id,
    };
    await likeHandler(newLike);
    const indexOfPost = postLikes.findIndex((like) => like.userID === props.user._id);
    if (indexOfPost === -1) {
      setLiked(!liked);
      return setPostLikes([...postLikes, { userID: props.user._id }]);
    } else {
      const filteredLikes = postLikes.filter((like) => like.userID !== props.user._id);
      setPostLikes(filteredLikes);
      setLiked(!liked);
    }
  };

  const updateLiked = async () => {
    await setPostLikes(props.data.likes);
    setLiked(props.data.likes.findIndex((like) => like.userID === props.user._id) !== -1);
  };

  const toggleCollection = async () => {
    await collectionHandler(props.data._id);
    if (props.type !== "" && props.app.userCollection.findIndex((recipe) => recipe._id === props.data._id) !== -1) {
      if (!showDetails) {
        await setShow(false);
        setInitialLoad(false);
        await props.setCollectionLoading(true);

        await props.setUserCollection(await getPosts_collection(props.user._id));
      } else {
        setShow(false);
        setInCollection(!inCollection);
      }
    } else {
      await props.setUserCollection(await getPosts_collection(props.user._id));
    }
  };

  const openComments = async () => {
    showDetailsHandler();
    await setGoToComments(true);
    setGoToComments(false);
  };

  const setPosts = async () => {
    if (props.currentTab === "Following") props.setFollowingFeed(await getPosts_following());
    if (props.currentTab === "Discover") props.setDiscoverFeed(await getPosts_discover());
    if (props.type === "Collection") props.setUserCollection(await getPosts_collection(props.user._id));
  };

  const totalCommentsLength = () => {
    const commentsLength = data.comments.length;
    let repliesLength = 0;
    data.comments.forEach((comment) => (repliesLength += comment.comments.length));
    return commentsLength + repliesLength;
  };

  useEffect(() => {
    if (!postFirstLoad && !showDetails) {
      setPosts();
    }
    setPostFirstLoad(false);
  }, [showDetails]);

  useEffect(() => {
    updateLiked();
  }, [props.data.likes]);

  useEffect(() => {
    const updateInCollection = async () => {
      await setInCollection(props.app.userCollection.findIndex((recipe) => recipe._id === props.data._id) !== -1);
    };
    updateInCollection();
  }, [props.app.userCollection]);

  return (
    <>
      <AnimatePresence>
        {show && (
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            xl={3}
            component={motion.div}
            animate={initialLoad ? { y: [300, 0], opacity: [0, 0, 1] } : {}}
            transition={{ ease: "easeInOut", duration: 0.5, delay: props.delay / 8 }}
            exit={{ y: [0, -5, 300], opacity: [1, 0, 0] }}
            style={initialLoad ? { opacity: 0 } : { opacity: 1 }}
          >
            <PostContainer>
              <ImageContainer>
                {data.images && data.images.length > 0 ? (
                  <img className="post-img" onClick={showDetailsHandler} src={data.images[0]} alt="recipe" />
                ) : (
                  <img
                    className="post-img"
                    onClick={showDetailsHandler}
                    src="https://res.cloudinary.com/dwx0x1pe9/image/upload/v1616005149/Nomyos/default-user_h8hwcd.png"
                    alt="recipe"
                  />
                )}

                <User onClick={showDetailsHandler}>
                  {data.userID !== user._id && (
                    <Link to={`/profile/${props.data.userID}`}>
                      <Button label="hidden">
                        <img src={data.author_picture} alt="profile" />
                        <Typography variant="body2">{data.author}</Typography>
                      </Button>
                    </Link>
                  )}
                  {data.userID === user._id && <Box style={{ marginBottom: 10 }}></Box>}
                  <Typography variant="h6">{data.name}</Typography>
                </User>

                <ButtonBar>
                  {data.likes && (
                    <Button label="hidden" onClick={likeRecipe}>
                      <HeartIcon liked={liked} />
                      <Typography variant="body2">{postLikes.length}</Typography>
                    </Button>
                  )}
                  <Button label="hidden" onClick={openComments}>
                    <CommentsIcon />
                    <Typography variant="body2">{totalCommentsLength()}</Typography>
                  </Button>
                  <Button label="hidden" onClick={toggleCollection}>
                    <StarIcon inCollection={inCollection} />
                  </Button>
                </ButtonBar>
              </ImageContainer>
            </PostContainer>
          </Grid>
        )}
      </AnimatePresence>
      <PopupPostDrawer
        data={data}
        likeRecipe={likeRecipe}
        postLikes={postLikes}
        liked={liked}
        inCollection={inCollection}
        toggleCollection={toggleCollection}
        goToComments={goToComments}
        showDetails={showDetails}
        showDetailsHandler={showDetailsHandler}
      />
    </>
  );
};

const PostContainer = styled(Box)({
  marginBottom: 10,
  borderRadius: "1rem",
  overflow: "hidden",
  flex: "1 1 auto",
  position: "relative",
  boxShadow: "0 3px 4px -1px rgba(0,0,0,0.05)",
  borderTop: "1px solid rgba(255,255,255,0.25)",
  borderBottom: "1px solid rgba(0,0,0,0.125)",

  "& svg": {
    stroke: "#454545",
    width: 20,
    height: 20,
    marginRight: 6,
  },
  "&::before": {
    content: `""`,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 0,
    backgroundColor: " #fcfcfc",
  },
});
const ImageContainer = styled(withTheme(Box))((props) => ({
  overflow: "hidden",
  width: "100%",
  height: 280,
  minHeight: 200,
  position: "relative",
  cursor: "pointer",
  "&:hover .post-img": {
    transform: "scale(1.09)",
  },
  "& img": {
    objectFit: "cover",
    height: "100%",
    width: "100%",
    transition: "transform 0.25s ease",
  },
  "& h6": {
    position: "relative",
    zIndex: 2,
    color: "#fcfcfc",
    paddingLeft: 10,
    textShadow: "0 2px 3px  rgba(0,0,0,0.35), 0 3px 10px rgba(0,0,0,0.1)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "90%",
  },
}));

const ButtonBar = styled(Box)({
  position: "absolute",
  bottom: 0,
  right: 0,
  zIndex: 10,
  display: "flex",
  color: "#fcfcfc",
  backgroundColor: "rgba(255,255,255,0.85)",
  borderRadius: "1rem 0rem 0rem 0rem",
  padding: "4px 12px 4px",
  minWidth: 200,
  "& button": {
    backgroundColor: "transparent",
    color: "#fcfcfc",
    marginRight: 6,
    backgroundImage: "none",
    minWidth: 0,
    padding: "8px 14px",
    width: "37%",
    transition: "transform 0.25s ease",
    "&:active": {
      transform: "scale(0.75)",
    },
    "&:last-child": {
      padding: "8px 10px",
      marginRight: 0,
      width: "26%",
      "& svg": {
        margin: 0,
      },
    },
  },
  "& p": {
    fontWeight: 500,
  },
});

const User = styled(Box)({
  position: "absolute",
  top: 6,
  left: 6,
  zIndex: 1,
  width: "100%",
  "&::before": {
    content: `""`,
    position: "absolute",
    top: -6,
    left: -6,
    right: 6,
    bottom: 6,
    zIndex: 0,
    height: 100,
    background: "linear-gradient(0deg, rgba(0,0,0,0) 0%,  rgba(0,0,0,0.65) 100%)",
  },
  "& button": {
    backgroundImage: "none",
    backgroundColor: "transparent",
    textTransform: "none",
    "& span": {
      "& p": {
        color: "#fcfcfc",
        fontWeight: 500,
      },
    },
  },
  "& img": {
    width: 32,
    height: 32,
    borderRadius: "100%",
    marginRight: 6,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SinglePost);
