import "./App.css";
import Main from "./Main";
import { ThemeProvider } from "@material-ui/core";
import theme from "./Assets/theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Main />
    </ThemeProvider>
  );
}

export default App;
