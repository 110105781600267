import React from "react";
import Lottie from "react-lottie";
import animationData from "../Assets/spinningDumplingAnim.json";
import Box from "@material-ui/core/Box";
import { styled, withTheme } from "@material-ui/core/styles";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function SpinningDumpling() {
  return (
    <>
      <AnimationContainer>
        <Lottie options={defaultOptions} height={300} width={300} isClickToPauseDisabled={true} />
      </AnimationContainer>
      <AnimationContainerSmall>
        <Lottie options={defaultOptions} height={200} width={200} isClickToPauseDisabled={true} />
      </AnimationContainerSmall>
    </>
  );
}

const AnimationContainer = styled(withTheme(Box))((props) => ({
  borderRadius: "1rem 0rem 0rem 1rem",
  padding: "0 3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  [props.theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const AnimationContainerSmall = styled(withTheme(Box))((props) => ({
  borderRadius: "1rem 0rem 0rem 1rem",
  padding: "0 3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  [props.theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

export default SpinningDumpling;
