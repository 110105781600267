import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { styled, withTheme } from "@material-ui/core/styles";
import { motion, AnimatePresence } from "framer-motion";
import { HeartIcon, MoreIcon, DeleteIcon, CloseIcon } from "../../Assets/Icons";
import { likeHandler, deleteCommentHandler } from "../../Utils";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Moment from "react-moment";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

function SingleComment({
  data,
  setComments,
  user,
  setReplyingHandler,
  type,
  showReplies,
  setShowReplies,
  replyingTo,
  replyingToID,
}) {
  const { username, text, user_profilePicture, createdAt } = data;
  const [showMenu, setShowMenu] = useState(false);
  const [liked, setLiked] = useState(data.likes.findIndex((like) => like.userID === user._id) !== -1);
  const [commentLikes, setCommentLikes] = useState(data.likes.length);

  const deleteComment = async () => {
    const comments = await deleteCommentHandler(data._id);
    if (comments) {
      setShowMenu(false);
      setComments(comments);
    }
  };

  const likeComment = async () => {
    const newLike = {
      userID: user._id,
      username: "",
      referenceID: data._id,
    };
    await likeHandler(newLike);
    setLiked(!liked);
    if (liked) setCommentLikes(commentLikes - 1);
    if (!liked) setCommentLikes(commentLikes + 1);
  };

  useEffect(() => {
    setLiked(data.likes.findIndex((like) => like.userID === user._id) !== -1);
    setCommentLikes(data.likes.length);
  }, [data.likes]);

  return (
    <>
      <SingleCommentContainer
        component={motion.div}
        animate={{ x: [-300, 0], opacity: [0, 0, 1] }}
        transition={{ ease: "easeInOut", duration: 0.5 }}
        style={
          type === "reply"
            ? {
                marginLeft: 40,
                marginTop: -10,
                maxWidth: 560,
              }
            : {}
        }
      >
        <Left style={{ display: "flex", flexDirection: "column", marginRight: 16 }}>
          {data.user_profilePicture && <img src={user_profilePicture} alt="user-profile" />}
          <Button onClick={likeComment}>
            <HeartIcon liked={liked} /> {commentLikes}
          </Button>
        </Left>
        <Box
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Box
              style={
                data.userID === user._id
                  ? {
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      marginBottom: -10,
                    }
                  : {
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }
              }
            >
              <Link to={`/profile/${data.userID}`}>
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  {username}
                </Typography>
              </Link>
              {data.userID === user._id && (
                <MoreButton onClick={() => setShowMenu(true)}>
                  <MoreIcon />
                </MoreButton>
              )}
            </Box>
            <Typography variant="body2" style={{ width: "100%", wordBreak: "break-word" }}>
              {type === "reply" && (
                <span style={{ fontWeight: 500 }}>
                  Replying to{" "}
                  <Link to={`/profile/${replyingToID}`} style={{ color: "#735cdd" }}>
                    @{replyingTo}
                  </Link>{" "}
                </span>
              )}
              {text}
            </Typography>
          </Box>

          <Box>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2" style={{ marginRight: 6, color: "rgba(0,0,0,0.3)" }}>
                <Moment fromNow ago>
                  {createdAt}
                </Moment>
              </Typography>

              {type !== "reply" && (
                <>
                  <Button
                    onClick={() => setReplyingHandler({ text: text, username: username, referenceID: data._id }, true)}
                    style={{ textTransform: "none" }}
                  >
                    Reply
                  </Button>
                  {data.comments.length > 0 && (
                    <Button onClick={() => setShowReplies(!showReplies)} style={{ textTransform: "none" }}>
                      {!showReplies
                        ? `Show ${data.comments.length} repl${data.comments.length === 1 ? "y" : "ies"}`
                        : `Hide ${data.comments.length} repl${data.comments.length === 1 ? "y" : "ies"}`}
                    </Button>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Box>
        <AnimatePresence>
          {showMenu && (
            <MenuContainer
              component={motion.div}
              animate={{ y: [100, 5, 0], opacity: [0, 1, 1] }}
              transition={{ ease: "easeInOut", duration: 0.5 }}
              exit={{ y: [0, 5, 100], opacity: [1, 1, 0] }}
            >
              <ul>
                <li>
                  <Button onClick={deleteComment}>
                    <Box display="flex" alignItems="center" className="delete-btn">
                      <DeleteIcon /> Delete
                    </Box>
                  </Button>
                </li>
                <li>
                  <Button onClick={() => setShowMenu(false)}>
                    <Box display="flex" alignItems="center">
                      <CloseIcon /> Cancel
                    </Box>
                  </Button>
                </li>
              </ul>
            </MenuContainer>
          )}
        </AnimatePresence>
      </SingleCommentContainer>
    </>
  );
}

const SingleCommentContainer = styled(Box)({
  display: "flex",
  borderRadius: "0rem",
  maxWidth: 600,
  marginBottom: 20,
  position: "relative",
  padding: "10px 0px",
  "& img": {
    width: 48,
    height: 48,
    borderRadius: "100%",
  },
  "& button": {
    backgroundImage: "none",
    backgroundColor: "transparent",
    padding: "2px 10px",
    minWidth: 0,
    "& svg": {
      width: 24,
      height: 24,
      stroke: "#454545",
    },
  },
});

const Left = styled(Box)({
  "& button": {
    marginTop: 6,
    minWidth: 50,
    "& svg": {
      marginRight: 6,
      height: 16,
      width: 16,
    },
  },
});

const MoreButton = styled(Button)({
  height: 40,
  width: 40,
  marginTop: -8,
});

const MenuContainer = styled(withTheme(Box))((props) => ({
  position: "absolute",
  right: 10,
  top: 10,
  margin: "0 auto",
  backgroundColor: "#fcfcfc",
  width: "200px",
  borderRadius: "1rem",
  zIndex: 12,
  boxShadow: "0 3px 10px -1px rgba(0,0,0,0.125)",
  border: "1px solid rgba(0,0,0,0.125)",
  opacity: 0,
  overflow: "hidden",

  "& ul": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    margin: 0,
    padding: 0,

    "& li": {
      width: "100%",
      margin: 0,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",

      "& button": {
        backgroundColor: "transparent",
        backgroundImage: "none",
        borderRadius: 0,
        width: "100%",
        padding: "10px 10px 10px 20px",
        height: 55,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid rgba(0,0,0,0.075)",
        textTransform: "none",
        boxShadow: "none",
        fontSize: 16,
        [props.theme.breakpoints.down("sm")]: {
          height: 75,
        },
        "&:hover": {
          boxShadow: "none",
        },
        "& .delete-btn": {
          color: "#d72638",
          "& svg": {
            stroke: "#d72638",
          },
        },
        "& a": {
          width: "100%",
          color: "#454545",
          padding: 0,
          "& div": {
            width: "100%",
          },
        },
        "& svg": {
          stroke: "#454545",
          width: 32,
          height: 32,
          backgroundColor: "#e7e7e7",
          borderRadius: "100%",
          padding: 8,
          marginRight: "10px !important",
        },
      },
      "&:first-child": {
        borderRadius: "1rem 1rem 0rem 0rem",
        "& button": {
          borderRadius: "1rem 1rem 0rem 0rem",
        },
      },
      "&:last-child": {
        borderRadius: "1rem 1rem 0rem 0rem",
        "& button": {
          borderRadius: "0rem 0rem 1rem 1rem",
          borderBottom: "transparent",
        },
      },
    },
  },
}));

export default connect(mapStateToProps, mapDispatchToProps)(SingleComment);
