import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { styled, withTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LockIcon from "@material-ui/icons/Lock";
import Logo from "../../Assets/Logo/logo_large_purple_sidetext_alt.png";
import LoginLottie from "./LoginLottie";
import InputAdornment from "@material-ui/core/InputAdornment";
import SuccessAlert from "../Alerts/SuccessAlert";
import ErrorAlert from "../Alerts/ErrorAlert";
import CircularProgress from "@material-ui/core/CircularProgress";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch({ type: "UPDATE_USER_INFO", payload: data }),
  setUserCollection: (data) => dispatch({ type: "SET_USER_COLLECTION", payload: data }),
  setLoading: (boolean) => dispatch({ type: "SET_LOADING", payload: boolean }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

const Login = (props) => {
  const [loginInput, setLoginInput] = useState({
    username: "",
    password: "",
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const loginInputHandler = (event) => {
    setLoginInput({
      ...loginInput,
      [event.target.name]: event.target.value,
    });
  };

  const loginHandler = async (event, guest = false) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/users/login`,
        {
          username: guest ? process.env.REACT_APP_GUEST_USER : loginInput.username,
          password: guest ? process.env.REACT_APP_GUEST_PASS : loginInput.password,
        },
        { withCredentials: true }
      );
      const data = response.data;
      if (data.errors) {
        setTimeout(() => {
          setLoading(false);
          props.setError(data.errors);
          props.showErrors(true);
        }, 1500);
        setTimeout(() => {
          props.showErrors(false);
          props.setError([]);
        }, 5500);
      } else {
        setShowSuccess(true);
        setTimeout(() => {
          setShowSuccess(false);
          setLoading(false);
        }, 1500);
        setTimeout(() => {
          props.setLoading(true);
          props.setUser(data);
          props.setUserCollection(data.recipeCollection);
        }, 1500);
        setTimeout(() => {
          props.setLoading(false);
        }, 2800);
      }
      setLoginInput({
        username: "",
        password: "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    props.showErrors(false);
    props.setError([]);
  }, []);

  return (
    <LoginFullWrap>
      <LoginMainContainer display="flex" alignItems="center">
        <Grid container spacing={0} alignItems="center" justify="center">
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" className="height-scroll">
              <Link to="/">
                <img src={Logo} alt="logo" />
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} lg={7} xl={5}>
            <LoginPanel
              component={motion.div}
              animate={{ y: [100, -10, 0], opacity: [0, 1, 1] }}
              transition={{ ease: "easeInOut", duration: 0.75 }}
            >
              <Grid container spacing={0}>
                <Grid item xs={12} md={6}>
                  <LoginLottie />
                </Grid>
                <Grid item xs={12} md={6} className="heightController">
                  <Right display="flex" flexDirection="column">
                    <Box fontWeight="fontWeightBold" fontSize={18}>
                      Welcome back!
                    </Box>
                    <Box color="secondary" fontWeight="fontWeightLight" fontSize={20} mb={3}>
                      Enter your details below.
                    </Box>

                    <ErrorAlert
                      text={props.errors.errors.length > 0 && props.errors.errors[0].msg}
                      show={props.errors.show}
                    />

                    <SuccessAlert text="Success, Logging In..." show={showSuccess} />

                    <form onSubmit={loginHandler}>
                      <Input
                        id="input-with-icon-adornment"
                        name="username"
                        color="secondary"
                        label="Username/Email"
                        onChange={loginInputHandler}
                        value={loginInput.username}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircleIcon style={{ opacity: 0.55, color: "#735cdd" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Input
                        name="password"
                        color="secondary"
                        label="Password"
                        type="password"
                        onChange={loginInputHandler}
                        value={loginInput.password}
                        autoComplete="on"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockIcon style={{ opacity: 0.55, color: "#735cdd" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <LoginButton type="submit" color="primary">
                        {loading ? <CircleLoader /> : "Log In"}
                      </LoginButton>
                    </form>
                    <Box mt={2}>
                      <Typography variant="body2" align="center">
                        <Link to="/forgotpassword">Forgot Password?</Link>
                      </Typography>
                    </Box>
                    <Box mt={2} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <Typography variant="body2" align="center">
                        Don't have an account? <Link to="/register">Register Now</Link>
                      </Typography>
                      <Typography variant="body2" align="center" style={{ fontWeight: 700, marginTop: 10 }}>
                        or
                      </Typography>
                      <Button className="guest-btn" onClick={(event) => loginHandler(event, true)}>
                        Sign in as guest
                      </Button>
                    </Box>
                  </Right>
                </Grid>
              </Grid>
            </LoginPanel>
          </Grid>
        </Grid>
      </LoginMainContainer>
    </LoginFullWrap>
  );
};

const LoginMainContainer = styled(withTheme(Box))((props) => ({
  height: "100vh",
  width: "100%",
  "& a": {
    "& img": {
      width: "100%",
      maxWidth: 240,
      minWidth: 150,
      opacity: 0.75,
      display: "block",
      [props.theme.breakpoints.down("sm")]: {
        marginTop: 30,
      },
    },
  },
}));

const LoginFullWrap = styled(Box)({
  overflow: "auto",
  "&::-webkit-scrollbar": {
    width: 6,
    height: 6,
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,0.25)",
    borderRadius: "2rem",
  },
});

const LoginPanel = styled(withTheme(Box))((props) => ({
  borderRadius: "2rem",
  color: "#0f0423",
  position: "relative",
  zIndex: 999,
  backgroundColor: "#fcfcfc",
  boxShadow: "0 3px 4px -1px rgba(0,0,0,0.05)",
  borderBottom: "1px solid rgba(0,0,0,0.125)",
  maxWidth: 815,
  margin: "30px auto 40px",
  [props.theme.breakpoints.down("xs")]: {
    borderRadius: 0,
  },
  "& form": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    "& ~ div": {
      marginTop: 20,
    },
  },
  "& a": {
    color: "#735cdd",
    fontWeight: "bold",
  },
  "& .heightController": {
    height: "100%",
  },
}));

const Right = styled(withTheme(Box))((props) => ({
  margin: "2rem",
  padding: "30px",
  [props.theme.breakpoints.down("sm")]: {
    padding: "50px 30px",
  },
  height: "100%",
  position: "relative",
  zIndex: 999,
  display: "flex",
  justifyContent: "center",
  "& button": {
    marginTop: 10,
    "&.guest-btn": {
      backgroundImage: "none",
      backgroundColor: "#e7e7e7",
      padding: "6px 24px",
      marginTop: 16,
    },
  },
}));

const Input = styled(TextField)({
  width: "100%",
  minWidth: 200,
  marginBottom: 20,
  color: "#735cdd !important",
});

const LoginButton = styled(Button)({
  width: "100%",
  minWidth: 200,
  padding: "10px",
});

const CircleLoader = styled(CircularProgress)({
  height: "25px !important",
  width: "25px !important",
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
