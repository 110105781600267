import axios from "axios";

const authorise = async (setUser) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/auth`, { withCredentials: true });
    const data = response.data;
    if (!data.errors) {
      await setUser(data);
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};

export { authorise };
