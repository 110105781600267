import React from "react";
import { connect } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import Alert from "@material-ui/lab/Alert";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

const SuccessAlert = ({ text, show, filled }) => {
  return (
    <AnimatePresence>
      {show && (
        <motion.span animate={{ y: [5, -1, -1, 5] }} transition={{ repeat: "Infinity" }}>
          <Alert
            severity="success"
            variant={filled && "filled"}
            style={{ marginBottom: 20 }}
            animate={{ scale: [0.1, 1], opacity: [0, 1] }}
            transition={{ ease: "easeInOut", duration: 0.5 }}
            exit={{ scale: [1, 0], opacity: [1, 0] }}
            component={motion.div}
          >
            {text}
          </Alert>
        </motion.span>
      )}
    </AnimatePresence>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessAlert);
