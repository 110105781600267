import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  "@keyframes skChase": {
    "100%": { transform: "rotate(360deg)" },
  },
  "@keyframes skChaseDot": {
    " 80%, 100%": { transform: "rotate(360deg)" },
  },
  "@keyframes skChaseDotBefore": {
    "50%": {
      transform: "scale(0.4)",
    },
    "100%, 0%": {
      transform: "scale(1.0)",
    },
  },
  skChase: {
    width: 100,
    height: 100,
    position: "relative",
    animation: "$skChase 2.5s infinite linear both",
    zIndex: 999,
  },
  skChaseDot: {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    animation: "$skChaseDot 2.0s infinite ease-in-out both",
    "&::before": {
      content: `""`,
      display: "block",
      width: "25%",
      height: "25%",
      backgroundColor: "#735cdd",
      borderRadius: "100%",
      animation: "$skChaseDotBefore 2.0s infinite ease-in-out both",
    },
    "&:nth-child(1)": {
      animationDelay: "1.1s",
      "&::before": {
        content: `""`,
        animationDelay: "1.1s",
      },
    },
    "&:nth-child(2)": {
      animationDelay: "1s",
      "&::before": {
        content: `""`,
        animationDelay: "1s",
      },
    },
    "&:nth-child(3)": {
      animationDelay: "0.9s",
      "&::before": {
        content: `""`,
        animationDelay: "1.1s",
      },
    },
    "&:nth-child(4)": {
      animationDelay: "0.8s",
      "&::before": {
        content: `""`,
        animationDelay: "1.1s",
      },
    },
    "&:nth-child(5)": {
      animationDelay: "0.7s",
      "&::before": {
        content: `""`,
        animationDelay: "0.7s",
      },
    },
    "&:nth-child(6)": {
      animationDelay: "0.6s",
      "&::before": {
        content: `""`,
        animationDelay: "0.6s",
      },
    },
  },
}));

function SpinnerLoader() {
  const classes = styles();
  return (
    <Box className={classes.skChase}>
      <Box className={classes.skChaseDot} />
      <Box className={classes.skChaseDot} />
      <Box className={classes.skChaseDot} />
      <Box className={classes.skChaseDot} />
      <Box className={classes.skChaseDot} />
      <Box className={classes.skChaseDot} />
    </Box>
  );
}

export default SpinnerLoader;
