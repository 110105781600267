import React, { useState } from "react";
import { connect } from "react-redux";
import { styled, withTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { EditIcon, DeleteIcon, MoreIcon, CloseIcon } from "../../Assets/Icons";
import { motion, AnimatePresence } from "framer-motion";
import { deleteRecipeHandler, getPosts_discover } from "../../Utils";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setFollowingFeed: (data) => dispatch({ type: "SET_FOLLOWING_FEED", payload: data }),
  setDiscoverFeed: (data) => dispatch({ type: "SET_DISCOVER_FEED", payload: data }),
  setShowBuilderDrawer: (boolean) => dispatch({ type: "SET_SHOW_BUILDER_DRAWER", payload: boolean }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

function MoreMenu(props) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const dropdownLinks = [
    {
      text: "Edit Recipe",
      icon: <EditIcon />,
      func: () => {
        props.showDetailsHandler();
        props.showEditDrawer(true);
      },
    },
    {
      text: "Delete",
      icon: <DeleteIcon />,
      func: () => {
        setShow(!show);
        setShowConfirmation(!showConfirmation);
      },
    },
    {
      text: "Close",
      icon: <CloseIcon />,
      func: () => {
        setShow(!show);
      },
    },
  ];

  const dropdownLinks_confirm = [
    {
      text: "Yes, Delete",
      icon: <DeleteIcon />,
      func: async () => {
        setLoading(true);
        await deleteRecipeHandler(props.recipeID);
        setTimeout(async () => {
          props.setDiscoverFeed(await getPosts_discover());
          setLoading(false);
          setShow(false);
          setShowConfirmation(false);
          props.showDetailsHandler();
        }, 2000);
      },
    },
    {
      text: "Cancel",
      icon: <CloseIcon />,
      func: () => {
        setShow(!show);
        setShowConfirmation(!showConfirmation);
      },
    },
  ];

  return (
    <>
      <Button onClick={() => setShow(!show)}>
        <MoreIcon />
      </Button>
      <AnimatePresence>
        {show && (
          <MenuContainer
            component={motion.div}
            animate={{ y: [100, 5, 0], opacity: [0, 1, 1] }}
            transition={{ ease: "easeInOut", duration: 0.5 }}
            exit={{ y: [0, 5, 100], opacity: [1, 1, 0] }}
          >
            {loading ? (
              <LoaderContainer>
                <CircleLoader />
              </LoaderContainer>
            ) : (
              <ul>
                {dropdownLinks.map(({ text, icon, func }, index) => (
                  <li>
                    <Button onClick={func && (() => func())}>
                      <Box display="flex" alignItems="center" className={text === "Delete" ? "delete-btn" : ""}>
                        {icon} {text}
                      </Box>
                    </Button>
                  </li>
                ))}
              </ul>
            )}
          </MenuContainer>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showConfirmation && (
          <>
            <ConfirmationText
              component={motion.p}
              animate={{ y: [100, 5, 0], opacity: [0, 1, 1] }}
              transition={{ ease: "easeInOut", duration: 0.5 }}
              exit={{ y: [0, 5, 100], opacity: [1, 1, 0] }}
              variant="body2"
              style={{ textAlign: "center" }}
            >
              Are you sure?
            </ConfirmationText>
            <MenuContainer
              component={motion.div}
              animate={{ y: [100, 5, 0], opacity: [0, 1, 1] }}
              transition={{ ease: "easeInOut", duration: 0.5 }}
              exit={{ y: [0, 5, 100], opacity: [1, 1, 0] }}
            >
              {loading ? (
                <LoaderContainer>
                  <CircleLoader />
                </LoaderContainer>
              ) : (
                <ul>
                  {dropdownLinks_confirm.map(({ text, icon, func }, index) => (
                    <li key={index}>
                      <Button onClick={func && (() => func())}>
                        <Box display="flex" alignItems="center" className={text === "Yes, Delete" ? "delete-btn" : ""}>
                          {icon} {text}
                        </Box>
                      </Button>
                    </li>
                  ))}
                </ul>
              )}
            </MenuContainer>
          </>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {(show || showConfirmation) && (
          <FullPageWrap
            component={motion.div}
            animate={{ opacity: [0, 1] }}
            transition={{ ease: "easeInOut", duration: 0.25 }}
            exit={{ opacity: [1, 0] }}
            onClick={() => {
              setShow(false);
              setShowConfirmation(false);
            }}
          />
        )}
      </AnimatePresence>
    </>
  );
}

const MenuContainer = styled(withTheme(Box))((props) => ({
  position: "absolute",
  right: 0,
  left: 0,
  top: -170,
  margin: "0 auto",
  backgroundColor: "#fcfcfc",
  width: "300px",
  borderRadius: "1rem",
  zIndex: 12,
  boxShadow: "0 3px 10px -1px rgba(0,0,0,0.125)",
  border: "1px solid rgba(0,0,0,0.125)",
  opacity: 0,
  overflow: "hidden",
  [props.theme.breakpoints.down("sm")]: {
    top: -230,
  },

  "& ul": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    margin: 0,
    padding: 0,

    "& li": {
      width: "100%",
      margin: 0,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",

      "& button": {
        backgroundColor: "transparent",
        backgroundImage: "none",
        borderRadius: 0,
        width: "100%",
        padding: "10px 10px 10px 20px",
        height: 55,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid rgba(0,0,0,0.075)",
        textTransform: "none",
        boxShadow: "none",
        fontSize: 16,
        [props.theme.breakpoints.down("sm")]: {
          height: 75,
        },
        "&:hover": {
          boxShadow: "none",
        },
        "& .delete-btn": {
          color: "#d72638",
          "& svg": {
            stroke: "#d72638",
          },
        },
        "& a": {
          width: "100%",
          color: "#454545",
          padding: 0,
          "& div": {
            width: "100%",
          },
        },
        "& svg": {
          stroke: "#454545",
          width: 32,
          height: 32,
          backgroundColor: "#e7e7e7",
          borderRadius: "100%",
          padding: 8,
          marginRight: "10px !important",
        },
      },
      "&:first-child": {
        borderRadius: "1rem 1rem 0rem 0rem",
        "& button": {
          borderRadius: "1rem 1rem 0rem 0rem",
        },
      },
      "&:last-child": {
        borderRadius: "1rem 1rem 0rem 0rem",
        "& button": {
          borderRadius: "0rem 0rem 1rem 1rem",
          borderBottom: "transparent",
        },
      },
    },
  },
}));

const FullPageWrap = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,0.15)",
  borderRadius: "2rem 2rem 0rem 0rem",
});

const ConfirmationText = styled(withTheme(Typography))((props) => ({
  textAlign: "center",
  padding: "10px 10px 30px",
  position: "absolute",
  right: 0,
  left: 0,
  top: -216,
  zIndex: 11,
  margin: "0 auto",
  width: "300px",
  backgroundColor: "#fcfcfc",
  borderRadius: "1rem 1rem 0rem 0rem",
  boxShadow: "0 3px 10px -1px rgba(0,0,0,0.125)",
  border: "1px solid rgba(0,0,0,0.125)",
  fontWeight: "bold",
  [props.theme.breakpoints.down("sm")]: {
    top: -276,
  },
}));

const LoaderContainer = styled(Box)({
  padding: "50px 0px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

const CircleLoader = styled(CircularProgress)({
  color: "rgba(0,0,0,0.65)",
  margin: "0 auto",
});

export default connect(mapStateToProps, mapDispatchToProps)(MoreMenu);
