import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { styled, withTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { updateUserData } from "../../Utils";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch({ type: "UPDATE_USER_INFO", payload: data }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

function Alert(props) {
  return <MuiAlert variant="filled" {...props} />;
}

const MainInfo = (props) => {
  const [userInfo, setUserInfo] = useState({
    username: { value: props.user.username, edit: false },
    bio: { value: props.user.bio, edit: false },
    email: { value: props.user.email, edit: false },
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [isInvalidUsername, setIsInvalidUsername] = useState(false);

  const updateInfoHandler = (event) => {
    const fieldName = event.target.name;
    setUserInfo({
      ...userInfo,
      [fieldName]: { value: event.target.value, edit: userInfo[fieldName].edit },
    });
    if (fieldName === "username") {
      const str = event.target.value.toString();
      const symbols = new RegExp("^[a-zA-Z0-9]*$");
      if (!symbols.test(str)) {
        return setIsInvalidUsername(true);
      }
      setIsInvalidUsername(false);
    }
  };

  const toggleEditHandler = (fieldName) => {
    setUserInfo({ ...userInfo, [fieldName]: { value: userInfo[fieldName].value, edit: !userInfo[fieldName].edit } });
  };

  const updateUserDataHandler = async (event) => {
    event.preventDefault();
    try {
      if (!isInvalidUsername) {
        const data = { username: userInfo.username.value, bio: userInfo.bio.value, email: userInfo.email.value };
        setLoading(true);
        setTimeout(async () => {
          const updatedData = await updateUserData(data);
          if (updatedData) {
            await props.setUser(updatedData);
            setShowSuccess(true);
            setSuccessMessage("Successfully updated data!");
            setLoading(false);
          } else {
            props.showErrors(true);
            setLoading(false);
            setUserInfo({
              username: { value: props.user.username, edit: false },
              bio: { value: props.user.bio, edit: false },
              email: { value: props.user.email, edit: false },
            });
            setTimeout(() => {
              props.showErrors(false);
            }, 5000);
          }
        }, 2000);
        setTimeout(() => {
          setShowSuccess(false);
          setSuccessMessage("");
        }, 7000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const forgottenPasswordHandler = async () => {
    try {
      setPasswordLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/users/forgotpassword/me`,
        {},
        { withCredentials: true }
      );
      const data = response.data;

      if (!data.errors) {
        setSuccessMessage("Success! Please check your email.");
        setTimeout(() => {
          setPasswordLoading(false);
          setShowSuccess(true);
        }, 1500);
        setTimeout(() => {
          setShowSuccess(false);
          setSuccessMessage("");
        }, 5000);
      } else {
        setTimeout(() => {
          setPasswordLoading(false);
          props.showErrors(true);
        }, 1500);
        setTimeout(() => {
          props.showErrors(false);
        }, 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSuccess(false);
  };

  return (
    <>
      <MainInfoContainer>
        <form onSubmit={async (event) => await updateUserDataHandler(event)}>
          <SingleSetting>
            <Typography variant="body2">Username</Typography>
            <Grid container spacing={0} justify="center">
              <Grid item xs={12} md={10}>
                <Input
                  error={isInvalidUsername}
                  name="username"
                  variant="outlined"
                  color="secondary"
                  autoFocus={true}
                  helperText={isInvalidUsername && "Username contains invalid characters."}
                  value={userInfo.username.value}
                  inputProps={{ minLength: 4 }}
                  onChange={updateInfoHandler}
                  autoComplete={false}
                  disabled={!userInfo.username.edit}
                  required
                />
              </Grid>
              <InfoGridItem item xs={12} md={2}>
                {!userInfo.username.edit ? (
                  <BorderButton onClick={() => toggleEditHandler("username")}>Edit</BorderButton>
                ) : (
                  <Button
                    type="submit"
                    disabled={isInvalidUsername}
                    onClick={async () => {
                      setTimeout(() => {
                        toggleEditHandler("username");
                      }, 2000);
                    }}
                  >
                    {loading ? <CircleLoader /> : "Save"}
                  </Button>
                )}
              </InfoGridItem>
            </Grid>
          </SingleSetting>
          <SingleSetting>
            <Typography variant="body2">Bio</Typography>
            <Grid container spacing={0} justify="center">
              <Grid item xs={12} md={10}>
                <Input
                  name="bio"
                  variant="outlined"
                  color="secondary"
                  autoFocus={true}
                  value={userInfo.bio.value}
                  multiline={true}
                  autoComplete={false}
                  onChange={updateInfoHandler}
                  disabled={!userInfo.bio.edit}
                />
              </Grid>
              <InfoGridItem item xs={12} md={2}>
                {!userInfo.bio.edit ? (
                  <BorderButton onClick={() => toggleEditHandler("bio")}>Edit</BorderButton>
                ) : (
                  <Button
                    type="submit"
                    onClick={async () => {
                      setTimeout(() => {
                        toggleEditHandler("bio");
                      }, 2000);
                    }}
                  >
                    {loading ? <CircleLoader /> : "Save"}
                  </Button>
                )}
              </InfoGridItem>
            </Grid>
          </SingleSetting>
          <SingleSetting>
            <Typography variant="body2">Email</Typography>
            <Grid container spacing={0} justify="center">
              <Grid item xs={12} md={10}>
                <Input
                  name="email"
                  variant="outlined"
                  color="secondary"
                  autoFocus={true}
                  value={userInfo.email.value}
                  inputProps={{ minLength: 4 }}
                  autoComplete={false}
                  onChange={updateInfoHandler}
                  disabled={!userInfo.email.edit}
                  required
                />
              </Grid>
              <InfoGridItem item xs={12} md={2}>
                {!userInfo.email.edit ? (
                  <BorderButton onClick={() => toggleEditHandler("email")}>Edit</BorderButton>
                ) : (
                  <Button
                    type="submit"
                    onClick={async () => {
                      setTimeout(() => {
                        toggleEditHandler("email");
                      }, 2000);
                    }}
                  >
                    {loading ? <CircleLoader /> : "Save"}
                  </Button>
                )}
              </InfoGridItem>
            </Grid>
          </SingleSetting>
        </form>
        <SingleSetting style={{ marginBottom: 0 }}>
          <Typography variant="body2">Password</Typography>
          <Grid container spacing={0} justify="center">
            <Grid item xs={12} md={8}>
              <Box>
                <Input
                  name="password"
                  type="password"
                  variant="outlined"
                  color="secondary"
                  autoFocus={true}
                  value={"•••••••••••••"}
                  autoComplete={false}
                  inputProps={{ minLength: 8 }}
                  disabled
                  required
                />
              </Box>
            </Grid>
            <InfoGridItem item xs={12} md={4}>
              <Button onClick={forgottenPasswordHandler}>{passwordLoading ? <CircleLoader /> : "Rest Password"}</Button>
            </InfoGridItem>
          </Grid>
        </SingleSetting>
      </MainInfoContainer>
      <Snackbar open={showSuccess} autoHideDuration={4500} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={props.errors.show} autoHideDuration={4500} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Error updating data, please try again.
        </Alert>
      </Snackbar>
    </>
  );
};

const MainInfoContainer = styled(Box)({
  backgroundColor: "rgba(255,255,255,0.65)",
  padding: "40px 60px",
  borderRadius: "1rem",
  borderBottom: "3px solid rgba(0,0,0,0.125)",
  width: "100%",
  maxWidth: 600,
});

const InfoGridItem = styled(withTheme(Grid))((props) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  [props.theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

const SingleSetting = styled(withTheme(Box))((props) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: 30,
  width: "100%",
  "& p": {
    width: "100%",
    color: "rgba(0,0,0,0.5)",
    fontWeight: 500,
  },
  "& button": {
    padding: "4px 20px",
    color: "#fcfcfc",
    borderRadius: "3rem",
    minWidth: 80,
    height: 40,
    [props.theme.breakpoints.down("sm")]: {
      marginTop: 20,
      width: 270,
    },
  },
}));

const Input = styled(withTheme(TextField))((props) => ({
  color: "#735cdd",
  fontWeight: 24,
  borderRadius: "0.5rem",

  "& input": {
    fontSize: 24,
    padding: "10px 0px",
    borderBottom: "1px solid #735cdd",
    fontWeight: 500,
    width: "80%",
    transition: "border 0.25s ease",
    [props.theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "&:disabled": {
      borderBottom: "1px solid rgba(0,0,0,0.05)",
    },
  },
  "&:autofill": {
    backgroundColor: "transparent !important",
    borderRadius: 0,
    borderBottom: "1px solid rgba(0,0,0,0.05)",
    color: "#95929a !important",
    "& input": {
      backgroundColor: "transparent !important",
      color: "#95929a !important",
    },
  },
  "& fieldset": {
    border: "none",
    "& legend": {
      "& span": {
        fontSize: 24,
      },
    },
  },
  "& div": {
    padding: 0,
  },
  "& div textarea": {
    borderBottom: "1px solid #735cdd",
    padding: "10px 0px",
    width: "80%",
    fontWeight: 500,
    transition: "border 0.25s ease",
    "&:disabled": {
      borderBottom: "1px solid rgba(0,0,0,0.05)",
    },
  },
}));

const BorderButton = styled(Button)({
  backgroundColor: "transparent",
  backgroundImage: "none",
  color: "#454545 !important",
  border: "2px solid #454545",
});

const CircleLoader = styled(CircularProgress)({
  height: "25px !important",
  width: "25px !important",
});

export default connect(mapStateToProps, mapDispatchToProps)(MainInfo);
