import React, { useState } from "react";
import { styled, withTheme } from "@material-ui/core/styles";
import Messages from "./Messages";
import Box from "@material-ui/core/Box";
import NotificationsList from "./NotificationsList";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";

function NotificationsFeeds() {
  const [show, setShow] = useState(true);
  return (
    <>
      <TabButtons disableElevation variant="contained" color="primary">
        <Button
          style={
            show
              ? {
                  backgroundColor: "#5805e9",
                  backgroundImage: "linear-gradient(90deg, #5805e9 0%, #8b20eb 100%)",
                }
              : {
                  backgroundColor: "#fcfcfc",
                  backgroundImage: "none",
                }
          }
          onClick={(event) => {
            event.stopPropagation();
            setShow(true);
          }}
        >
          <span style={show ? { color: "#fcfcfc" } : { color: "#454545" }}>Messages</span>
        </Button>
        <Button
          style={
            !show
              ? {
                  backgroundColor: "#5805e9",
                  backgroundImage: "linear-gradient(90deg, #5805e9 0%, #8b20eb 100%)",
                }
              : {
                  backgroundColor: "#fcfcfc",
                  backgroundImage: "none",
                }
          }
          onClick={(event) => {
            event.stopPropagation();
            setShow(false);
          }}
        >
          <span style={!show ? { color: "#fcfcfc" } : { color: "#454545" }}>Notifications</span>
        </Button>
      </TabButtons>
      <Box
        style={{
          width: "80%",
          padding: 20,
          backgroundColor: "#edeeaa",
          borderRadius: "1rem",
          margin: "0 auto",
          textAlign: "center",
          fontWeight: 500,
          color: "#acad44",
        }}
      >
        Feature coming soon, design preview only.
      </Box>
      {show ? <Messages /> : <NotificationsList />}
    </>
  );
}

const TabButtons = styled(withTheme(ButtonGroup))((props) => ({
  margin: "0 auto",
  marginBottom: 20,
  /*  boxShadow: "0 3px 10px -1px rgba(0,0,0,0.125)",
  border: "1px solid rgba(0,0,0,0.05)", */
  boxShadow: "0 3px 4px -1px rgba(0,0,0,0.05)",
  borderBottom: "1px solid rgba(0,0,0,0.05)",
  borderRadius: "2rem",
  width: "100%",
  maxWidth: 600,

  "& button": {
    backgroundColor: "#fcfcfc",
    backgroundImage: "none",
    fontWeight: 600,
    width: 350,
    padding: 10,
    display: "flex",
    alignItems: "center",
    transition: "width 0.25s ease",

    [props.theme.breakpoints.down("md")]: {
      width: "50%",
    },
    [props.theme.breakpoints.down("sm")]: {
      padding: "10px 40px",
      width: "100%",
    },
    "&:hover": {
      backgroundColor: "#e7e7e7",
    },
    "&:first-child": {
      borderRight: "1px solid rgba(0,0,0,0.15)",
    },
  },
}));

export default NotificationsFeeds;
