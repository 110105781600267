import React from "react";
import { styled } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function Instructions({ instructions }) {
  return (
    <InstructionsContainer>
      <Typography variant="h5" style={{ marginBottom: 10, fontWeight: "bold" }}>
        Instructions
      </Typography>
      {instructions.map((instruction, index) => (
        <SingleInstruction key={index}>
          <Typography variant="body1" style={{ fontWeight: "bold", marginRight: 6 }}>
            Step {index + 1}
          </Typography>
          <Typography variant="body1"> {instruction.text}</Typography>
        </SingleInstruction>
      ))}
    </InstructionsContainer>
  );
}

const InstructionsContainer = styled(Box)({
  backgroundColor: "rgba(255,255,255,0.65)",
  padding: 20,
  borderRadius: "1rem",
  /*  boxShadow: "0 3px 3px -1px rgba(0,0,0,0.1)", */
  borderBottom: "1px solid rgba(0,0,0,0.125)",
});
const SingleInstruction = styled(Box)({
  padding: 10,
  borderRadius: "1rem",
  marginBottom: 10,
});

export default Instructions;
