import React from "react";
import { styled } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

function Tags({ tags }) {
  return (
    <TagsContainer>
      {tags.map((tag, index) => (
        <SingleTag key={index}>{tag}</SingleTag>
      ))}
    </TagsContainer>
  );
}

const TagsContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: 20,
});

const SingleTag = styled(Box)({
  borderRadius: "2rem",
  backgroundColor: "rgba(0,0,0,0.65)",
  boxShadow: "0 3px 3px -1px rgba(0,0,0,0.1)",
  color: "#fcfcfc",
  padding: "6px 12px",
  fontSize: 12,
  minWidth: 80,
  textAlign: "center",
  marginRight: 6,
});

export default Tags;
