import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { styled } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { SearchIcon } from "../../Assets/Icons";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";

const tags = [
  "Beef",
  "Breakfast",
  "Chicken",
  "Chocolatey",
  "Cold",
  "Dairy Free",
  "Dessert",
  "Dinner",
  "Fish",
  "Gluten Free",
  "Hot",
  "Lamb",
  "Lunch",
  "Mild",
  "Pescatarian",
  "Pork",
  "Quick & Easy",
  "Salmon",
  "Seafood",
  "Sour",
  "Spicy",
  "Sweet",
  "Vegan",
  "Vegetarian",
];

const cuisine = [
  "African",
  "American",
  "Brazilian",
  "British",
  "Caribbean",
  "Chinese",
  "Ethiopian",
  "French",
  "Greek",
  "Indian",
  "Indonesian",
  "Irish",
  "Israeli",
  "Italian",
  "Jamaican",
  "Japanese",
  "Korean",
  "Lebanese",
  "Malaysian",
  "Mexican",
  "Middle Eastern",
  "Moroccan",
  "Nigerian",
  "Peruvian",
  "Polish",
  "Portuguese",
  "Romanian",
  "Russian",
  "Singaporean",
  "South American",
  "Spanish",
  "Sri Lankan",
  "Thai",
  "Turkish",
  "Vegetarian",
  "Vietnamese",
];

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setSearchResults: (searchResults) => dispatch({ type: "SET_SEARCH_RESULTS", payload: searchResults }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

function SearchBar(props) {
  const [searchInput, setSearchInput] = useState("");
  const [selectedCuisine, setSelectedCuisine] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const searchHandler = async (event) => {
    event.preventDefault();
    props.showErrors(false);
    try {
      if (searchInput.length > 0 && !searchInput.startsWith(" ")) {
        props.setLoading(true);
        const newSearch = {
          searchTerm: searchInput,
          cuisine: selectedCuisine,
          tags: selectedTags,
        };
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/recipes/search`, newSearch, {
          withCredentials: true,
        });
        const data = response.data;
        if (!data.errors) {
          resetHandler();
          setTimeout(() => {
            props.setLoading(false);
            props.setSearchResults(data);
          }, 2000);
        } else {
          props.setLoading(false);
          props.showErrors(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectCuisineHandler = (cuisine) => {
    if (selectedCuisine.findIndex((text) => cuisine === text) === -1) {
      setSelectedCuisine([...selectedCuisine, cuisine]);
    } else {
      setSelectedCuisine(selectedCuisine.filter((selectedCuisine) => selectedCuisine !== cuisine));
    }
  };

  const selectTagHandler = (tag) => {
    if (selectedTags.findIndex((text) => tag === text) === -1) {
      setSelectedTags([...selectedTags, tag]);
    } else {
      setSelectedTags(selectedTags.filter((selectedTag) => selectedTag !== tag));
    }
  };

  const resetHandler = () => {
    setSearchInput("");
    setSelectedCuisine([]);
    setSelectedTags([]);
  };

  return (
    <>
      <SearchBarContainer>
        <form onSubmit={searchHandler}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TitleBar>
                <Typography variant="h3">Search</Typography>
              </TitleBar>
            </Grid>
            <Grid item xs={12}>
              <Search>
                <SearchIcon />
                <BaseInput
                  name="search"
                  color="secondary"
                  label="Search"
                  placeholder="Search..."
                  onChange={(event) => setSearchInput(event.target.value)}
                  value={searchInput}
                />
              </Search>
            </Grid>
            <Grid item xs={12} md={6}>
              <Tags>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography variant="h6">Filter by Cuisine</Typography>
                </AccordionSummary>
                <TagsContainer>
                  {cuisine.map((cuisine, index) => (
                    <SingleTag
                      key={index}
                      onClick={() => selectCuisineHandler(cuisine)}
                      className={selectedCuisine.findIndex((text) => cuisine === text) !== -1 && "selected"}
                    >
                      {cuisine}
                    </SingleTag>
                  ))}
                </TagsContainer>
              </Tags>
            </Grid>
            <Grid item xs={12} md={6}>
              <Tags>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography variant="h6">Filter by Tags</Typography>
                </AccordionSummary>
                <TagsContainer>
                  {tags.map((tag, index) => (
                    <SingleTag
                      key={index}
                      onClick={() => selectTagHandler(tag)}
                      className={selectedTags.findIndex((text) => tag === text) !== -1 && "selected"}
                    >
                      {tag}
                    </SingleTag>
                  ))}
                </TagsContainer>
              </Tags>
            </Grid>
          </Grid>

          <ButtonBar>
            <Button type="submit">Submit</Button>
            <Button onClick={resetHandler}>Reset</Button>
          </ButtonBar>
        </form>
      </SearchBarContainer>
    </>
  );
}

const SearchBarContainer = styled(Box)({
  width: "100%",
  borderRadius: "1rem",
  marginBottom: 20,
  "& svg": {
    stroke: "#454545",
    height: 32,
    width: 32,
  },
});

const Search = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  backgroundColor: "#fcfcfc",
  borderRadius: "1rem",
  padding: "0px 20px",
  boxShadow: "0 3px 4px -1px rgba(0,0,0,0.05)",
  borderBottom: "1px solid rgba(0,0,0,0.125)",
});

const TitleBar = styled(Box)({
  width: "100%",
  marginTop: 10,
  marginBottom: 6,
  "& h3": {
    fontWeight: 700,
  },
});

const BaseInput = styled(InputBase)({
  backgroundColor: "#fcfcfc",
  borderRadius: "1rem",
  padding: "16px 20px",
  fontSize: 25,
  width: "100%",
});

const Tags = styled(Accordion)({
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  backgroundColor: "#fcfcfc",
  borderRadius: "1rem",
  padding: "2px 10px",
  boxShadow: "0 3px 4px -1px rgba(0,0,0,0.05)",
  borderBottom: "1px solid rgba(0,0,0,0.125)",
  "& h6": {
    fontSize: 16,
    fontWeight: 700,
  },
  "&::before": {
    display: "none",
  },
  "&:last-child": {
    borderRadius: "1rem",
  },
});

const TagsContainer = styled(AccordionDetails)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flexWrap: "wrap",
});

const SingleTag = styled(Box)({
  padding: "14px 20px",
  backgroundColor: "rgba(0,0,0,0.1)",
  borderRadius: "2rem",
  margin: "6px",
  fontWeight: 500,
  fontSize: 14,
  cursor: "pointer",
  minWidth: 75,
  textAlign: "center",
  "&.selected": {
    backgroundColor: "#5d08e9",
    backgroundImage: "linear-gradient(135deg, #5805e9 0%, #8b20eb 100%)",
    color: "#fcfcfc",
  },
});

const ButtonBar = styled(Box)({
  marginTop: 16,
  marginBottom: 16,
  "& button": {
    padding: "10px 50px",
    marginRight: 12,

    "&:first-child": {
      backgroundColor: "rgba(138, 201, 38, 0.8)",
      backgroundImage: "none",
      color: "#212121",
    },
    "&:last-child": {
      backgroundColor: "rgba(0,0,0,0.1)",
      backgroundImage: "none",
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
