import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { styled, withTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch({ type: "UPDATE_USER_INFO", payload: data }),
  setLoading: (boolean) => dispatch({ type: "SET_LOADING", payload: boolean }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

const tags = [
  "Sweet",
  "Spicy",
  "Sour",
  "Mild",
  "Chocolatey",
  "Dessert",
  "Hot",
  "Cold",
  "Pork",
  "Chicken",
  "Beef",
  "Seafood",
  "Lamb",
  "Fish",
  "Salmon",
  "Vegetarian",
  "Vegan",
  "Gluten Free",
  "Dairy Free",
  "Quick & Easy",
  "Breakfast",
  "Lunch",
  "Dinner",
  "Pescatarian",
];

const cuisine = [
  "Mexican",
  "Chinese",
  "Italian",
  "Japanese",
  "Thai",
  "Vietnamese",
  "Malaysian",
  "Singaporean",
  "French",
  "Spanish",
  "Indian",
  "Vegetarian",
  "Turkish",
  "Korean",
  "American",
  "Middle Eastern",
  "Greek",
  "Russian",
  "Moroccan",
  "Indonesian",
  "British",
  "Israeli",
  "Lebanese",
  "Peruvian",
  "Sri Lankan",
  "Jamaican",
  "Polish",
  "Ethiopian",
  "African",
  "Brazilian",
  "Romanian",
  "Nigerian",
  "Caribbean",
  "Irish",
  "South American",
  "Portuguese",
];

const CollectionController = (props) => {
  const [selectedCuisine, setSelectedCuisine] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);

  const selectCuisineHandler = (cuisine) => {
    if (selectedCuisine.findIndex((text) => cuisine === text) === -1) {
      setSelectedCuisine([...selectedCuisine, cuisine]);
    } else {
      setSelectedCuisine(selectedCuisine.filter((selectedCuisine) => selectedCuisine !== cuisine));
    }
  };

  const selectTagHandler = (tag) => {
    if (selectedTags.findIndex((text) => tag === text) === -1) {
      setSelectedTags([...selectedTags, tag]);
    } else {
      setSelectedTags(selectedTags.filter((selectedTag) => selectedTag !== tag));
    }
  };

  useEffect(() => {
    if (!initialLoad) props.updateFilter(selectedTags);
    setInitialLoad(false);
  }, [selectedTags]);

  useEffect(() => {
    if (!initialLoad) props.updateFilter(selectedCuisine);
    setInitialLoad(false);
  }, [selectedCuisine]);

  return (
    <ControllerContainer>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TitleBar>
            <Typography variant="h3">Collection</Typography>
          </TitleBar>
        </Grid>
        <CollectionGridItem item xs={12} md={6}>
          <Tags>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h6">Filter by Cuisine</Typography>
            </AccordionSummary>
            <TagsContainer>
              {cuisine.map((cuisine) => (
                <SingleTag
                  onClick={() => selectCuisineHandler(cuisine)}
                  className={selectedCuisine.findIndex((text) => cuisine === text) !== -1 && "selected"}
                >
                  {cuisine}
                </SingleTag>
              ))}
            </TagsContainer>
          </Tags>
        </CollectionGridItem>
        <CollectionGridItem item xs={12} md={6}>
          <Tags>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h6">Filter by Tags</Typography>
            </AccordionSummary>
            <TagsContainer>
              {tags.map((tag) => (
                <SingleTag
                  onClick={() => selectTagHandler(tag)}
                  className={selectedTags.findIndex((text) => tag === text) !== -1 && "selected"}
                >
                  {tag}
                </SingleTag>
              ))}
            </TagsContainer>
          </Tags>
        </CollectionGridItem>
      </Grid>
    </ControllerContainer>
  );
};

const ControllerContainer = styled(Box)({
  width: "100%",
  marginBottom: 32,
  borderRadius: "1rem",
});

const TitleBar = styled(Box)({
  width: "100%",
  marginTop: 10,
  marginBottom: 6,
  "& h3": {
    fontWeight: 700,
  },
});

const Tags = styled(Accordion)({
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  backgroundColor: "#fcfcfc",
  borderRadius: "1rem",
  padding: "6px 10px",
  boxShadow: "0 3px 4px -1px rgba(0,0,0,0.05)",
  borderBottom: "1px solid rgba(0,0,0,0.125)",

  "& h6": {
    fontSize: 16,
    fontWeight: 700,
  },
  /* "&::before": {
    display: "none",
  }, */
  "&:first-child": {
    borderTopLeftRadius: "1rem",
    borderTopRightRadius: "1rem",
  },
  "&:last-child": {
    borderBottomLeftRadius: "1rem",
    borderBottomRightRadius: "1rem",
  },
});

const TagsContainer = styled(AccordionDetails)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flexWrap: "wrap",
});

const SingleTag = styled(Box)({
  padding: "14px 20px",
  backgroundColor: "rgba(0,0,0,0.1)",
  borderRadius: "2rem",
  margin: "6px",
  fontWeight: 500,
  fontSize: 14,
  cursor: "pointer",
  minWidth: 75,
  textAlign: "center",
  "&.selected": {
    backgroundColor: "#5d08e9",
    backgroundImage: "linear-gradient(135deg, #5805e9 0%, #8b20eb 100%)",
    color: "#fcfcfc",
  },
});

const CollectionGridItem = styled(withTheme(Grid))((props) => ({
  [props.theme.breakpoints.up("md")]: {
    paddingLeft: "0px !important",
  },
  "&:last-child": {
    [props.theme.breakpoints.up("md")]: {
      paddingLeft: "4px !important",
    },
  },
}));

export default connect(mapStateToProps, mapDispatchToProps)(CollectionController);
