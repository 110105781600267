import React from "react";
import { styled } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { CloseIcon } from "../../Assets/Icons";
import { motion } from "framer-motion";

function SingleInstructionBlock({ index, data, deleteBlock, updateData }) {
  return (
    <motion.div
      component={motion.div}
      animate={{ y: [-100, 0], opacity: [0, 0, 1] }}
      transition={{ duration: 0.25 }}
      exit={{ y: [0, -100], opacity: [1, 0, 0] }}
      style={{ width: "100%" }}
    >
      <SingleInstruction className="topbar">
        <Input
          variant="outlined"
          name="text"
          color="secondary"
          label="Instruction Text"
          value={data.text}
          onChange={(event) => updateData(event, index)}
          multiline={true}
          required
        />
        <Button onClick={() => deleteBlock(index)}>
          <CloseIcon />
        </Button>
      </SingleInstruction>
    </motion.div>
  );
}

const SingleInstruction = styled(Box)({
  position: "relative",
  padding: 14,
  marginBottom: 30,
  borderRadius: "1rem",
  backgroundColor: "rgba(0,0,0,0.1)",
  boxShadow: "0 3px 3px -1px rgba(0,0,0,0.1)",
  "& .topbar": {
    marginBottom: 10,
  },
  "& button": {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 32,
    margin: "auto",
    backgroundImage: "none",
    backgroundColor: "#d72638",
    color: "#fcfcfc",
    minWidth: 0,
    height: 32,
    width: 32,
    "&:hover": {
      backgroundColor: "#be192a",
    },
    "& svg": {
      height: 24,
      width: 24,
      stroke: "#fcfcfc",
    },
  },
});

const Input = styled(TextField)({
  width: "100%",
  minWidth: 200,
  color: "#735cdd",
  backgroundColor: "#fcfcfc",
  fontWeight: 24,
  borderRadius: "0.5rem",
  boxShadow: "0 3px 3px -1px rgba(0,0,0,0.1)",
  paddingRight: 50,
  "& input": {
    fontSize: 24,
  },
  "& fieldset": {
    border: "none",
    boxShadow: "none",

    "& legend": {
      "& span": {
        backgroundColor: "red",
        fontSize: 24,
      },
    },
  },
});

export default SingleInstructionBlock;
