import React from "react";
import Typography from "@material-ui/core/Typography";

function RecipeName({ text }) {
  return (
    <Typography variant="h4" style={{ fontWeight: "bold", marginBottom: 0 }}>
      {text}
    </Typography>
  );
}

export default RecipeName;
