import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { styled } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import SinglePost from "../SinglePost";
import SingleUser from "./SingleUser";
import ErrorAlert from "../Alerts/ErrorAlert";
import SearchLoaderUsers from "../Loaders/SearchLoaderUsers";
import SearchLoaderRecipes from "../Loaders/SearchLoaderRecipes";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setSearchResults: (searchResults) => dispatch({ type: "SET_SEARCH_RESULTS", payload: searchResults }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

function SearchResults({ app, loading, errors }) {
  const [showTitles, setShowTitles] = useState(false);
  const { users, recipes } = app.searchResults;

  useEffect(() => {
    if (users.length > 0 || recipes.length > 0) setShowTitles(true);
  }, [users, recipes]);

  return (
    <SearchResultsContainer>
      {loading ? (
        <>
          <SearchLoaderUsers numberOfElements={users.length} />
          <SearchLoaderRecipes numberOfElements={recipes.length} />
        </>
      ) : (
        !errors.show && (
          <>
            {users.length > 0 && (
              <Box style={{ marginBottom: 32 }}>
                {showTitles && (
                  <Typography variant="h6" style={{ fontWeight: 500, marginBottom: 6, color: "#b6b6b6" }}>
                    Users
                  </Typography>
                )}
                <Grid container spacing={3}>
                  {users.map((user, index) => (
                    <SingleUser key={index} data={user} delay={index} />
                  ))}
                </Grid>
              </Box>
            )}
            {recipes.length > 0 && (
              <Box style={{ marginBottom: 16 }}>
                {showTitles && (
                  <Typography variant="h6" style={{ fontWeight: 500, marginBottom: 6, color: "#b6b6b6" }}>
                    Recipes
                  </Typography>
                )}
                <Grid container spacing={3}>
                  {recipes.map((result, index) => (
                    <SinglePost key={index} data={result} />
                  ))}
                </Grid>
              </Box>
            )}
          </>
        )
      )}
      {!loading && errors.show && (
        <Box style={{ maxWidth: 600 }}>
          <ErrorAlert text="No Results Found." show={true} filled={true} />
        </Box>
      )}
    </SearchResultsContainer>
  );
}

const SearchResultsContainer = styled(Box)({
  marginBottom: 70,
  paddingBottom: 70,
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
