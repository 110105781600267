import React, { useState, useEffect, createRef } from "react";
import { styled, withTheme } from "@material-ui/core/styles";
import { motion, AnimatePresence } from "framer-motion";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import { MessagesIcon, CloseIcon } from "../../Assets/Icons";
import { newCommentHandler } from "../../Utils";
import SingleCommentBlock from "./SingleCommentBlock";
import CircularProgress from "@material-ui/core/CircularProgress";

function Comments(props) {
  const [initialLoad, setInitialLoad] = useState(true);
  const [newCommentInput, setNewCommentInput] = useState("");
  const [showMore, setShowMore] = useState(false);
  const previewComments = [...props.data].splice(0, 3);
  const [loading, setLoading] = useState(false);
  const [replyingTo, setReplyingTo] = useState({
    text: "",
    username: "",
    referenceID: "",
  });
  const [showReplying, setShowReplying] = useState(false);
  const commentsEnd = createRef();

  const postNewComment = async () => {
    try {
      if (!loading) {
        if (newCommentInput.length > 0 && !newCommentInput.startsWith(" ")) {
          setLoading(true);
          if (!showReplying) {
            const newComment = {
              referenceID: props.recipeID,
              text: newCommentInput,
            };
            setTimeout(async () => {
              const comments = await newCommentHandler(newComment);
              props.setComments(comments);
              setNewCommentInput("");
              setLoading(false);
            }, 1500);
          } else {
            const newComment = {
              referenceID: replyingTo.referenceID,
              text: newCommentInput,
            };
            setTimeout(async () => {
              const comments = await newCommentHandler(newComment);
              await props.setComments(comments);
              setNewCommentInput("");
              setLoading(false);
              resetReplying();
            }, 1500);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const scrollToBottom = () => {
    commentsEnd.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const setReplyingHandler = (data, scroll = false) => {
    setReplyingTo(data);
    setShowReplying(true);
    if (scroll) scrollToBottom();
  };

  const resetReplying = async (scroll) => {
    setReplyingHandler({ text: "", username: "", referenceID: "" }, scroll);
    setShowReplying(false);
  };

  useEffect(() => {
    if (!initialLoad) scrollToBottom();
    if (initialLoad) setInitialLoad(false);
  }, [props.data]);

  useEffect(() => {
    if (showMore) scrollToBottom();
  }, [showMore]);

  useEffect(() => {
    if (props.goToComments) scrollToBottom();
  }, [props.goToComments]);

  return (
    <CommentsBlock>
      <Typography variant="h5" style={{ fontWeight: "bold", marginBottom: 10 }}>
        Comments
      </Typography>

      <CommmentsContainer>
        {!showMore
          ? previewComments.map((comment, index) => (
              <SingleCommentBlock
                key={index}
                recipeID={props.recipeID}
                data={comment}
                setComments={props.setComments}
                setReplyingHandler={setReplyingHandler}
              />
            ))
          : props.data.map((comment, index) => (
              <SingleCommentBlock
                key={index}
                recipeID={props.recipeID}
                data={comment}
                setComments={props.setComments}
                setReplyingHandler={setReplyingHandler}
              />
            ))}
        {(!props.data || props.data.length === 0) && (
          <Typography variant="body1" style={{ color: "rgba(0,0,0,0.4)" }}>
            Be the first to comment
          </Typography>
        )}
        <div ref={commentsEnd}></div>
      </CommmentsContainer>
      {!showMore && props.data.length > 3 && (
        <ShowMoreButton onClick={() => setShowMore(true)}>Show More</ShowMoreButton>
      )}
      <NewCommentContainer>
        <Box style={{ position: "relative", maxWidth: 600 }}>
          <AnimatePresence>
            {showReplying && (
              <ReplyBox
                component={motion.div}
                animate={{ x: [-300, 0], opacity: [0, 0, 1] }}
                transition={{ ease: "easeInOut", duration: 0.25 }}
                exit={{ x: [0, -300], opacity: [1, 0, 0] }}
              >
                <Box>
                  <Typography variant="body2">
                    <span style={{ fontWeight: "bold", marginRight: 6 }}>Replying to:</span>
                    <span style={{ fontWeight: "bold", marginRight: 6, color: "#735cdd" }}>@{replyingTo.username}</span>
                    {replyingTo.text}
                  </Typography>
                </Box>
                <Button onClick={resetReplying}>
                  <CloseIcon />
                </Button>
              </ReplyBox>
            )}
          </AnimatePresence>

          <Input
            name="recipeName"
            variant="outlined"
            color="secondary"
            placeholder="Share your thoughts"
            multiline={true}
            inputProps={{ minLength: 3 }}
            onChange={(event) => setNewCommentInput(event.target.value)}
            value={newCommentInput}
            required
          />
          <SendButton onClick={postNewComment}>{loading ? <CircleLoader /> : <MessagesIcon />}</SendButton>
        </Box>
      </NewCommentContainer>
    </CommentsBlock>
  );
}

const CommentsBlock = styled(withTheme(Box))((props) => ({
  marginBottom: 100,
  padding: 20,
  [props.theme.breakpoints.down("sm")]: {
    padding: 0,
  },
}));

const CommmentsContainer = styled(Box)({});

const NewCommentContainer = styled(Box)({
  position: "relative",
  marginTop: 50,
});

const Input = styled(InputBase)({
  width: "100%",
  minWidth: 200,
  color: "#735cdd",
  fontWeight: 18,
  borderRadius: "1rem",
  backgroundColor: "rgba(255,255,255,0.65)",
  padding: "20px 100px 40px 20px",
  borderBottom: "1px solid rgba(0,0,0,0.125)",
  "& input": {
    fontSize: 18,
    fontWeight: 500,
    "&::placeholder": {
      color: "#000",
    },
  },
  "& textarea": {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.5,
  },
  "& fieldset": {
    border: "none",
    "& legend": {
      "& span": {
        fontSize: 24,
      },
    },
  },
});

const SendButton = styled(Button)({
  position: "absolute",
  top: 12,
  right: 14,
  padding: 10,
  minWidth: 0,
  height: 50,
  width: 50,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& svg": {
    height: 24,
    width: 24,
    stroke: "#fcfcfc",
    marginLeft: -2,
  },
});

const ShowMoreButton = styled(Button)({
  minWidth: 0,
  padding: "4px 60px",
  borderRadius: "1rem",
  backgroundImage: "none",
  backgroundColor: "transparent",
  border: "2px solid #454545",
  marginTop: 10,
});

const CircleLoader = styled(CircularProgress)({
  height: "25px !important",
  width: "20px !important",
});

const ReplyBox = styled(withTheme(Box))((props) => ({
  backgroundColor: "rgba(255,255,255,0.65)",
  padding: "10px 10px 10px 20px",
  borderBottom: "1px solid rgba(0,0,0,0.125)",
  position: "absolute",
  zIndex: 10,
  bottom: "85%",
  left: 20,
  right: "20%",
  borderRadius: "2rem",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  opacity: 0,
  "& p": {
    width: 370,
    maxWidth: 370,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [props.theme.breakpoints.down("sm")]: {
      width: "20vh",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  "& button": {
    backgroundImage: "none",
    backgroundColor: "#e7e7e7",
    minWidth: 0,
    "& svg": {
      height: 24,
      width: 24,
    },
  },
}));

export default Comments;
