import React from "react";
import { styled, withTheme } from "@material-ui/core/styles";
import { motion } from "framer-motion";
import Typography from "@material-ui/core/Typography";
import SpinningDumpling from "../SpinningDumpling";

function NoPostsMessage({ text }) {
  return (
    <motion.div
      animate={{ scale: [0, 1.1, 1], opacity: [0, 1, 1] }}
      transition={{ ease: "easeInOut", duration: 0.5 }}
      style={{ opacity: 0 }}
    >
      <SpinningDumpling />
      <Message
        component={motion.h5}
        animate={{ y: [100, 5, 0], opacity: [0, 1, 1] }}
        transition={{ ease: "easeInOut", duration: 0.5, delay: 0.15 }}
      >
        {text}
      </Message>
    </motion.div>
  );
}

const Message = styled(withTheme(Typography))((props) => ({
  width: "100%",
  fontWeight: "bold",
  textAlign: "center",
  marginTop: -30,
  fontSize: "1.25rem",
  transition: "font-size 0.25s ease",
  [props.theme.breakpoints.up("sm")]: {
    fontSize: "1.65rem",
  },
}));

export default NoPostsMessage;
