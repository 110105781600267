import React from "react";
import { styled, withTheme } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";

function ProfileLoaderTop() {
  return (
    <>
      <LoaderContainer>
        <Skeleton variant="circle" animation="wave" width={180} height={180} style={{ marginBottom: 10 }} />
        <Skeleton variant="text" animation="wave" width={220} height={80} style={{ marginBottom: 10 }} />
        <Box style={{ display: "flex", justifyContent: "space-between", width: 500, marginBottom: 10 }}>
          <Skeleton
            variant="rect"
            animation="wave"
            width={150}
            height={55}
            style={{ borderRadius: "1.5rem 1.5rem 0rem 0rem" }}
          />
          <Skeleton
            variant="rect"
            animation="wave"
            width={150}
            height={55}
            style={{ borderRadius: "1.5rem 1.5rem 0rem 0rem" }}
          />
          <Skeleton
            variant="rect"
            animation="wave"
            width={150}
            height={55}
            style={{ borderRadius: "1.5rem 1.5rem 0rem 0rem" }}
          />
        </Box>
        <Skeleton variant="text" animation="wave" width={500} height={20} />
        <Skeleton variant="text" animation="wave" width={500} height={20} />
        <Skeleton variant="text" animation="wave" width={500} height={20} />
        <Skeleton variant="text" animation="wave" width={500} height={20} />
        <Box style={{ display: "flex", justifyContent: "space-between", width: 600, marginTop: 10, marginBottom: 30 }}>
          <Skeleton
            variant="rect"
            animation="wave"
            width={299}
            height={48}
            style={{ borderRadius: "3rem 0rem 0rem 3rem" }}
          />
          <Skeleton
            variant="rect"
            animation="wave"
            width={299}
            height={48}
            style={{ borderRadius: "0rem 3rem 3rem 0rem" }}
          />
        </Box>
        <Skeleton
          variant="rect"
          animation="wave"
          width={"90%"}
          height={600}
          style={{ borderRadius: "1.5rem", maxWidth: 1000 }}
        />
      </LoaderContainer>
      <LoaderContainerSmall>
        <Skeleton variant="circle" animation="wave" width={100} height={100} style={{ marginBottom: 0 }} />
        <Skeleton variant="text" animation="wave" width={220} height={80} style={{ marginBottom: 0 }} />
        <Box style={{ display: "flex", justifyContent: "space-between", width: 400, marginBottom: 4 }}>
          <Skeleton
            variant="rect"
            animation="wave"
            width={120}
            height={45}
            style={{ borderRadius: "1.5rem 1.5rem 0rem 0rem" }}
          />
          <Skeleton
            variant="rect"
            animation="wave"
            width={120}
            height={45}
            style={{ borderRadius: "1.5rem 1.5rem 0rem 0rem" }}
          />
          <Skeleton
            variant="rect"
            animation="wave"
            width={120}
            height={45}
            style={{ borderRadius: "1.5rem 1.5rem 0rem 0rem" }}
          />
        </Box>
        <Skeleton variant="text" animation="wave" width={400} height={20} />
        <Skeleton variant="text" animation="wave" width={400} height={20} />
        <Skeleton variant="text" animation="wave" width={400} height={20} />
        <Box style={{ display: "flex", justifyContent: "space-between", width: 400, marginTop: 10, marginBottom: 30 }}>
          <Skeleton
            variant="rect"
            animation="wave"
            width={199}
            height={48}
            style={{ borderRadius: "3rem 0rem 0rem 3rem" }}
          />
          <Skeleton
            variant="rect"
            animation="wave"
            width={199}
            height={48}
            style={{ borderRadius: "0rem 3rem 3rem 0rem" }}
          />
        </Box>
        <Skeleton
          variant="rect"
          animation="wave"
          width={"100%"}
          height={800}
          style={{ borderRadius: "1.5rem", maxWidth: 1000 }}
        />
      </LoaderContainerSmall>
    </>
  );
}

const LoaderContainer = styled(withTheme(Box))((props) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  [props.theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));
const LoaderContainerSmall = styled(withTheme(Box))((props) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  [props.theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

export default ProfileLoaderTop;
