import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#735cdd",
      contrastText: "#fff",
    },
    error: {
      main: "#d72638",
    },
    warning: {
      main: "#f5f749",
    },
    info: {
      main: "#dcdcdc",
    },
    success: {
      main: "#8ac926",
    },
  },
  typography: {
    fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif`,
  },
});

theme.props = {
  MuiButton: {
    disableElevation: true,
  },
  MuiAccordion: {
    disableElevation: true,
  },
  MuiPaper: {
    disableElevation: true,
  },
};

theme.overrides = {
  MuiButton: {
    root: {
      borderRadius: 25,
      backgroundColor: "#5805e9",
      backgroundImage: "linear-gradient(90deg, #5805e9 0%, #8b20eb 100%)",
      "&$disabled": {
        color: "#fff",
        opacity: 0.25,
      },
    },
  },
  MuiTypography: {
    body1: {
      fontSize: 16,
      color: "#454545",
    },
    body2: {
      fontSize: 14,
      color: "#454545",
    },
    h1: {
      color: "#454545",
    },
    h2: {
      color: "#454545",
    },
    h3: {
      color: "#454545",
    },
    h4: {
      color: "#454545",
    },
    h5: {
      color: "#454545",
    },
    h6: {
      color: "#454545",
    },
  },
  MuiAlert: {
    standardSuccess: {
      backgroundColor: "rgba(138, 201, 38, 0.25)",
    },
    filledError: {
      backgroundColor: "rgba(215,38,56, 0.8)",
    },
    filledSuccess: {
      backgroundColor: "rgba(138, 201, 38, 0.8)",
      color: "#212121",
    },
  },
  MuiFormLabel: {
    root: {
      fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif`,
      "&.Mui-focused": {
        color: "#735cdd",
      },
    },
  },
  MuiInputBase: {
    root: {
      fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif`,
    },
  },
  MuiSelect: {
    root: {
      backgroundColor: "#fcfcfc",
      borderRadius: "0.5rem",
      "&.Mui-selected": {
        backgroundColor: "#fcfcfc",
      },
    },
  },
  MuiListItem: {
    root: {
      "&.Mui-selected": {
        backgroundColor: "#fcfcfc",
      },
    },
  },
  MuiFormControl: {
    root: {
      borderRadius: "0.5rem",
      width: "100%",
      height: "100%",
    },
  },
  MuiCollapse: {
    entered: {
      width: "100%",
    },
    hidden: {
      width: "100%",
    },
  },
  MuiSkeleton: {
    root: {
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0,0,0,0.04)",
      borderRadius: "1rem",
    },
  },
};

export default theme;
