import React from "react";
import { styled } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import BannerAdImage from "../../Assets/AppAd.jpg";

function BannerAd() {
  return (
    <BannerAdContainer>
      <img src={BannerAdImage} alt="download-the-app" />
    </BannerAdContainer>
  );
}

const BannerAdContainer = styled(Box)({
  width: "100%",
  overflow: "hidden",
  borderRadius: "2rem",
  display: "flex",
  alignItems: "flex-end",
  /*   marginBottom: 80, */
  /*   marginTop: 100, */
  height: "100%",
  minHeight: 282,
  "& img": {
    width: "100%",
    borderRadius: "2rem",
  },
});

export default BannerAd;
