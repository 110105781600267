import React, { useState } from "react";
import { connect } from "react-redux";
import { styled, withTheme } from "@material-ui/core/styles";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Logo from "../Assets/Logo/logo_large_dark_sidetext_alt.png";
import Button from "@material-ui/core/Button";
import BugReportPopup from "./BugReportPopup";
import {
  HomeIcon,
  SearchIcon,
  MessagesIcon,
  StarIcon,
  PlusIcon,
  UserIcon,
  SettingsIcon,
  LogoutIcon,
  MenuIcon,
  CloseIcon,
  BackIcon,
  MenuMoreIcon,
  NewRecipeIcon,
  NewPostIcon,
  NewClipIcon,
  WarningIcon,
  /*   BellIcon,
  JellyBeanIcon,
  StoreIcon, */
} from "../Assets/Icons";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => dispatch({ type: "UPDATE_USER_INFO", payload: data }),
  setLoading: (boolean) => dispatch({ type: "SET_LOADING", payload: boolean }),
  setShowBuilderDrawer: (boolean) => dispatch({ type: "SET_SHOW_BUILDER_DRAWER", payload: boolean }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

const NavBar = (props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropDownMenuData, setDropdownMenuData] = useState("");
  const [initialOpen, setInitialOpen] = useState(true);
  const [showBugReport, setShowBugReport] = useState(false);

  const links = [
    { text: "/", icon: <HomeIcon /> },
    { text: "/search", icon: <SearchIcon /> },
    {
      text: "",
      icon: showDropdown ? <CloseIcon /> : <MenuIcon />,
      func: () => {
        setInitialOpen(true);
        setShowDropdown(true);
        setDropdownMenuData("");
      },
    },
    { text: "/notifications", icon: <MessagesIcon /> },
    { text: "/collection", icon: <StarIcon /> },
  ];

  const dropdownLinks = [
    {
      text: "Create",
      icon: <PlusIcon />,
      func: () => {
        setDropdownMenuData("create");
        setInitialOpen(false);
      },
    },
    { text: "Your Profile", icon: <UserIcon />, to: `/profile/${props.user._id}` },
    /*   { text: "Notifications", icon: <BellIcon />, to: "/notifications" }, */
    /*  { text: "Store", icon: <StoreIcon />, isStore: true }, */
    { text: "Settings", icon: <SettingsIcon />, disabled: true },
    {
      text: "Submit Bug Report",
      icon: <WarningIcon />,
      func: () => {
        setShowBugReport(true);
        setShowDropdown(false);
      },
    },
    { text: "Log Out", icon: <LogoutIcon />, href: `${process.env.REACT_APP_BASE_URL}/users/logout` },
  ];
  const dropdownLinks_create = [
    { text: "Go Back", icon: <BackIcon />, func: () => setDropdownMenuData("") },
    {
      text: "Recipe",
      icon: <NewRecipeIcon />,
      func: () => {
        setShowDropdown(false);
        setDropdownMenuData("");
        setInitialOpen(true);
        props.setShowBuilderDrawer(!props.app.showBuilderDrawer);
      },
    },
    { text: "Post", icon: <NewPostIcon />, disabled: true },
    { text: "Clip", icon: <NewClipIcon />, disabled: true },
  ];

  return (
    <>
      <TopBar>
        <TopBarLeft>
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
        </TopBarLeft>
        <TopBarRight>
          <Link to={`/profile/${props.user._id}`}>
            <UserProfile>
              <NavProfilePicture>
                <img src={props.user.image} alt="profile" />
              </NavProfilePicture>
              <Box fontWeight={500}>{props.user.username.toLowerCase()}</Box>
            </UserProfile>
          </Link>
        </TopBarRight>
      </TopBar>
      <NavBarMainContainer
        component={motion.div}
        animate={{ y: [-100, 10, 0], opacity: [0, 1, 1] }}
        transition={{ ease: "easeInOut", duration: 0.75 }}
      >
        <NavMainContent>
          <Middle>
            <ul>
              {links.map((link, index) => {
                if (link.text) {
                  return (
                    <li key={index}>
                      <Button label="hidden">
                        <Link as={Button} to={`${link.text}`} className={props.location === link.text ? "active" : ""}>
                          {link.icon}
                        </Link>
                      </Button>
                      {props.location === link.text && (
                        <Link
                          to={`${link.text}`}
                          className="active"
                          style={{ filter: "blur(2px)", marginTop: "-44px", transform: "scale(1.1)", opacity: 0.5 }}
                        >
                          {link.icon}
                        </Link>
                      )}
                    </li>
                  );
                } else {
                  return (
                    <li key={index}>
                      <Button className="create-btn" label="hidden" onClick={() => link.func()}>
                        {link.icon}
                      </Button>
                    </li>
                  );
                }
              })}
            </ul>
          </Middle>

          <AnimatePresence>
            {showDropdown && (
              <NavDropdown
                component={motion.div}
                animate={{ y: [100, -5, 0], opacity: [0, 1, 1] }}
                transition={{ ease: "easeInOut", duration: 0.35 }}
                exit={{ y: [0, -5, 100], opacity: [1, 1, 0] }}
              >
                {dropDownMenuData === "" ? (
                  <AnimatePresence>
                    {!initialOpen ? (
                      <motion.div
                        animate={{ x: [-100, 5, 0] }}
                        transition={{ ease: "easeInOut", duration: 0.5 }}
                        exit={{ x: [0, 5, -100] }}
                      >
                        <ul>
                          {dropdownLinks.map(({ text, icon, func, isStore }, index) => (
                            <li key={index}>
                              <Button onClick={func && (() => func())}>
                                <Box display="flex" alignItems="center">
                                  {icon} {text}
                                </Box>
                                {func && <MenuMoreIcon />}
                                {/*         {isStore && (
                                  <JellyBeansContainer
                                    style={{
                                      padding: "3px 14px",
                                      backgroundColor: "#e7e7e7",
                                      backgroundImage: "none",
                                      color: "#454545",
                                      margin: 0,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <JellyBeanIcon /> 2492
                                  </JellyBeansContainer>
                                )} */}
                              </Button>
                            </li>
                          ))}
                        </ul>
                      </motion.div>
                    ) : (
                      <ul>
                        {dropdownLinks.map(({ text, icon, func, isStore, href, to, subMenu, disabled }, index) => (
                          <li key={index}>
                            <Button
                              disabled={disabled}
                              onClick={
                                (func && (() => func())) ||
                                (href &&
                                  (() => {
                                    props.setLoading(true);
                                    window.location.replace(href);
                                  }))
                              }
                            >
                              {to ? (
                                <Link to={to} onClick={() => setShowDropdown(false)}>
                                  <Box display="flex" alignItems="center">
                                    {icon} {text}
                                  </Box>
                                </Link>
                              ) : (
                                <Box display="flex" alignItems="center">
                                  {icon} {text}
                                </Box>
                              )}

                              {subMenu && <MenuMoreIcon />}
                              {/*        {isStore && (
                                <JellyBeansContainer
                                  style={{
                                    padding: "3px 14px",
                                    backgroundColor: "#e7e7e7",
                                    backgroundImage: "none",
                                    color: "#454545",
                                    margin: 0,
                                    fontWeight: "bold",
                                  }}
                                >
                                  <JellyBeanIcon /> 2492
                                </JellyBeansContainer>
                              )} */}
                            </Button>
                          </li>
                        ))}
                      </ul>
                    )}
                  </AnimatePresence>
                ) : (
                  <AnimatePresence>
                    <motion.div
                      animate={{ x: [100, 5, 0] }}
                      transition={{ ease: "easeInOut", duration: 0.5 }}
                      exit={{ x: [0, 5, 100] }}
                    >
                      <ul>
                        {dropdownLinks_create.map(({ text, icon, func, disabled }, index) => (
                          <li key={index}>
                            <Button onClick={func && (() => func())} disabled={disabled}>
                              {disabled ? (
                                <Box display="flex" alignItems="center">
                                  {icon} Coming soon
                                </Box>
                              ) : (
                                <Box display="flex" alignItems="center">
                                  {icon} {text}
                                </Box>
                              )}
                            </Button>
                          </li>
                        ))}
                      </ul>
                    </motion.div>
                  </AnimatePresence>
                )}
              </NavDropdown>
            )}
          </AnimatePresence>

          {showDropdown && (
            <FullPageWrap
              onClick={() => {
                setShowDropdown(false);
                setDropdownMenuData("");
                setInitialOpen(true);
              }}
            />
          )}
          <BugReportPopup show={showBugReport} setShowBugReport={setShowBugReport} />
        </NavMainContent>
      </NavBarMainContainer>
    </>
  );
};

const TopBar = styled(withTheme(Box))((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  backgroundColor: "#fcfcfc",
  padding: "12px 30px",
  minWidth: 320,
  zIndex: 99,
  borderRadius: "0rem",
  boxShadow: "0 3px 4px -1px rgba(0,0,0,0.05)",
  borderBottom: "1px solid rgba(0,0,0,0.125)",
}));

const TopBarLeft = styled(withTheme(Box))((props) => ({
  display: "flex",
  alignItems: "center",
  width: "50%",
  "& a": {
    display: "flex",
    alignItems: "center",
    "& img": {
      height: "40px",
    },
  },
}));

const TopBarRight = styled(withTheme(Box))((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  width: "50%",
  "& a": {
    color: "#454545",
  },
  "& button": {
    backgroundColor: "#e7e7e7",
    backgroundImage: "none",
    maxWidth: 36,
    maxHeight: 36,
    minWidth: 0,
    transition: "transform 0.15s ease",
    "&:hover": {
      backgroundColor: "#e7e7e7",
      transform: "scale(1.15)",
    },
  },
}));

const UserProfile = styled(withTheme(Box))((props) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "1rem",
  padding: "4px 10px",
  cursor: "pointer",
  transition: "background-color 0.25s ease",
  "&:hover": {
    backgroundColor: "#e7e7e7",
  },
}));

const NavBarMainContainer = styled(withTheme(Box))((props) => ({
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 99,
}));

const NavProfilePicture = styled(Box)({
  borderRadius: "100%",
  height: 28,
  width: 28,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  marginRight: 10,
  "& img": {
    height: 28,
    width: 28,
    borderRadius: "100%",
    position: "relative",
    zIndex: 1,
  },
});

const NavMainContent = styled(withTheme(Box))((props) => ({
  border: "1px solid rgba(0,0,0,0.05)",
  backgroundColor: "#fcfcfc",
  borderRadius: "0rem 0rem 0rem 0rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  padding: 20,
  boxShadow: "0 -3px 4px -1px rgba(0,0,0,0.05)",
  borderTop: "1px solid rgba(0,0,0,0.125)",
  minWidth: 400,

  "& ul": {
    display: "flex",
    alignItems: "center",
    listStyle: "none",
    padding: 0,
    margin: 0,
    "& li": {
      margin: "0 4px",
      "&:nth-child(3)": {
        "& button.create-btn": {
          backgroundColor: "#5805e9",
          backgroundImage: "linear-gradient(90deg, #5805e9 0%, #8b20eb 100%)",
          padding: "10px",
          borderRadius: "100%",
          margin: "0 20px",
          boxShadow: "0 2px 3px -1px rgba(0,0,0,0.5)",

          "& svg": {
            stroke: "#fcfcfc",
            height: 24,
            width: 24,
            position: "relative",
            zIndex: 10,
          },
        },
      },
      "& button": {
        "&:focus-within": {
          backgroundColor: "transparent",
        },
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      "& a": {
        padding: "10px 20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "0.75rem",
        transition: "background-color 0.15s ease",
        position: "relative",

        "& svg": {
          stroke: "#454545",
        },

        "&.active": {
          backgroundColor: "transparent",
          "& svg": {
            stroke: "#5a06e9",
          },
        },
      },
    },
  },
  "& svg": {
    height: 24,
    width: 24,
  },
}));

const NavDropdown = styled(Box)({
  position: "absolute",
  right: 0,
  top: -330,
  backgroundColor: "#fcfcfc",
  width: "100%",
  borderRadius: "1rem",
  zIndex: 12,
  boxShadow: "0 3px 10px -1px rgba(0,0,0,0.125)",
  border: "1px solid rgba(0,0,0,0.125)",
  overflow: "hidden",
  opacity: 0,
  "& ul": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    margin: 0,
    padding: 0,
    "& li": {
      width: "100%",
      margin: 0,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      "& button": {
        backgroundColor: "transparent",
        backgroundImage: "none",
        borderRadius: 0,
        width: "100%",
        padding: "15px 20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid rgba(0,0,0,0.075)",
        textTransform: "none",
        "&:disabled": {
          color: "#454545",
        },
        "& a": {
          width: "100%",
          color: "#454545",
          padding: 0,
          "& div": {
            width: "100%",
          },
        },
        "& svg": {
          stroke: "#454545",
          width: 32,
          height: 32,
          backgroundColor: "#e7e7e7",
          borderRadius: "100%",
          padding: 8,
          marginRight: 10,
        },
      },
      "&:first-child": {
        borderRadius: "1rem 1rem 0rem 0rem",
        "& button": {
          borderRadius: "1rem 1rem 0rem 0rem",
        },
      },
      "&:last-child": {
        borderRadius: "1rem 1rem 0rem 0rem",

        "& button": {
          borderRadius: "0rem 0rem 1rem 1rem",
          borderBottom: "transparent",
        },
      },
    },
  },
});

const FullPageWrap = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "transparent",
  zIndex: 10,
});

const Middle = styled(Box)({
  "& button": {
    minWidth: 0,
    backgroundColor: "transparent",
    backgroundImage: "none",
    borderRadius: "1rem",
    padding: 0,
    margin: 0,
    "&:hover": {
      backgroundColor: "#e7e7e7",
    },
  },
});

/* const JellyBeansContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  margin: "0 10px",
  backgroundImage: "linear-gradient(90deg, rgba(88,5,233, 0.8) 0%, rgba(139, 32, 235, 0.7) 100%)",
  color: "#fcfcfc",
  padding: "10px 20px",
  borderRadius: "2rem",
  transform: "scale(0.8)",
  fontWeight: 500,
  cursor: "pointer",
  transition: "background-color 0.25s ease",
  "& svg": {
    marginRight: 6,
  },
  "&:hover": {
    backgroundColor: "rgba(125, 83, 222, 0.5)",
  },
});
 */
export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
